import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { hideLoader, showLoader } from "../GlobalLoader/globalLoaderSlice";
import { useGetExpiredJobsQuery } from "./api/expiredJobs"
import useJobUrl from "../../common/hooks/useJobUrl";

function ExpiredJobs() {
    const loaderId = { id: 'expiredJobs' }
    const { data, isFetching, isLoading } = useGetExpiredJobsQuery();
    const jobUrl = useJobUrl();

    const dispatch = useDispatch();

    useEffect(() => {
        if (isFetching || isLoading) {
            dispatch(showLoader(loaderId));
        } else {
            dispatch(hideLoader(loaderId));
        }
    }, [isFetching, isLoading, dispatch]);

    if (isLoading || isFetching)
        return <div></div>

    return (
        <section>
            <div className="container">

                <div className="head">
                    <h1>Expired Jobs</h1>
                </div>
                <ul className="links">
                    {data.map((job) => {
                        return (
                            <li><a href={jobUrl(job.slug)}>{job.slug}</a></li>
                        )
                    })}
                </ul>
            </div>
        </section>
    )
}

export default ExpiredJobs
