import React from 'react';
import styles from './ImageContentBlock.module.scss';

function ImageContentBlock(props) {
    return (
        <div className={styles.contents} style={props.background ? { backgroundColor: props.background } : null}>
            <div className="container">
                <div className={`${styles.top} `}>
                    <div className={`${styles.textContainer} `} dangerouslySetInnerHTML={{ __html: props.paragraphLeft }} style={props.background ? { color: `#ffff` } : null} />
                    <div className={`${styles.imageWrapper} `}>
                        <img src={props.image} alt="" />
                    </div>
                </div>
            </div>
            {
                props.paragraphBottom ?
                    <div className={styles.bottom} >
                        <div className="container">
                            <div dangerouslySetInnerHTML={{ __html: props.paragraphBottom }} style={props.background ? { color: `#ffff` } : null} />
                        </div>
                    </div>
                    : ''
            }
        </div>
    )
}

export default ImageContentBlock
