import { useState, useEffect } from 'react';
import { useFormik } from 'formik'
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from 'yup'
import { useSignUpMutation, useExistingUserLoginMutation } from '../api/authApiSlice';
import { LgbtToast } from '../../../components/LgbtToast/LgbtToast'
import useRoleRoute from './useRoleRoute';

function useSignUpForm(setFormError) {

    const navigate = useNavigate();

    const initialValues = {
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        linkedin_profile_url: ''
    }
    const validationSchema = Yup.object({
        firstname: Yup.string().required('Required'),
        lastname: Yup.string(),
        username: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email format').required('Required'),
        password: Yup.string().required('Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required'),
        linkedin_profile_url: Yup.string()
            .url('Invalid URL format')
            .matches(
                /^https?:\/\/(www\.)?linkedin\.com\/.*$/,
                'URL must be a valid LinkedIn profile'
            ),
    });

    const [signUp, { isFetching, isLoading }] = useSignUpMutation()
    const [loginExistingUser, { isFetching: euFetching, isLoading: euLoading }] = useExistingUserLoginMutation()

    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [popupQuestion, setPopUpQuestion] = useState('');
    const [extendedLoading, setExtendedLoading] = useState(false);

    const handleExistingUserLogin = async (values) => {
        try {
            const response = await loginExistingUser(values);
            if (response.error) {
                setFormError(response.error.data?.error || 'Something went wrong');
        } else {
                navigate('/jobseeker-login-verification', { replace: true });
            }
        } catch (error) {
            console.error('Error during existing user login:', error);
        }
    };


    const signUpForm = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                const data = await signUp(values);
                if (data === undefined) {
                    setFormError('Something went wrong! Please try again later')
                    return;
                }

                if (data.error) {
                    if (data.error.status === 401 && data.error?.data.detail != undefined)
                        setFormError(data.error?.data.detail);
                    else if (data.error.status === 400 && data.error?.data.error != undefined)
                        setFormError(data.error?.data.error);
                    else if (data.error?.status === 403 && data.error.data?.warning) {
                        setShowConfirmPopup(true);
                        setPopUpQuestion(data.error.data?.warning);
                    } else
                        setFormError('Something went wrong! Please try again later')
                    return;
                } else if (data?.data?.warning != undefined && data?.data?.warning != '') {
                    setFormError(data?.data?.warning)
                    return;
                }

                signUpForm.resetForm();
                LgbtToast({ message: 'Thank you for signing up. Now you can login here', type: 'success' });
                navigate('/signup-confirm', { replace: true });

            } catch (error) {
                console.log('Something went wrong!', error);
            }
        }
    })


    return { signUpForm, isFetching, isLoading, extendedLoading, showConfirmPopup, setShowConfirmPopup, handleExistingUserLogin, popupQuestion }
}

export default useSignUpForm