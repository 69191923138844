import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
const popupSlice = createSlice({
    name: 'popup',
    initialState: {
        disclosed: false,
    },
    reducers: {
        disClose: (state, action) => {
            state.disclosed = action.payload
            Cookies.set('popupDisclosed', true)
        }
    },
});

export const { disClose } = popupSlice.actions;

export default popupSlice.reducer