import { useEffect, useState } from "react";
import { ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { useSubmitContactFormMutation } from "../api/contactFormApiSlice";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  company_name: "",
  message: "",
  recaptcha: "",
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Second name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().matches(
    /^\s*(([+]\s?\d[-\s]?\d|0)?\s?\d([-\s]?\d){9}|[(]\s?\d([-\s]?\d)+\s*[)]([-\s]?\d)+)\s*$/,
    "Invalid phone number"
  ),
  message: Yup.string().required("Message is required"),
  recaptcha: Yup.string().required("Recaptcha required"),
});

function useContactForm() {
  const [submitContactForm, { isLoading, isError, isSuccess }] =
    useSubmitContactFormMutation();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("contact_form_submission");
    contactForm.setFieldValue("recaptcha", token);
  };

  const onSubmit = async (values, { setStatus, resetForm, setFieldError }) => {
    const response = await submitContactForm(values);
    try {
      setStatus({ success: true });
      setSuccessMessage(response.data.success);
      setErrorMessage("");
      resetForm();
    } catch (error) {
      setStatus({ success: false });
      if(response.error.data.error){
        setErrorMessage(response.error.data.error);
        setSuccessMessage("");
      }
      if (response.error && response.error.data) {
        Object.keys(response.error.data).forEach((field) => {
          setFieldError(field, response.error.data[field][0]);
        });
      }
    }
  };

  const contactForm = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return [contactForm, isLoading, isError, successMessage, errorMessage];
}

export default useContactForm;
