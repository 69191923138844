import React, { useState, useEffect, useRef } from 'react';
import FieldError from "../FieldError/FieldError";
import SuggestionBox from "../SuggestionBox/SuggestionBox";
import styles from "./JobLocationFields.module.scss";
import Select from 'react-select';

function JobLocationFields({ form, type, whichForm, hintText, locationPlaceholder }) {
    const [selectedDistance, setSelectedDistance] = useState(null);
    const [suggestionVisibility, setSuggestionVisibility] = useState({});
    const [locations, setLocations] = useState([{ id: 0, value: '' }]);

    // Check if component is mounted
    const isMounted = useRef(true);

    const distance = [
        { value: 0, label: '0 miles' },
        { value: 1, label: '1 mile' },
        { value: 3, label: '3 miles' },
        { value: 5, label: '5 miles' },
        { value: 10, label: '10 miles' },
        { value: 15, label: '15 miles' },
        { value: 20, label: '20 miles' },
        { value: 30, label: '30 miles' },
        { value: 50, label: '50 miles' },
    ];

    useEffect(() => {
        isMounted.current = true;

        // Initialize locations state from form values
        if (form.values.location_strings && form.values.location_strings.length > 0) {
            setLocations(form.values.location_strings.map((value, index) => ({ id: index, value })));
        }

        // Cleanup function to set isMounted to false when component unmounts
        return () => {
            isMounted.current = false;
        };
    }, [form.values.location_strings]);

    const handleDistanceChange = (selectedOption) => {
        if (isMounted.current) {
            setSelectedDistance(selectedOption);
            form.setFieldValue('distance', selectedOption.value);
        }
    };

    const handleLocationChange = (id, value) => {
        if (isMounted.current) {
            const updatedLocations = locations.map(location =>
                location.id === id ? { ...location, value } : location
            );
            setLocations(updatedLocations);
            form.setFieldValue('location_strings', updatedLocations.map(loc => loc.value));
            setSuggestionVisibility(prev => ({ ...prev, [id]: true }));
        }
    };

    const handleSuggestionSelect = (id, suggestion) => {
        if (isMounted.current) {
            const updatedLocations = locations.map(location =>
                location.id === id ? { ...location, value: suggestion } : location
            );
            setLocations(updatedLocations);
            form.setFieldValue('location_strings', updatedLocations.map(loc => loc.value));
            setSuggestionVisibility(prev => ({ ...prev, [id]: false }));
        }
    };

    const addLocationField = () => {
        if (isMounted.current) {
            const newId = locations.length; // Set the id as the new index
            setLocations([...locations, { id: newId, value: '' }]);
        }
    };

    const removeLocationField = (id) => {
        if (isMounted.current) {
            const updatedLocations = locations.filter(location => location.id !== id);

            // Update the state with the filtered locations
            setLocations(updatedLocations);

            // Update the form field with the new locations array
            form.setFieldValue('location_strings', updatedLocations.map(loc => loc.value));

            // Clean up the suggestion visibility state
            setSuggestionVisibility(prev => {
                const newVisibility = { ...prev };
                delete newVisibility[id];
                return newVisibility;
            });
        }
    };
    return (
        <section className="jobLocFieldsWrap fieldGroupWrap">
            <div className="row">
                <div className="fieldHead col-md-3">
                    <h2>Job location</h2>
                </div>
                <div className="jobFields col-md-9">
                    <div className="row">
                        <div className="col-md-12">
                            {whichForm !== 'jobAlertForm' &&
                                <div className="fieldWrap">
                                    <div className={`${styles.labelBox}`}>
                                        <input
                                            className={styles.checkbox}
                                            type="checkbox"
                                            name="is_remote_job"
                                            checked={form.values.is_remote_job}
                                            onChange={form.handleChange}
                                        />
                                        <label
                                            className={styles.label}
                                            htmlFor="jobAddress"
                                        >
                                            This is a remote job
                                        </label>
                                    </div>
                                </div>
                            }
                            {whichForm === 'jobAlertForm' ? (
                                <div className="fieldWrap">
                                    <div className="labelBox">
                                        <label htmlFor="jobAddress">Address</label>
                                    </div>
                                    <input
                                        id="location"
                                        type="text"
                                        name="location"
                                        placeholder={locationPlaceholder}
                                        value={form.values.location || ""}
                                        onChange={(e) => {
                                            form.setFieldValue('location', e.target.value);
                                            setSuggestionVisibility(true);
                                        }}
                                        onFocus={() => setSuggestionVisibility(true)}
                                        autoComplete="off"
                                    />
                                    {form.values.location && (
                                        <SuggestionBox
                                            keyword={form.values.location}
                                            name="location"
                                            form={form}
                                            visibilty={suggestionVisibility}
                                            setVisibility={setSuggestionVisibility}
                                            onSuggestionSelect={(suggestion) => {
                                                form.setFieldValue('location', suggestion);
                                                setSuggestionVisibility(false);
                                            }}
                                        />
                                    )}
                                    <FieldError error={form.errors.location} touched={form.touched.location} />
                                </div>
                            ) : (
                                <>
                                    {locations.map((location, index) => {
                                        return (

                                            <div className="fieldWrap" key={location.id}>
                                                <div className="labelBox">
                                                    <label htmlFor={`location-${index}`}>Address {index + 1}</label>
                                                </div>
                                                <input
                                                    id={`location-${index}`}
                                                    type="text"
                                                    name={`location-${index}`}
                                                    placeholder={locationPlaceholder}
                                                    value={location.value || ""}
                                                    onChange={(e) => handleLocationChange(location.id, e.target.value)}
                                                    onFocus={() => setSuggestionVisibility(prev => ({ ...prev, [location.id]: true }))}
                                                    autoComplete="off"
                                                />
                                                {location.value && (
                                                    <SuggestionBox
                                                        keyword={location.value}
                                                        form={form}
                                                        visibilty={suggestionVisibility[location.id] || false}
                                                        setVisibility={(isVisible) =>
                                                            setSuggestionVisibility((prev) => ({ ...prev, [location.id]: isVisible }))
                                                        }
                                                        onSuggestionSelect={(suggestion) => handleSuggestionSelect(location.id, suggestion)}
                                                        name="location-strings"
                                                    />
                                                )}
                                                <FieldError error={form.errors[`location-${index}`]} touched={form.touched[`location-${index}`]} />
                                                {index > 0 && (
                                                    <button
                                                        type="button"
                                                        onClick={() => removeLocationField(location.id)}
                                                        className={styles.removeLocationButton}
                                                    >
                                                        Remove
                                                    </button>
                                                )}
                                            </div>
                                        )
                                    })}
                                    <button type="button" onClick={addLocationField} className={styles.addLocationButton}>
                                        Add More Location
                                    </button>
                                </>
                            )}
                        </div>
                        <div className="col-md-6">
                            {whichForm === 'jobAlertForm' &&
                                <div className="fieldWrap">
                                    <label htmlFor="distance">Distance</label>
                                    <Select
                                        isClearable={false}
                                        isSearchable={false}
                                        options={distance}
                                        value={selectedDistance}
                                        onChange={handleDistanceChange}
                                    />
                                </div>
                            }
                        </div>
                        {whichForm === 'jobAlertForm' &&
                            <div className={styles.wfh}>
                                <div className={styles.checkboxWrapper}>
                                    <input
                                        type="checkbox"
                                        id="work_from_home"
                                        name="work_from_home"
                                        checked={form.values.work_from_home}
                                        onChange={event => form.setFieldValue('work_from_home', event.target.checked)}
                                    />
                                </div>
                                <div className={styles.checkboxTitle}>
                                    <h4 htmlFor="work_from_home">Include work from home jobs?</h4>
                                </div>
                            </div>
                        }
                        {hintText &&
                            <div className={styles.locationLabelBox}>
                                <label htmlFor="jobAddress">Postcode*</label>
                                <div dangerouslySetInnerHTML={{ __html: hintText }} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default JobLocationFields;
