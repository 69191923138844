import useAuthInit from '../../features/Auth/hooks/useAuthInit';
import useSiteInit from '../../features/Site/hooks/useSiteInit.js';
import usePopupInit from '../../features/PopUp/hooks/usePopupInit.js'

function useAppInitializer() {

  const authInit = useAuthInit();
  const siteInit = useSiteInit();
  const popupInit = usePopupInit();
  return () => {
    authInit();
    siteInit();
    popupInit();
  }
}

export default useAppInitializer
