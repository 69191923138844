import React from 'react'
import { useSelector } from 'react-redux'
import bannerImage from '../../../../assets/images/generic-banner.png'
import styles from './ForgotPasswordConfirm.module.scss'

function ForgotPasswordConfirm() {
    const siteSetting = useSelector(state => state.site)
    return (
        <section className={styles.signupConfirm} style={{ backgroundImage: `url(${siteSetting.bannerImage ?? bannerImage})` }}>
            {/* <section className={styles.ForgotPasswordConfirm} style={{ backgroundImage: `url(${bannerImage})` }}> */}
            <div className={`${styles.cntOuter} container`}>
                <h1>Password Update!</h1>
                <h3>If you have an account, you will receive an email with instructions to reset your password.</h3>
            </div>
        </section>
    )
}

export default ForgotPasswordConfirm;