import {api} from '../../../utils/api'

const expiredJobsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getExpiredJobs: builder.query({
            query: () => `/jobmanager/job/expired`,
        }),
    }),
});

export const {useGetExpiredJobsQuery} = expiredJobsApi;
