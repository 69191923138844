import { useMemo } from 'react';

const useJobUrl = () => {
    const generateJobUrl = (slug, id) => {
        return `/job/${slug}`;
    };
  
    return useMemo(() => generateJobUrl, []);
};

export default useJobUrl