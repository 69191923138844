import React from 'react'
import useProfileUpdateForm from '../../hooks/useProfileUpdateForm'
import styles from './ProfileUpdate.module.scss'

function ProfileUpdate() {

	const { profileUpdateForm, isLoading, formError } = useProfileUpdateForm()

	return (
		<div className={styles.profileWrapper}>
			<h3>Edit Profile</h3>
			{formError != '' ?
				<p className={styles.error}>{formError}</p> :
				''}
			<form action="" onSubmit={profileUpdateForm.handleSubmit}>
				<div className={styles.input}>
					<label htmlFor="first_name">
						First Name
					</label>
					<div className={styles.inputWrapper}>
						<input
							type="text"
							id="first_name"
							name="first_name"
							value={profileUpdateForm.values.first_name}
							onChange={profileUpdateForm.handleChange} />
						{profileUpdateForm.touched.first_name && profileUpdateForm.errors.first_name ?
							<p className={styles.error}>{profileUpdateForm.errors.first_name}</p>
							: ''}
					</div>
				</div>
				<div className={styles.input}>
					<label htmlFor="last_name">
						Last Name
					</label>
					<div className={styles.inputWrapper}>
						<input
							type="text"
							id="last_name"
							name="last_name"
							value={profileUpdateForm.values.last_name}
							onChange={profileUpdateForm.handleChange} />
					</div>
				</div>
				<div className={styles.input}>
					<label htmlFor="email">
						Email
					</label>
					<div className={styles.inputWrapper}>
						<input
							id="email"
							type="email"
							name="email"
							value={profileUpdateForm.values.email}
							onChange={profileUpdateForm.handleChange} />
						<span>An email verification is required if email is updated!</span>
						{profileUpdateForm.touched.email && profileUpdateForm.errors.email ?
							<p className={styles.error}>{profileUpdateForm.errors.email}</p>
							: ''}
					</div>
				</div>
				<div className={styles.input}>
					<label htmlFor="linkedin_profile_url">
						Linked-in Profile Url
					</label>
					<div className={styles.inputWrapper}>
						<input
							type="text"
							id="linkedin_profile_url"
							name="linkedin_profile_url"
							value={profileUpdateForm.values.linkedin_profile_url}
							onChange={profileUpdateForm.handleChange} />
						{profileUpdateForm.touched.linkedin_profile_url && profileUpdateForm.errors.linkedin_profile_url ?
							<p className={styles.error}>{profileUpdateForm.errors.linkedin_profile_url}</p>
							: ''}
					</div>
				</div>
				<button type="submit">Update Profile</button>
			</form>
		</div>
	)
}

export default ProfileUpdate
