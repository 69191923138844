import React from 'react'

import useSelectTextColor from '../../../../common/hooks/useSelectTextColor'

import GenericEmployerBanner from '../../components/GenericEmployerBanner/GenericEmployerBanner'
import Overview from '../../components/Overview/Overview'
import TabSection from '../../components/Tab/Tab'
import Content2Column from '../../components/Content2Column/Content2Column'
import FullWidthImage from '../../components/FullWidthImage/FullWidthImage'
import ColoredOverview from '../../components/ColoredOverview/ColoredOverview'
import JobSection from '../../components/JobSection/JobSection'
import ImageContentBlock from '../../components/ImageContentBlock/ImageContentBlock'
import TwoImageSection from '../../components/TwoImageSection/TwoImageSection'

import './GenericEmployer.scss'

export const EmployerContext = React.createContext()

function GenericEmployer({ employer }) {

	const employerSectionKeys = Object.keys(employer.page_data.section)
	employerSectionKeys.sort((a, b) => {
		const sectionA = employer.page_data.section[a];
		const sectionB = employer.page_data.section[b];
		return sectionA.sort_order - sectionB.sort_order;
	});
	const { primary_color, secondary_color } = employer.page_data.profile_meta_data
	const logo_bg = employer.page_data.section.banner.logo_bg ? employer.page_data.section.banner.logo_bg : "#ffff"

	const selectTextColor = useSelectTextColor()
	const text_color = selectTextColor(primary_color)

	const sections = employerSectionKeys.map((key, index) => {
		const section = employer.page_data.section[key];
		let sectionName = key.includes('-') ? key.substring(0, key.lastIndexOf('-')) : key;

		switch (sectionName) {
			case 'banner':
				return <GenericEmployerBanner key={index} data={section} primaryColor={primary_color} />

			case 'tab_section':
				return <TabSection key={index} data={section} primaryColor={primary_color} />

			case 'overview':
				return <Overview key={index} data={section} />

			case 'content_block_2_column':
				return <Content2Column key={index} data={section} />

			case 'image_block':
				return <FullWidthImage key={index} data={section} />

			case 'iframe_content_block':
				return <ColoredOverview key={index} data={section} primaryColor={primary_color} />

			case 'job_section':
				return <JobSection key={index} data={section} employer={employer} />

			case 'image_content_block':
				return <ImageContentBlock key={index} data={section} />

			case 'two_image_section':
				return <TwoImageSection key={index} data={section} />

			default:
				return null
		}
	})

	return (
		<>
			<div
				className="genericEmployer"
				style={
					{
						"--primary-employer-color": primary_color,
						"--secondary-employer-color": secondary_color,
						"--text-color": text_color,
						"--logo-bg": logo_bg,
					}
				}
			>
				<EmployerContext.Provider value={employer}>
					{sections}
				</EmployerContext.Provider>
			</div>
		</>
	)
}

export default GenericEmployer
