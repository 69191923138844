import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { useGetHeaderQuery } from './hooks/useHeader'
import { hideLoader } from '../GlobalLoader/globalLoaderSlice'

import Logo from '../../components/Logo/Logo'
import HeaderNav from '../../components/HeaderNav/HeaderNav'
import styles from './styles/Header.module.scss'
import { LgbtToast } from '../../components/LgbtToast/LgbtToast'

function Header() {
  const dispatch = useDispatch();
  const { data, error, isError, isLoading, isFetching } = useGetHeaderQuery()
  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(hideLoader({ id: 'app' }));
    }
  }, [dispatch, isLoading, isFetching]);

  useEffect(() => {
    if (isError && error) {
      LgbtToast({ message: error.status, type: 'error' })
    }
  }, [dispatch, isError, error]);


  // const [isScrollingDown, setIsScrollingDown] = useState(false)
  // useEffect(() => {
  //   // Function to handle scroll event
  //   const handleScroll = () => {
  //     if (window.scrollY > headerRef.current.offsetHeight) {
  //       setIsScrollingDown(true);
  //     } else {
  //       setIsScrollingDown(false);
  //     }
  //   };
  //   // Add the scroll event listener when the component mounts
  //   window.addEventListener('scroll', handleScroll);

  //   // Remove the scroll event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);


  // const headerClassName = isScrollingDown ? `${styles.scrolled}` : '';
  const logoData = data?.Logo;

  return (
    <header className={`${styles.header}`}>
      <div className="container">
        <nav className={styles.navWrapper}>
          <Logo data={logoData} />
          <HeaderNav menu={data?.NavMenu} logging={data?.Sign_in} />
        </nav>
      </div>
    </header>
  )
}

export default Header
