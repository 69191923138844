import React, { useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import JobSearchBox from '../../features/JobSearchBox'
import styles from '../SliderBanner/SliderBanner.module.scss'

const SliderBanner = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: 0,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
    };

    const banner = props.background
    return (
        <div className={styles.homeBanner}> 
            <Slider {...settings} className={styles.animWrapper}>
                {banner.images.map((image, index) => (
                    <img src={image} key={index} alt={`Slide ${index + 1}`} />
                ))}
            </Slider>
            <div className={styles.bannerCntWrapper + ' container'}>
                <span className={styles.headline}>
                    {props.title}
                </span>
                <span className={styles.cnt}>{props.subtitle}</span>
                {props.children}
            </div>
        </div>
    )
}

export default SliderBanner