import {api} from '../../../utils/api'

const homeApi = api.injectEndpoints({
    endpoints: (builder) => ({
        termsAndConditionsPageFields: builder.query({
            query: () => `/exampleprovider/terms-&-conditions/`,
        }),
    }),
});

export const {useTermsAndConditionsPageFieldsQuery} = homeApi;
