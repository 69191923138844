import React from 'react'
import styles from './AboutCard.module.scss'

function AboutCard(props) {
    return (
        <div className={`${styles.cardParent} container`}>
            <div className={styles.cardWrapper}>
                <div className={styles.container}>
                    <div className={styles.cardTop}>
                        <h1>{props.title}</h1>
                        <p>{props.description}</p>
                    </div>
                    <div className={styles.cardContents}>
                        <div className={`${styles.cardMiddle} row`}>
                            <div className={`${styles.topLeft} col-lg-6`}>
                                <img src={props.image} alt="" />
                            </div>
                            <div className={`${styles.topRight} col-lg-6`} dangerouslySetInnerHTML={{ __html: props.textBlockHalf }} >
                            </div>
                        </div>
                        <div className={styles.cardBottom} dangerouslySetInnerHTML={{ __html: props.textBlockFull }}>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default AboutCard
