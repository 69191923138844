import React from 'react'
import useJobSeekerDashboard from './hooks/useJobSeekerDashboard'

function JobSeekerDashboard() {
	const view = useJobSeekerDashboard();

	return (
		<>{view}</>
	)
}

export default JobSeekerDashboard
