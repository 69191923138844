import { api } from '../../../utils/api';

const siteApiSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        textToSpeech: builder.query({
            query: (selectedText) => {
                return {
                    url: `/multisitecontrolpanel/text-to-speech/`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ text: selectedText }),
                    responseHandler: async (response) => {
                        const contentType = response.headers.get('content-type');
                        if (contentType && contentType.includes('application/json')) {
                            return response.json(); // Handle JSON response
                        } else {
                            return response.blob(); // Handle binary response
                        }
                    },
                };
            },
        }),
    }),
});

export const { useLazyTextToSpeechQuery } = siteApiSlice;