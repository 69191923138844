import React, { useEffect, useState } from "react";
import styles from "../styles/contactForm.module.scss";
import useContactForm from "../hooks/useContactForm";
import ReCaptcha from "../../../components/ReCaptcha/ReCaptcha";

function ContactForm() {
  const [form, isLoading, isError, successMessage, errorMessage] = useContactForm();
  const handleCaptchaChange = (value) => {
    if (value === undefined) {
      form.setFieldValue("recaptcha", value);
    }
  };

  return (
    <div id="form-container" className={styles.containerForm}>
      <div className="container">
        <div className={styles.contactFormWrap}>
          <h2>If you would like to enquire about pricing and find out more about our Unlimited Job Posting packages then please complete the form below.</h2>
          <form
            onSubmit={form.handleSubmit}
            method="submit"
            className={styles.contactForm}
          >
            <div className={styles.formGroup}>
              <div className={`${styles.formField} ${styles.field}`}>
                <input
                  type="text"
                  className={styles.formInput}
                  id="first_name"
                  name="first_name"
                  onBlur={form.handleBlur}
                  placeholder="First Name"
                  onChange={form.handleChange}
                  value={form.values.first_name}
                />
                {form.touched.first_name && form.errors.first_name ? (
                  <div className={styles.error}>{form.errors.first_name}</div>
                ) : null}
              </div>
              <div className={`${styles.formField} ${styles.field}`}>
                <input
                  type="text"
                  className={styles.formInput}
                  id="last_name"
                  name="last_name"
                  placeholder="Last Name"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  value={form.values.last_name}
                />
                {form.touched.last_name && form.errors.last_name ? (
                  <div className={styles.error}>{form.errors.last_name}</div>
                ) : null}
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className={`${styles.formField} ${styles.field}`}>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={styles.formInput}
                  placeholder="Email Address"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  value={form.values.email}
                />
                {form.touched.email && form.errors.email ? (
                  <div className={styles.error}>{form.errors.email}</div>
                ) : null}
              </div>
              <div className={`${styles.formField} ${styles.field}`}>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className={styles.formInput}
                  placeholder="Phone Number"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  value={form.values.phone}
                />
                {form.touched.phone && form.errors.phone ? (
                  <div className={styles.error}>{form.errors.phone}</div>
                ) : null}
              </div>
            </div>

            <div
              className={`${styles.formField} ${styles.field} ${styles.fullWidth}`}
            >
              <input
                type="text"
                id="company_name"
                name="company_name"
                className={styles.formCompanyInput}
                placeholder="Employer Name"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                value={form.values.company_name}
              />
            </div>

            <div className={`${styles.formField} ${styles.field}`}>
              <textarea
                className={styles.formMessage}
                id="message"
                name="message"
                placeholder="Message"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                value={form.values.message}
                rows={6}
              ></textarea>
              {form.touched.message && form.errors.message ? (
                <div className={styles.error}>{form.errors.message}</div>
              ) : null}
            </div>
            <div className={styles.formGroup}>
              <div className={styles.button}>
                <button type="submit">Send</button>
              </div>
            </div>
            {successMessage && <div className={styles.success_message}>{successMessage}</div>}
            {errorMessage && <div className={styles.error_message}>{errorMessage}</div>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
