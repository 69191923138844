import React from 'react'
import Button from '../Button/Button'
import LinedDecorTitleWhite from '../LinedDecorTitleWhite/LinedDecorTitleWhite'

import styles from './TrendingJobsSecondaryColor.module.scss'

function TrendingJobsSecondaryColor(props) {
    return (
        <div className={styles.trendSecWrapper}>
            <div className="container">
                <LinedDecorTitleWhite tag="h2">Trending Jobs</LinedDecorTitleWhite>
                <ul className={styles.jobLinksWrapper}>
                    {
                        props.trendingJobs?.map((item, index) => (
                            <li key={index} className={styles.linkWrap}>
                                <Button className={styles.link} to={`/find-a-job?keyword=${item.button_text}`}>{item.button_text}</Button>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default TrendingJobsSecondaryColor