import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './AlertAccordion.module.scss';

const AlertAccordion = ({ jobAlert, handleDelete }) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        console.log('jobAlert data:', jobAlert);
    }, [jobAlert]);

    const toggleAccordion = () => {
        setIsActive(!isActive);
    };

    return (
        <div className={styles.accordion}>
            <div
                className={`${styles.accordionHeader} ${isActive ? styles.active : ''}`}
                onClick={toggleAccordion}
            >
                <div>{jobAlert?.job_title_keyword || 'No Job Title'}</div>
            </div>
            {isActive && (
                <div className={styles.accordionContent}>
                    <table>
                        <tbody>
                            <tr>
                                <td><b>Job title</b></td>
                                <td>{jobAlert?.job_title_keyword || 'N/A'}</td>
                            </tr>
                            <tr>
                                <td><b>Job Location</b></td>
                                <td>{jobAlert?.location || 'N/A'}</td>
                            </tr>
                            <tr>
                                <td><b>Sector</b></td>
                                <td>{jobAlert?.sector || 'N/A'}</td>
                            </tr>
                            <tr>
                                <td><b>Frequency</b></td>
                                <td>{jobAlert?.alert_frequency || 'N/A'}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className={styles.links}>
                        <Link className={styles.edit} to={`/edit/${jobAlert?.id}`}>Edit</Link>
                        <button className={styles.delete} onClick={(e) => { handleDelete(jobAlert?.id, e) }}>Delete</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AlertAccordion;
