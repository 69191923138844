import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetCompanySearchResultsQuery } from '../CompanySearchBox/api/searchApiSlice'
import { useInclusiveHiringEmployersQuery } from './Api/CompanySearchPageSlice'
import CompanySearchBox from '../CompanySearchBox'
import EmployerList from '../../components/EmployerList/EmployerList';
import AnimatedBanner from '../../components/AnimatedBanner/AnimatedBanner';
import StaticBanner from '../../components/StaticBanner/StaticBanner'
import Pagination from '../../components/Pagination/Pagination';

import styles from './styles/searchPage.module.scss'

function CompanySearchPage() {

  const loc = useLocation();
  const queryParams = new URLSearchParams(loc.search);
  const company = queryParams.get('company');
  const searchByLetter = queryParams.get('search-by');
  const scrollRef = useRef(null);
  const bannerRef = useRef(null);
  const [page, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const pageSize = 10
  const { data, isLoading, isFetching, isError } = useGetCompanySearchResultsQuery({
    company: company || '',
    searchByLetter: searchByLetter || '',
    page_size: pageSize,
    page: page,
  });

  const { data: pageData, error: pageError, isError: pageIsError, isLoading: pageIsLoading } = useInclusiveHiringEmployersQuery();

  useEffect(() => {
    setPageNumber(1);
  }, [searchByLetter,company])

  useEffect(() => {
    if (data) {
      setTotalPages(Math.ceil(data.count / pageSize));
    }
  }, [data])

  useEffect(() => {
    if (company && !isFetching && !isLoading) {
      const y =
        scrollRef.current?.getBoundingClientRect().top - bannerRef.current?.getBoundingClientRect().top - 20;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [company, isFetching, isLoading]);
  
  useEffect(() => {
    const page = queryParams.get('page');
    setPageNumber(page ? parseInt(page, 10) : 1);
  }, [loc]);

  return (
    <div className={styles.searchPage}>
      {pageData?.bannerType === 'animated_banner' ? (
        <AnimatedBanner
          title={pageData.bannerTitle}
          height={pageData.height}
          subtitle={pageData.bannerSubtitle}
          scrollTo={false}
        >
          <CompanySearchBox className={styles.searchBox} />
        </AnimatedBanner>
      ) : (
        <StaticBanner
          title={pageData?.bannerTitle}
          background={pageData?.bannerImage ? pageData?.bannerImage : ''}
          subtitle={pageData?.bannerSubtitle}
          desktopHeight={pageData?.desktop_height}
          mobileHeight={pageData?.mobile_height}
          page={`employer`}
          isLoading={pageIsLoading}
        >
          <CompanySearchBox className={styles.searchBox} />
        </StaticBanner>
      )}

      <div className={styles.gridOuter}>
        <div className={`container ${styles.gridWrap}`}>
          <EmployerList
            employers={data}
            isLoading={isLoading}
            isFetching={isFetching}
            isError={isError}
            skeleton={true}
            searchByLetter={searchByLetter}
          />
          <div className={styles.searchButtonWrapper}>
            <div className={styles.searchBtn}>
              <Pagination
                currentPage={page}
                totalPages={totalPages}
                pageSize={pageSize}
                onPageChange={setPageNumber}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanySearchPage
