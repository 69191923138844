import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux';

import { useVerifyLoginMutation, useLazyGetUserQuery } from '../api/authApiSlice'
import { initAuth, logout, setUser } from '../hooks/authSlice'

function useAuthInit() {

	const [verifyLogin] = useVerifyLoginMutation()
	const [getUserData] = useLazyGetUserQuery();
	const dispatch = useDispatch()

	return async () => {
		const isAuthenticated = Cookies.get('isAuthenticated');
		const access = Cookies.get('access')
		const refresh = Cookies.get('refresh')


		if (isAuthenticated !== 'true' || access == undefined || access == null || refresh == undefined || refresh == null) {
			dispatch(logout());
			return false;
		}

		dispatch(initAuth({ access, refresh, isAuthenticated: true }));

		try {
			const data = await verifyLogin();

			// fetching user data
			if (!data?.error) {

				const userData = await getUserData();
				// console.log(userData);
				if (userData.isError) {
					dispatch(logout());
					return false;
				}

				dispatch(setUser(userData.data.user));
				return true;
			}
		} catch (error) {
			console.log('Something went wrong!', error);
		}
	}
}

export default useAuthInit
