import React from 'react'
import { FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import { useGetJobPackagesQuery } from '../../api/dashboardApiSlice'

import styles from './JobPackages.module.scss'

function JobPackages() {

    const {data, error, isLoading, isFetching} = useGetJobPackagesQuery()

    if (isLoading || isFetching) {
        return <div>Loading...</div>
    }

    if (data === undefined || (data.length === 0 && !isLoading && !isFetching)) {
        return <><p>No packages have been purchased yet!</p></>
    }

    return (
        <div className={styles.tableWrapper}>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Package Name</th>
                        <th scope="col">Remaining</th>
                        <th scope="col">Listing Duration</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((jobPackage) => {
                        return (
                            <tr key={jobPackage.id}>
                                <td>{jobPackage.job_package.title}</td>
                                <td>{jobPackage.job_package.is_unlimited ? 'Unlimited' : jobPackage.balance}</td>
                                <td>{jobPackage.job_package.duration_in_days}</td>
                                <td>
                                    <span className={`${styles.status} ${styles[jobPackage.status]}`}>{jobPackage.status}</span>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default JobPackages
