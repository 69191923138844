import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { useGetEmployerQuery } from '../api/EmployerApiSlice'
import { hideLoader, showLoader } from '../../GlobalLoader/globalLoaderSlice'
import employer from '../employer.json'
import GenericEmployer from '../templates/GenericEmployer/GenericEmployer'
import PostOffice from '../templates/PostOffice/PostOffice'
import useEmployerComponentSelector from './useEmployerComponentSelector'

function useEmployerProfile() {
    let { employerSlug } = useParams()

    const { data, isLoading, isFetching, error } = useGetEmployerQuery(employerSlug)

    // TODO: create sections here and return it to employer single page
    const selectPageComponent = useEmployerComponentSelector()
    let pageComponent = <></>
    if (
        data &&
        // data.schema_standard &&
        !error &&
        !isLoading &&
        !isFetching
    ) {
        pageComponent = selectPageComponent(data)
    }


    return {
        // employer: data,
        employer: employer,
        pageComponent,
        isLoading,
        isFetching,
        error,
    }
}

export default useEmployerProfile
