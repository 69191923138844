import { useEffect } from "react";

function useRoleRoute() {

    return (roles) => {

        if (!roles || roles.length == 0)
            return '/';

        switch (true) {
            case roles.includes('recruiter'):
                return '/dashboard';
            case roles.includes('jobseeker'):
                return '/my-account';
            default:
                return '/unauthorized';
        }
    }
}

export default useRoleRoute
