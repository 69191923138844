import React from 'react'
import styles from './LgbtDecorLineSingle.module.scss'

function LgbtDecorLineSingle(props) {
    return (
        <div className={`${styles.decorWrapper} ${props.className}`}>
            {
                props.color ?
                    <div className={styles[props.color]}></div>
                    :
                    <div className={styles.white}></div>
            }
        </div>
    )
}

export default LgbtDecorLineSingle