import React from 'react'
import CompactCompanySearch from '../../features/CompactCompanySearch/index'
import CompaniesGrid from '../../features/CompaniesGrid/index'

import styles from './CompanySearchSection.module.scss'

function CompanySearchSection() {
    return (
        <div className={styles.companiesSecWrap}>
            <div className={`${styles.companiesSecInner} container`}>
                <CompactCompanySearch />
                <CompaniesGrid />
            </div>
        </div>
    )
}

export default CompanySearchSection