import React from 'react'
import { Field } from 'formik'
import styles from './TextInput.module.scss'

function TextInput(props) {

    return (
        <div className={`${styles.inputWrap} ${props.className}`}>
            {props.icon}
            <Field type="text" name={props.name} id={props.name} placeholder={props.placeholder} />
        </div>
    )
}

export default TextInput