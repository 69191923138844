import { useState, useEffect } from 'react';
import { useFormik } from 'formik'
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from 'yup'
import { useForgotPasswordMutation } from '../api/authApiSlice';
import { LgbtToast } from '../../../components/LgbtToast/LgbtToast'
import useRoleRoute from './useRoleRoute';

function useForgotPasswordForm(setFormError) {

    const navigate = useNavigate();

    const initialValues = {
        username: '',
        password: '',
        confirmPassword: '',
    }
    const validationSchema = Yup.object({
        username: Yup.string().required('Required'),
        password: Yup.string().required('Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required'),
    });

    const [resetPassword, { isFetching, isLoading }] = useForgotPasswordMutation()

    const forgotPasswordForm = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                const data = await resetPassword(values);
                if (data === undefined) {
                    setFormError('Something went wrong! Please try again later')
                    return;
                }

                if (data.error) {
                    if (data.error.status === 401 && data.error?.data.detail != undefined)
                        setFormError(data.error?.data.detail);
                    else if (data.error.status === 400 && data.error?.data.error != undefined)
                        setFormError(data.error?.data.error);
                    else
                        setFormError('Something went wrong! Please try again later')
                    return;
                } else if (data?.data?.warning != undefined && data?.data?.warning != '') {
                    setFormError(data?.data?.warning)
                    return;
                }

                forgotPasswordForm.resetForm();
                navigate('/password-update', { replace: true });

            } catch (error) {
                console.log('Something went wrong!', error);
            }
        }
    })


    return { forgotPasswordForm, isFetching, isLoading }
}

export default useForgotPasswordForm