import React from 'react'
import { Formik } from 'formik'

import usePasswordResetForm from '../../hooks/usePasswordResetForm';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useState } from 'react';
import styles from './ResetPassword.module.scss'

function ResetPassword() {

    const { passwordResetForm, isError, Error, AlertResponseMessage } = usePasswordResetForm();
    const [showPasswordOld, setShowPasswordOld] = useState(false);
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    return (
        <div className={`${styles.passwordResetForm} `}>
            <div className={styles.contentWrapper}>
                <div className={`${styles.header}`}>
                    <h2>Reset Your Password</h2>
                </div>
                <div className={`${styles.passwordFields}`}>
                    <Formik>
                        <form action="" onSubmit={passwordResetForm.handleSubmit}>
                            <div className={styles.inputs}>
                                <div className={styles.fieldWrap}>
                                    <div className={styles.labelBox}>
                                        <label htmlFor="old-password">Enter Your Current Password</label>
                                    </div>
                                    <div className={styles.fieldWrapper}>
                                        <input
                                            id="currentPassword"
                                            type={showPasswordOld ? 'text' : 'password'}
                                            name="old_password"
                                            value={passwordResetForm.values.old_password}
                                            onChange={passwordResetForm.handleChange}
                                        />
                                        <div
                                            onClick={() => setShowPasswordOld(!showPasswordOld)}
                                            className={styles.togglePasswordButton}
                                        >
                                            {showPasswordOld ? <FiEyeOff className={styles.eyeButton} onClick={() => setShowPasswordOld(!showPasswordOld)} /> : <FiEye className={styles.eyeButton} />}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldWrap}>
                                    <div className={styles.labelBox}>
                                        <label htmlFor="new_password">Enter New Password</label>
                                    </div>
                                    <div className={styles.fieldWrapper}>
                                        <input
                                            id="newPassword"
                                            type={showPasswordNew ? 'text' : 'password'}
                                            name="new_password"
                                            value={passwordResetForm.values.new_password}
                                            onChange={passwordResetForm.handleChange}
                                        />
                                        <div
                                            onClick={() => setShowPasswordNew(!showPasswordNew)}
                                            className={styles.togglePasswordButton}
                                        >
                                            {showPasswordNew ? <FiEyeOff className={styles.eyeButton} onClick={() => setShowPasswordNew(!showPasswordNew)} /> : <FiEye className={styles.eyeButton} />}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldWrap}>
                                    <div className={styles.labelBox}>
                                        <label htmlFor="confirm_password">Confirm New Password</label>
                                    </div>
                                    <div className={styles.fieldWrapper}>
                                        <input
                                            id="newPassword"
                                            type={showPasswordConfirm ? 'text' : 'password'}
                                            name="confirm_password"
                                            value={passwordResetForm.values.confirm_password}
                                            onChange={passwordResetForm.handleChange}
                                        />
                                        <div
                                            onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                            className={styles.togglePasswordButton}
                                        >
                                            {showPasswordConfirm ? <FiEyeOff className={styles.eyeButton} onClick={() => setShowPasswordConfirm(!showPasswordConfirm)} /> : <FiEye className={styles.eyeButton} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.buttonWrapper}>
                                <button type='submit'>Reset Password</button>
                            </div>
                        </form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
