import React, { useState } from 'react';
import { IoSearchOutline } from 'react-icons/io5';

import useCompanySearchForm from './hooks/useCompanySearchForm'

import styles from './styles/CompanySearchBox.module.scss'

function CompanySearchBox({ className }) {

    const { searchForm } = useCompanySearchForm();
    const [selectedLetter, setSelectedLetter] = useState(null);
    const scaleSearchHandler = (value) => {
        if (selectedLetter === value) {
            setSelectedLetter(null);
            searchForm.setFieldValue('searchByLetter', '');
            searchForm.handleSubmit();
        } else {
            searchForm.setFieldValue('searchByLetter', value);
            searchForm.handleSubmit();
            setSelectedLetter(value);
        }
    }
    var searchScale = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '0 - 9']

    return (
        <>
            <div className={`${styles.searchWrapper} ${className}`}>
                <form action="" onSubmit={searchForm.handleSubmit}>
                    <div className={styles.inputWarp}>
                        <IoSearchOutline />
                        <input
                            type="text"
                            name="company"
                            value={searchForm.values.company}
                            onChange={searchForm.handleChange}
                            id="company"
                            placeholder="Search By Employer Name"
                        />
                    </div>
                    <input type="submit" value="Search" className={styles.searchBtn} />
                </form>
            </div >
            <div className={styles.alphabetScale}>
                <p>Search by first letter :</p>
                <ul>
                    {
                        searchScale.map((value, index) => (
                            <li
                                key={index}
                                value={value}
                                className={selectedLetter === value ? styles.selected : ''}
                                onClick={() => scaleSearchHandler(value)}
                            >
                                {value}
                            </li>
                        ))
                    }
                </ul>
            </div>
        </>
    )
}

export default React.memo(CompanySearchBox)