import {api} from '../../../utils/api'

const homeApi = api.injectEndpoints({
    endpoints: (builder) => ({
        privacyPolicyPageFields: builder.query({
            query: () => `/exampleprovider/privacy-policy/`,
        }),
    }),
});

export const {usePrivacyPolicyPageFieldsQuery} = homeApi;
