import React from 'react'
import { useSelector } from 'react-redux'
import styles from './Banner.module.scss'
import bannerImage from '../../assets/images/generic-banner.png'

function Banner({ className = '', children }) {
    const siteSetting = useSelector(state => state.site)
    return (
        <section className={`${className} ${styles.banner}`} style={{ backgroundImage: `url(${siteSetting.bannerImage ?? bannerImage})` }}>
            <div className="container">
                {children}
            </div>
        </section>
    )
}

export default Banner
