import React from 'react'
import Slider from "react-slick"
import { Link } from 'react-router-dom'
import { IoIosArrowBack } from "react-icons/io";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styles from './PartnersCarousel.module.scss'


const SliderArrow = (props) => {
    let { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={style}
            onClick={onClick}
        >
            <IoIosArrowBack className="slick-arrow-icon" />
        </div>
    );
}


function PartnersCarousel(props) {

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        adaptiveHeight: false,
        arrows: true,
        autoplay: true,
        pauseOnHover: true,
        nextArrow: <SliderArrow dir="right" />,
        prevArrow: <SliderArrow dir="left" />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
        ]
    };
    return (
        <>
            <div className={styles.partnersSecWrapper}>
                <div className={styles.partnersSec + ' container'}>
                    <Slider {...settings} className={styles.partnerSlider}>
                        {props.companies.map((_, index) => {
                            if (index % 2 === 0) {
                                return (
                                    <div className={styles.slideItem} key={index}>
                                        <div className={styles.top}>
                                            <Link to={props.companies[index].link}>
                                                <img src={props.companies[index].logo} alt="partner-logo" />
                                            </Link>
                                        </div>
                                        <div className={styles.bottom}>
                                            {props.companies[index + 1] && (
                                                <Link to={props.companies[index + 1].link} className={styles.sliderBottomRow}>
                                                    <img src={props.companies[index + 1].logo} alt="partner-logo" />
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </Slider>
                </div>
            </div>

        </>
    );
}

export default PartnersCarousel