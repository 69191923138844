import React, { useState, useEffect } from 'react';
import styles from './styles/popup.module.scss';
import LgbtDecorLine from '../../components/LgbtDecorLine/LgbtDecorLine';
import { useSelector, useDispatch } from 'react-redux';
import { RxCross2 } from "react-icons/rx";
import { useHomepagePopUpDataQuery } from './api/popupApi'
import { disClose } from './popupSlice';

export default function PopUp() {
    const site = useSelector((state) => state.site);
    const [isPopupVisible, setPopupVisible] = useState(true);
    const { data, isLoading, isFetching, isError } = useHomepagePopUpDataQuery();
    const dispatch = useDispatch();

    const popup = useSelector(state => state.popup);
    const handleClosePopup = () => {
        setPopupVisible(false);
        dispatch(disClose(true));
    };
    useEffect(()=>{
        if(popup.disclosed){
            setPopupVisible(false) 
        }
    },[popup.disclosed])

    if (popup.disclosed) {
        return <></>
    }

    if (!data) {
        return <></>
    }
   
    return (
        <>
            {isPopupVisible && (
                <div className={styles.popupWrap} style={{ '--popup-background': data.right_content.background_colour }}>
                    <div className={styles.popupBackdrop}></div>
                    <div className={styles.popup}>
                        <RxCross2 className={styles.crossIcon} onClick={handleClosePopup} style={{ color: data.right_content.text_colour }} />
                        <div className={styles.jobLogos}>
                            <img src={site.siteLogo} className={styles.logo1} alt="Logo" />
                            {
                                site.domain.includes('lgbtjobs') && <LgbtDecorLine className={styles.gradient} />
                            }
                            <p dangerouslySetInnerHTML={{ __html: data.left_content.content }}></p>
                            <ul className={styles.logosWrap}>
                                {data.left_content.site_logos.map((logoData, index) => (
                                    <li key={index}>
                                        <a href={logoData.link} target="_blank" rel="noopener noreferrer">
                                            <img src={logoData.logo} alt={`Logo ${index + 1}`} />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className={styles.jobContents}>
                            <p className={styles.paragraph}>{data.right_content.title_content}</p>
                            <a href={`tel:${data.right_content.phone}`} style={{ color: data.right_content.text_colour }} className={styles.commonText}>
                                <h5>Please call us on</h5>
                                {data.right_content.phone}
                            </a>
                            <div className={styles.line} style={{ backgroundColor: data.right_content.text_colour }}></div>
                            <div className={styles.emilSection}>
                                <a href={`mailto:${data.right_content.email}`} style={{ color: data.right_content.text_colour }} className={styles.commonText}>
                                    <h5>Email our friendly team on</h5>
                                    {data.right_content.email}
                                </a>
                            </div>
                        </div>
                        {/* ====================================== */}
                        <div className={styles.jobContentsNew}>
                            <p className={styles.paragraph}>
                                {data.right_content.title_content} Please call us on &nbsp;
                                <a href={`tel:${data.right_content.phone}`} style={{ color: data.right_content.text_colour }} >{data.right_content.phone}</a>&nbsp;
                                or email our friendly team on&nbsp;
                                <a href={`mailto:${data.right_content.email}`} style={{ color: data.right_content.text_colour }} >
                                    {data.right_content.email}
                                </a>
                            </p>
                        </div>
                        {/*  */}
                    </div>
                </div>
            )}
        </>
    );
}
