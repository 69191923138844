import React from 'react'
import Select from 'react-select';
import { useGetSalaryTypesQuery } from '../../api/jobCreateApiSlice';


function SalaryFields({ form, fields, whichForm }) {
    const { data: salaryTypes, error, isError, isLoading } = useGetSalaryTypesQuery();
    const getSalaryTypeOption = (salaryType) => {
        return (
            salaryTypes &&
            salaryTypes.find((option) => option.value === salaryType) ||
            null
        );
    };

    return (
        <section className="jobSalaryFieldsWrap fieldGroupWrap">
            <div className="row">
                <div className="fieldHead col-md-3">
                    <h2>Salary</h2>
                </div>
                <div className="jobFields col-md-9">
                    <div className="row">
                        <div className="col-12">
                            <div className="fieldWrap salaryAmount">
                                {
                                    fields.includes('minimumSalary') ?
                                        <div className="minSalary">
                                            <div className="labelBox">
                                                {
                                                    whichForm === 'jobAlertForm' ?
                                                        <label htmlFor="salary">Minimum Salary</label>
                                                        : <label htmlFor="salary">Minimum Salary</label>
                                                }
                                            </div>
                                            <input
                                                id="salary"
                                                type="number"
                                                name="salary_amount"
                                                value={form.values.salary_amount}
                                                onChange={form.handleChange}
                                            />
                                        </div>
                                        : ''
                                }
                                {
                                    fields.includes('maximumSalary') ?
                                        <div className="maxSalary">
                                            <div className="labelBox">
                                                <label htmlFor="salary">Maximum Salary</label>
                                            </div>
                                            <input
                                                id="salary"
                                                type="number"
                                                name="maximum_salary"
                                                value={form.values.maximum_salary}
                                                onChange={form.handleChange}
                                            />
                                        </div>
                                        : ''
                                }
                            </div>
                            {
                                fields.includes('salaryType') ?
                                    <div className="fieldWrap">
                                        <div className="labelBox">
                                            <label htmlFor="salary">Salary Type</label>
                                        </div>
                                        <Select
                                            isSearchable={false}
                                            isClearable={false}
                                            value={getSalaryTypeOption(form.values.salary_type)}
                                            onChange={(selectedOption) =>
                                                form.setFieldValue('salary_type', selectedOption.value)
                                            }
                                            className="selectBox"
                                            options={salaryTypes}
                                        />
                                    </div>
                                    : ''
                            }
                            {
                                fields.includes('salaryText') ?
                                    <div className="fieldWrap">
                                        <div className="labelBox">
                                            <label htmlFor="salary">Salary Text</label>
                                        </div>
                                        <input
                                            id="salaryText"
                                            type="text"
                                            name="salary_text"
                                            value={form.values.salary_text}
                                            onChange={form.handleChange}
                                        />
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SalaryFields
