import { api } from '../../../utils/api';

const siteApiSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        getSiteSettings: builder.query({
            query: (domain = '') => {
                return `/multisitecontrolpanel/site-settings/`
            }
        }),
    }),
});
export const { useGetSiteSettingsQuery } = siteApiSlice;