import React, { useState, useEffect } from 'react';
import styles from './ZoomerWrapper.module.scss';

function ZoomerToolbar() {
    const [zoomLevel, setZoomLevel] = useState(1);

    const handleZoomIn = () => {
        if (zoomLevel < 1.5) {
            setZoomLevel(zoomLevel + 0.1);
            applyZoom(zoomLevel + 0.1);
        }
    };

    const handleZoomOut = () => {
        if (zoomLevel > 1) {
            setZoomLevel(zoomLevel - 0.1);
            applyZoom(zoomLevel - 0.1);
        }
    };

    const applyZoom = (level) => {
        document.body.style.zoom = level;
    };

    const toolbarStyle = {
        zoom: 1 / zoomLevel,
    };

    return (
        <div className={`${styles.ZoomerWrapper} ${styles.Sticky}`} style={toolbarStyle}>
            <div className={styles.VoicerPrompt}>
                <p>Highlight a few words in the Title or Paragraphs to read out the selection</p>
            </div>
            <div className={styles.ZoomerBlock}>
                <div className={styles.ZoomerInfo}>
                    <span className={styles.a1}>A</span>
                    <span className={styles.a2}>A</span>
                    <span className={styles.a3}>A</span>
                </div>
                <div className={styles.ZoomerToolbar}>
                    <div className={styles.ZoomerIcon} onClick={handleZoomIn} id="zoom-in">
                        +
                    </div>
                    <div className={styles.ZoomerIcon} onClick={handleZoomOut} id="zoom-out">
                        -
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ZoomerToolbar;
