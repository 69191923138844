import React from 'react';
import styles from './EmployerCard.module.scss';
import useCompanyUrl from '../../common/hooks/useCompanyUrl';
import IndustryIcon from '../../assets/images/industry.png';

function EmployerCard(props) {
    const employerUrl = useCompanyUrl();
    return (
        <div className={styles.card}>
            <div className={styles.cardLeft}>
                <a href={props.employer ? employerUrl(props.employer.slug) : '#'}>
                    <figure>
                        <img src={props.employer.logo?.file ? props.employer.logo?.file : IndustryIcon} alt={props.employer.logo?.alternative_text} />
                    </figure>
                </a>
            </div>
            <div className={styles.dataWrapper} >
                <div className={styles.cardCenter}>
                    <h3 dangerouslySetInnerHTML={{ __html: props.employer.name }} />
                </div>
                <div className={styles.cardRight}>
                    <a href={props.employer ? employerUrl(props.employer.slug) : '#'}>
                        View Employer
                    </a>
                </div>
            </div>
        </div>
    );
}

export default EmployerCard;
