import React from 'react'
import Button from '../Button/Button'
import LinedDecorTitle from '../LinedDecorTitle/LinedDecorTitle'

import styles from './TrendingJobs.module.scss'

function TrendingJobs(props) {
    return (
        <div className={styles.trendSecWrapper}>
            <div className="container">
                <LinedDecorTitle tag="h2">Trending Jobs</LinedDecorTitle>
                <ul className={styles.jobLinksWrapper}>
                    {
                        props.trendingJobs?.map((item, index) => (
                            <li key={index} className={styles.linkWrap}>
                                <Button className={styles.link} to={`/find-a-job?keyword=${item.button_text}`}>{item.button_text}</Button>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default TrendingJobs