import {api} from '../../../utils/api'


const contactFormAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        submitContactForm: builder.mutation({
            query: (values) => {
                return{
                    url: `/form/contact/`,
                    method: 'POST',
                    body: values,
                }
            },
        }),
    })
})


export const {
    useSubmitContactFormMutation
} = contactFormAPI