import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Button.module.scss'

function Button(props) {

  const propsClass = props.className ? props.className : '';

  return (
    <Link className={styles.button + ' ' + propsClass} to={props.to} onClick={props.onClick} target={props.openInNewTab === true ? `_blank` : ``}>{props.children}</Link>
  )
}

export default Button