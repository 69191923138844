import React from 'react';
import EmployerCard from '../EmployerCard/EmployerCard';

import styles from './EmployerList.module.scss';

function EmployerList(props) {
    return (
        <>
            {
                props.isError ?
                    <h3>No employers found</h3>
                    :
                    <>
                        {
                            props.employers?.count > 200 ?
                                <h2 className={styles.ListTitle}>Search results for {props.searchByLetter} - {props.employers?.count} employers matched in total.</h2>
                                : ''
                        }
                        <div className={styles.lifeBtm}>
                            <ul className={styles.list}>
                                {
                                    !props.isLoading || !props.isFetching ?
                                        props.employers?.results.map((item, index) => (
                                            <li key={index}>
                                                <EmployerCard employer={item} />
                                            </li>
                                        ))
                                        : null
                                }
                            </ul>
                        </div>
                    </>
            }
        </>
    );
}

export default EmployerList;
