import { api } from '../../../utils/api'
import authTokenHeader from '../../../common/functions/authTokenHeader'

const headerApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getHeader: builder.query({
            query: () => {
                return {
                    url: `/exampleprovider/header/`,
                    headers: {
                        ...authTokenHeader(),
                    }
                }
            },
            providesTags: ['HeaderNav'],
        }),
    }),
})

export const { useGetHeaderQuery } = headerApi