import React from 'react'
import Cookies from 'js-cookie';
import { disClose } from '../popupSlice';
import { useDispatch } from 'react-redux';

function usePopupInit() {
    const dispatch = useDispatch();
    return () => {
        const popupCookie =Cookies.get('popupDisclosed')
        if(popupCookie == 'true' ){
            dispatch(disClose(true));
        } 
    }
}

export default usePopupInit
