import useSelectTextColor from '../../../../common/hooks/useSelectTextColor'
import { useSelector } from 'react-redux'
import { useState, useRef, useEffect } from 'react';

function TabSection(data) {
    const siteConfig = useSelector(state => state.site)
    const colorSelector = useSelectTextColor()
    const [maxHeight, setMaxHeight] = useState(0)
    const [toggle, setToggle] = useState(1)
    function updateToggle(key) {
        setToggle(key)
    }

    const tabRefs = useRef([])

    useEffect(() => {
        const getHiddenElementHeight = (el) => {
            if (!el) return 0;
            const currentVisiblity = el.style.visibility
            const currentDisplay = el.style.display

            el.style.visibility = 'hidden';
            el.style.display = 'block';

            const height = parseInt(window.getComputedStyle(el).height);
            console.log(el, height);

            el.style.visibility = currentVisiblity;
            el.style.display = currentDisplay;
            return height;
        };

        if (tabRefs.current.length > 0) {


            const heights = tabRefs.current.map(ref => {
                return getHiddenElementHeight(ref)
            });
            const largestHeight = Math.max(...heights);
            setMaxHeight(largestHeight);

        }
    }, [data.data.tab, tabRefs.current]);

    return (
        <section className="tab-sec">
            <div className="container">
                <div className="tab">
                    <div className="TabTitle" style={{ color: colorSelector(data.primaryColor) }}>
                        {data.data.title}
                    </div>
                    <div className="tabs-wrapper">
                        {data.data.tab.map((item, key) => (
                            <button
                                className={toggle === key ? "tablinks active" : "tablinks"}
                                onClick={() => updateToggle(key)}
                                style={{
                                    color: toggle === key
                                        ? colorSelector(data.primaryColor)
                                        : data.primaryColor
                                }}
                                key={key}
                            >
                                {item.title}
                            </button>
                        ))}
                    </div>
                    <div className="tabWrapper" style={{ color: colorSelector(data.primaryColor) }}>
                        {data.data.tab.map((item, key) => (
                            <div
                                id="tabCnt"
                                ref={el => (tabRefs.current[key] = el)}
                                style={{ height: maxHeight == 0 ? 'unset' : maxHeight }}
                                className={`tabCnt ${toggle === key ? "showCnt" : ""}`}
                                dangerouslySetInnerHTML={{ __html: item.content }} key={key}
                            >
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TabSection;