import React from 'react'
import Button from '../Button/Button'
import cloud from '../../assets/images/cloud.png'
import styles from './CurvedPromoSection.module.scss'
import useSelectTextColor from '../../common/hooks/useSelectTextColor'
import { useSelector } from 'react-redux'

function CurvedPromoSection(props) {
    const siteConfig = useSelector(state => state.site)
    const colorSelector = useSelectTextColor()
    return (
        <div className={styles.promoSection}>
            <img src={cloud} alt="" className={styles.cloud1} />
            <img src={cloud} alt="" className={styles.cloud2} />
            <img src={cloud} alt="" className={styles.cloud3} />
            <div className={styles.cntWrapper}>
                <span className={styles.promoTitle}>
                    <span className={styles.redText}>PRIDE</span>
                    <span className={styles.orangeText}>CALENDAR</span>
                    <span className={styles.purpleText}>2024</span>
                </span>
                <img src={props.flagImage} alt="" className={styles.prideFlag} />
                <div className={styles.calendarInfo}>
                    <span style={{ color: colorSelector(siteConfig.decorColor7) }}>{props.card?.title}</span>
                    <Button className={styles.button} to={props.card?.button_link}>{props.card?.button_text}</Button>
                </div>
            </div>
        </div>
    )
}

export default CurvedPromoSection