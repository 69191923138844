import React from 'react'
import { useSelector } from 'react-redux'
import bannerImage from '../../../../assets/images/generic-banner.png'
import styles from './LogInConfirm.module.scss'

function LoginConfirm() {
	const siteSetting = useSelector(state => state.site)
	return (
		<section className={styles.loginConfirm} style={{ backgroundImage: `url(${siteSetting.bannerImage ?? bannerImage})` }}>
			{/* <section className={styles.loginConfirm} style={{ backgroundImage: `url(${bannerImage})` }}> */}
			<div className={`${styles.cntOuter} container`}>
				<h2>Email Verification mail sent to your registered email address, Please verify your email address to complete sign up/in.</h2>
			</div>
		</section>
	)
}

export default LoginConfirm;