import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import FieldError from '../../../JobPost/components/FieldError/FieldError';
import { useGetSectorsQuery } from '../../../JobPost/api/jobCreateApiSlice';

function JobSectorFieldsMultiple({ form }) {
    const { data: sectorTypes, error, isError, isLoading } = useGetSectorsQuery();

    const handleChange = (selectedOptions) => {
        form.setFieldValue(
            'sector',
            selectedOptions.map(option => option.value)
        );
    }

    const getSelectedSectors = (sectors) => {
        if (!sectorTypes) return [];
        return sectorTypes.filter(option => sectors?.includes(option.value));
    }

    return (
        <section className="jobTypeFieldsWrap fieldGroupWrap">
            <div className="row">
                <div className="fieldHead col-md-3">
                    <h2>Sector</h2>
                </div>
                <div className="jobFields col-md-9">
                    <div className="row">
                        <div className="col-12">
                            <div className="fieldWrap">
                                <div className="labelBox">
                                    <label htmlFor="sector-type">Sectors</label>
                                </div>
                                <Select
                                    isMulti
                                    isClearable={false}
                                    isSearchable={false}
                                    value={getSelectedSectors(form.values.sector)}
                                    onChange={handleChange}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    placeholder="Select..."
                                    options={sectorTypes || []}
                                />
                                <FieldError error={form.errors.sector} touched={form.touched.sector} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default JobSectorFieldsMultiple;
