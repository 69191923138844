import React from 'react'
import LgbtDecorLine from '../LgbtDecorLine/LgbtDecorLine'
import styles from './CompanyCard.module.scss'

function CompanyCard(props) {
    const { link, logo, jobCount } = props.data
    return (
        <a href={link || `/`} className={`${styles.companyCard} ${props.className}`}>
            <figure>
                <img src={logo.file} alt="" />
            </figure>
            <LgbtDecorLine />
            <div className={styles.jobCount}>
                <span>Active jobs</span>
                <span>{jobCount}</span>
            </div>
        </a>
    )
}

export default CompanyCard