import styles from './JobContent.module.scss'


function JobContent({ content }) {

  return (
    <div className={styles.jobContent} dangerouslySetInnerHTML={{ __html: content }} />
  )
}

export default JobContent
