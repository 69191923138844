import TextEditor from '../../../../components/TextEditor/TextEditor'
import FieldError from '../FieldError/FieldError'

function BasicJobFields({ form, fields, whichForm, placeHolder }) {
    return (
        <section className="basicJobFieldsWrap fieldGroupWrap">
            <div className="row">
                <div className="fieldHead col-md-3">
                    {fields ?
                        (fields.includes('title') ? <h2>Job title *</h2> : <h2>What is your Email address? *</h2>) : (<h2>Job title & Job description</h2>)}
                </div>
                <div className="jobFields col-md-9">
                    {
                        fields.includes('title') ?
                            <div className="fieldWrap">
                                <div className="labelBox">
                                    <label htmlFor="jobTitle">Job title*</label>
                                    <span>Maximum 100 characters</span>
                                </div>
                                {
                                    whichForm === 'jobAlertForm' ?
                                        <input
                                            placeholder={placeHolder}
                                            id="jobTitle"
                                            type="text"
                                            name="job_title_keyword"
                                            value={form.values.job_title_keyword}
                                            onChange={form.handleChange}
                                        />
                                        :
                                        <input
                                            id="jobTitle"
                                            type="text"
                                            name="title"
                                            value={form.values.title}
                                            onChange={form.handleChange}
                                        />
                                }
                                <FieldError error={form.errors.title} touched={form.touched.title} />
                            </div>
                            : ''
                    }
                    {
                        fields.includes('email') ?
                            <div className="fieldWrap">
                                <div className="labelBox">
                                    <label htmlFor="jobTitle">Email Address*</label>
                                </div>
                                <input
                                    id="email"
                                    type="email"
                                    name="email"
                                    value={form.values.email}
                                    onChange={form.handleChange}
                                />
                                <FieldError error={form.errors.title} touched={form.touched.title} />
                            </div> : ''
                    }
                    {
                        fields.includes('description') ?
                            <div className="fieldWrap">
                                <div className="labelBox">
                                    <label htmlFor="description">Job description</label>
                                    <span>Maximum 10000 characters</span>
                                </div>
                                <TextEditor id="job-description" value={form.values.description} setData={(value) => {
                                    form.setFieldValue('description', value)
                                }} />
                                <FieldError error={form.errors.description} touched={form.touched.description} />
                            </div>
                            : ''
                    }
                </div>
            </div>
        </section>
    )
}

export default BasicJobFields
