import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { HiOutlineExternalLink } from "react-icons/hi";

import { showLoader, hideLoader } from '../../GlobalLoader/globalLoaderSlice';
import styles from '../../Dashboard/styles/Dashboard.module.scss';
import seekerStyles from '../styles/JobSeekerDashboard.module.scss';

function useJobSeekerDashboard() {

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const dispatch = useDispatch();

    const [active, setActive] = useState('my-jobs');
    const [isMobile, setIsMobile] = useState(false);
    const auth = useSelector((state) => state.auth);
    const loaderId = { id: 'dashboard' };

    const date = new Date()
    const data = {};
    const employerData = {};

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    // useEffect(() => {
    //     if (isLoading || isFetching) {
    //         dispatch(showLoader(loaderId));
    //     } else {
    //         dispatch(hideLoader(loaderId));
    //     }
    // }, [isLoading, isFetching, dispatch]);

    const handleClick = (key) => {
        setActive(key);
    }

    const username = auth.user.name ? auth.user.name : auth.user.username;

    return (
        <section className={styles.dashboard}>
            <div className={`${styles.dashboardWrap}`}>
                <div className={styles.head}>
                    <div className={styles.titleAndButton}>
                        {
                            !isMobile ?
                                <h1>{username} - Dashboard</h1>
                                : <h1>{username} Dashboard</h1>
                        }
                        <a className={styles.jobPostBtn} href={`/job-alert`} target='_blank' rel="noopener noreferrer">Create Job Alert</a>
                    </div>
                    {
                        employerData?.length > 1 ?
                            <Select
                                className={styles.dashboardSelect}
                                value={1}
                                options={[]}
                                onChange={() => { }}
                            />
                            : ''
                    }
                </div>
                <div className={styles.subHead}>
                    <ul className={styles.links}>
                        <li>
                            <Link className={`${styles.link} ${active === 'my-jobs' ? styles.active : ''}`} onClick={() => { handleClick('my-jobs') }} to="/my-account/job-alerts">Manage Job Alerts</Link>
                        </li>
                        <li>
                            <Link className={`${styles.link} ${active === 'reset-password' ? styles.active : ''}`} onClick={() => { handleClick('reset-password') }} to="/my-account/reset-password">Reset Password</Link>
                        </li>
                        <li>
                            <Link className={`${styles.link} ${active === 'edit-profile' ? styles.active : ''}`} onClick={() => { handleClick('edit-profile') }} to="/my-account/edit-profile">Edit Profile</Link>
                        </li>
                    </ul>
                    {
                        auth.user.role.includes('recruiter') ? (
                            <div>
                                <div className={styles.downloadBtn}>
                                    <Link to="/dashboard" className={`${styles.link} ${seekerStyles.button}`}>Recruiter Dashboard <HiOutlineExternalLink /></Link>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
                <div className={styles.downloadDataWrapper}><span>Your listings are below</span> <Link className={`${styles.link} ${styles.downloadJobData}`} onClick={() => { }}>Download Job Data</Link></div>
                <div className={`tableCntWrap ${styles.tableWrap}`}>
                    <Outlet />
                </div>
            </div>
        </section>
    )
}

export default useJobSeekerDashboard
