import { useState } from 'react';
import { MdError } from "react-icons/md";
import styles from './SignUpInput.module.scss'

function SignUpInput({ name, form, type, placeholder }) {

    const [focused, setFocused] = useState(null);

    const handleFocus = (e) => {
        setFocused(styles.inputFocus)
    }

    const handleBlur = (e) => {
        setFocused(null);
    }

    let errorCls = form.touched[name] && form.errors[name] ? styles.inputError : null

    return (
        <div className={`${styles.inputWarp} ${errorCls} ${focused}`}>
            <input
                type={type}
                name={name}
                value={form.values[name]}
                onChange={form.handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                id={name}
                placeholder={placeholder} />
            <MdError className={styles.errorIcon} />
        </div>
    )
}

export default SignUpInput
