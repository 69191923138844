import {api} from '../../../utils/api'

const searchApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompanySearchResults: builder.query({
      query: ({ company, searchByLetter, page_size, page }) => `/jobmanager/employers/search/?&page_size=${page_size}&page=${page}&s=${company}${searchByLetter != null ? `&search-by=${searchByLetter}` : ''}`,
    }),
  }),
});
 
export const { useGetCompanySearchResultsQuery } = searchApiSlice;
