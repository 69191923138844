import React from 'react'
import Button from '../Button/Button'
import styles from './CompaniesTeaserBlockView.module.scss'
import logoArtifact from '../../assets/images/logo_symbol.png'

function CompaniesTeaserBlockView(props) {
    return (
        <div className={styles.teaserWrapper}>
            <img src={logoArtifact} className={styles.artifactLeft} alt="" />
            <div className={styles.teaser + ' container'}>
                <h2>{props.title}</h2>
                <div className={styles.teaserCnt}>
                    <p>{props.subtitle}</p>
                    <Button to={props.button.button_link}>{props.button.button_text}</Button>
                </div>
            </div>
            <img src={logoArtifact} className={styles.artifactRight} alt="" />
        </div>
    )
}

export default CompaniesTeaserBlockView