import React, { useEffect } from 'react';
import { useCheckResourceExistQuery } from './NoDataApiSlice';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../features/GlobalLoader/globalLoaderSlice';

function NoData({ children }) {
    const currentPath = window.location.href;
    const { data, isFetching, isLoading } = useCheckResourceExistQuery(encodeURIComponent(currentPath));
    const dispatch = useDispatch();
    const loaderId = { id: "404" };

    useEffect(() => {
        dispatch(showLoader(loaderId));
    }, [])

    if (!isFetching || !isLoading) {
        dispatch(hideLoader(loaderId));
    }

    if (data?.status_code === 404) {
        return <>{children}</>;
    }

    if (data?.to_url) {
        window.location.replace(data.to_url);
    }

    return null;
}

export default NoData;
