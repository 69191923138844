import {api} from '../../../utils/api'

const homeApi = api.injectEndpoints({
    endpoints: (builder) => ({
        homepageFields: builder.query({
            query: () => `/exampleprovider/home/`,
        }),
    }),
});

export const {useHomepageFieldsQuery} = homeApi;
