import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './LgbtInputBox.module.scss';

function LgbtInputBox(props) {
    const [inputValue, setInputValue] = useState(props.form.values[props.fieldName] || '');

    useEffect(() => {
        setInputValue(props.form.values[props.fieldName] || '');
    }, [props.form.values[props.fieldName]]);

    const handleInputChange = (event) => {
        const value = event.target.value ? parseInt(event.target.value) : '';
        setInputValue(value);
        props.form.setFieldValue(props.fieldName, value);
    };

    return (
        <div className={styles.inputBox}>
            <label htmlFor={props.fieldName}>{props.label}</label>
            <input
                type="number"
                id={props.fieldName}
                name={props.fieldName}
                value={inputValue}
                placeholder="£ Any"
                onChange={handleInputChange}
                className={styles.inputField}
            />
        </div>
    );
}

LgbtInputBox.propTypes = {
    label: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired
};

export default LgbtInputBox;
