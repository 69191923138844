import React from 'react'
import styles from './LgbtDecorLine.module.scss'

function LgbtDecorLine(props) {
    return (
        <div className={`${styles.decorWrapper} ${props.className}`}>
            <div className={`${styles.red} red`}></div>
            <div className={`${styles.orange} orange`}></div>
            <div className={`${styles.yellow} yellow`}></div>
            <div className={`${styles.green} green`}></div>
            <div className={`${styles.blue} blue`}></div>
            <div className={`${styles.purple} purple`}></div>
            <div className={`${styles.violet} violet`}></div>
        </div>
    )
}

export default LgbtDecorLine