import { useFormik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router-dom'

import { useJobAlertUpdateMutation, useGetJobAlertQuery } from "../api/jobAlertApiSlice";
import { useGetSectorsQuery } from "../../JobPost/api/jobCreateApiSlice"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as Yup from 'yup'
import { LgbtToast } from "../../../components/LgbtToast/LgbtToast";
import { useSelector } from "react-redux";


function useJobAlertUpdateForm() {

    const { jobAlertId } = useParams();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [AlertResponseMessage, setAlertResponseMessage] = useState("");
    const [submitAlert, { isFetching, isLoading, isError, Error }] = useJobAlertUpdateMutation();
    const { data: jobAlertData, isFetching: isJobAlertFetching, isLoading: isJobAlertLoading, isError: isJobAlertError } = useGetJobAlertQuery(jobAlertId);
    const { data: sectorTypes } = useGetSectorsQuery();
    const auth = useSelector((state) => state.auth || {})
    const formRef = useRef();
    const navigate = useNavigate();

    // useEffect(() => {
    //     handleReCaptchaVerify();
    // }, [executeRecaptcha]);

    const handleReCaptchaVerify = async () => {
        if (!executeRecaptcha) {
            return;
        }
        const token = await executeRecaptcha("alert_form_submission");
        jobAlertUpdateForm.setFieldValue("recaptcha", token);
    };

    const initialValues = {
        full_name: jobAlertData?.full_name || auth.user.name || auth.user.username || '',
        job_title_keyword: jobAlertData?.job_title_keyword || '',
        email: jobAlertData?.email || auth.user.email || '',
        location: jobAlertData?.location || '',
        work_from_home: jobAlertData?.work_from_home || false,
        salary_amount: jobAlertData?.minimum_salary || 0,
        minimum_salary: jobAlertData?.minimum_salary || 0,
        maximum_salary: jobAlertData?.maximum_salary || 0,
        contract_type: jobAlertData?.contract_type || [],
        sector: jobAlertData?.sector || [],
        alert_frequency: jobAlertData?.alert_frequency == 'Daily' ? 1 : 2,
        distance: jobAlertData?.distance || 0,
    };


    const validationSchema = Yup.object({
        'full_name': Yup.string(),
        'job_title_keyword': Yup.string().required('Job title is required'),
        'email': Yup.string().email('Invalid email format'),
        'location': Yup.string(),
        'work_from_home': Yup.bool(),
        'contract_type': Yup.array(),
        'salary_amount': Yup.number(),
        'minimum_salary': Yup.number(),
        'maximum_salary': Yup.number(),
        'sector': Yup.array(),
        // Desabled to ignore recaptcha error
        // 'recaptcha': Yup.string().required('Execute recaptcha not yet available'),
        'alert_frequency': Yup.number(),
        'distance': Yup.number(),
    })

    const jobAlertUpdateForm = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setStatus, resetForm, setFieldError }) => {
            values.minimum_salary = values.salary_amount
            if (values.minimum_salary > values.maximum_salary) {
                // Swap the values of minimum_salary and maximum_salary
                values = {
                    ...values,
                    minimum_salary: values.maximum_salary,
                    maximum_salary: values.minimum_salary,
                };
            }

            // Update alert frequency when new option comes on JobAlert Model in Backend
            values.alert_frequency = values.alert_frequency == 1 ? 'Daily' : 'Weekly';

            const response = await submitAlert({ body: values, jobAlertId: jobAlertData.id });
            if (response.error) {
                LgbtToast({ message: response.error.status, type: 'error' })
                setAlertResponseMessage('Alert not sent')
            } else {
                LgbtToast({ message: 'Alert sent successfully', type: 'success' })
                console.log(auth.isAuthenticated);

                if (auth.isAuthenticated)
                    navigate('/my-account/job-alerts')
                else
                    setAlertResponseMessage('Alert sent successfully')
                resetForm();
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
    });

    useEffect(() => {
        if (Object.keys(auth.user).length <= 0 && jobAlertUpdateForm) return;

        jobAlertUpdateForm.setValues({
            full_name: auth.user.name || auth.user.username || '',
            email: auth.user.email || ''
        });

    }, [auth.user, formRef, jobAlertData, isJobAlertLoading, isJobAlertFetching])

    useEffect(() => {
        if (jobAlertData) {
            if (!formRef.current) {
                formRef.current = true;
                jobAlertUpdateForm.setValues({
                    full_name: auth.user.name || auth.user.username || '',
                    email: auth.user.email || '',
                    job_title_keyword: jobAlertData.job_title_keyword || '',
                    location: jobAlertData.location || '',
                    work_from_home: jobAlertData.work_from_home || false,
                    salary_amount: jobAlertData.minimum_salary || 0,
                    minimum_salary: jobAlertData.minimum_salary || 0,
                    maximum_salary: jobAlertData.maximum_salary || 0,
                    contract_type: jobAlertData.contract_type || [],
                    sector: jobAlertData.sector,
                    alert_frequency: jobAlertData.alert_frequency,
                    distance: jobAlertData.distance || 0,
                });
            }
        }

    }, [jobAlertData]);

    return { jobAlertUpdateForm, isFetching, isLoading, isError, Error, AlertResponseMessage, jobAlertData };
}

export default useJobAlertUpdateForm;
