import { createSlice } from '@reduxjs/toolkit';
import bannerImage from '../../assets/images/static-banner.png'
import siteLogo from '../../assets/images/logo.png'

const siteSlice = createSlice({
    name: 'site',
    initialState: {
        primaryColor: '#365BB8',
        secondaryColor: '#008143',
        complementaryColor: '#FF8B01',
        decorColor1: '#F91F19',
        decorColor2: '#FF8B01',
        decorColor3: '#FEE800',
        decorColor4: '#008143',
        decorColor5: '#0064AA',
        decorColor6: '#8E31C9',
        decorColor7: '#9D11A0',
        decorColor8: '#492BD0',
        bannerImage: bannerImage,
        favIcon:'',
        siteLogo,
        domain: 'lgbtjobs.co.uk',
    },
    reducers: {
        initSiteSettings: (state, action) => {
            state.primaryColor = action.payload.primary_color
            state.secondaryColor = action.payload.secondary_color
            state.complementaryColor = action.payload.complementary_color
            state.decorColor1 = action.payload.decor_color1
            state.decorColor2 = action.payload.decor_color2
            state.decorColor3 = action.payload.decor_color3
            state.decorColor4 = action.payload.decor_color4
            state.decorColor5 = action.payload.decor_color5
            state.decorColor6 = action.payload.decor_color6
            state.decorColor7 = action.payload.decor_color7
            state.decorColor8 = action.payload.decor_color8
            state.bannerImage = action.payload.banner_image
            state.footerDecorColor = action.payload.footer_decor_color
            state.siteLogo = action.payload.logo
            state.favIcon = action.payload.favicon
            state.domain = action.payload.domain
            state.enableAccessToolbar = action.payload.enable_access_toolbar
            state.enableZoomToolbar = action.payload.enable_zoom_toolbar
            state.enableTextToSpeech = action.payload.enable_text_to_speech
        }
    },
});

export const { initSiteSettings } = siteSlice.actions;

export default siteSlice.reducer