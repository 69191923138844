import { api } from "../../utils/api"

const noDataApiSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        checkResourceExist: builder.query({
            query: (currentPath) => `/bulkredirector/lookup?from_url=${currentPath}`,
        }),
    }),
});

export const {
    useCheckResourceExistQuery
} = noDataApiSlice
