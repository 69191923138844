import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useGetSingleJobQuery } from './api/singleJobApiSlice'
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Cookies from 'js-cookie';

import { showLoader, hideLoader } from '../../features/GlobalLoader/globalLoaderSlice';
import JobSingleTemplate from './components/JobSingleTemplate';
import { useIncrementJobViewCountMutation, useIncrementJobApplyCountMutation } from './api/singleJobApiSlice'

import NotFound from '../../components/NotFound/NotFound'
import { LgbtToast } from '../../components/LgbtToast/LgbtToast'
import SomethingWrong from '../../components/SomethingWrong/SomethingWrong'

function JobSinglePage() {
    const { jobSlug } = useParams();
    const loaderId = { id: 'jobSingle' };
    const dispatch = useDispatch();
    const { data, isloading, isFetching, isError, error } = useGetSingleJobQuery(jobSlug);
    const siteSetting = useSelector(state => state.site)

    const [incrementJobViewCount, { isSuccess }] = useIncrementJobViewCountMutation();
    const [incrementJobApplyCount, { }] = useIncrementJobApplyCountMutation();

    const [recaptchaKey, setRecaptchaKey] = useState(null);
    const [showRecaptcha, setShowRecaptcha] = useState(1)

    const handleCaptchaChange = useCallback((value) => {
        setRecaptchaKey(value)
    }, []);

    useEffect(() => {
        if (data) {
            incrementJobViewCount({ jobId: data.id })
        }
        // Increment the view count by one only once per user even if they access the same page multiple times.
        // if (data && showRecaptcha === 1) {
        //     const cookieValue = Cookies.get('jobViewed');
        //     const existingViewedJobs = cookieValue ? JSON.parse(cookieValue) : [];
        //     if (!existingViewedJobs.includes(data.id)) {
        //         incrementJobViewCount({ jobId: data.id })
        //         const updatedViewedJobs = [...existingViewedJobs, data.id];
        //         Cookies.set('jobViewed', JSON.stringify(updatedViewedJobs));
        //     }
        //     setShowRecaptcha(2)
        // }
    }, [data]);

    useEffect(() => {
        if (isSuccess)
            setShowRecaptcha(3)
    }, [isSuccess])
    const jobApplyCount = () => {
        incrementJobApplyCount({ jobId: data.id, recaptcha: recaptchaKey })
    }

    useEffect(() => {
        if (isloading || isFetching)
            dispatch(showLoader(loaderId));
        else
            dispatch(hideLoader(loaderId));
    }, [dispatch, isloading, isFetching]);

    if (isError || error) {
        if (error.status === 404) {
            return <NotFound />;
        }
        LgbtToast({ message: error.status, type: "error" })
        return <SomethingWrong />
    }
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        >
            <JobSingleTemplate data={data} recaptchaChangeHandler={handleCaptchaChange} jobApplyCountHandler={jobApplyCount} showRecaptcha={showRecaptcha !== 2} />
        </GoogleReCaptchaProvider>
    )
}

export default JobSinglePage
