import React from 'react'
import { TfiSave } from 'react-icons/tfi'
import { useParams } from 'react-router-dom'

import Banner from '../../components/Banner/Banner'
import Button from '../../components/Button/Button'
import JobPostForm from './components/JobPostForm'
import JobUpdateForm from './components/JobUpdateForm'

import styles from './styles/JobPostPage.module.scss'

function JobPostPage({ type }) {
    const { jobId } = useParams()

    return (
        <>
            <Banner className={styles.banner}>
                <div className={styles.cnt}>

                    {jobId === undefined ?
                        (<h1>Create your job advert</h1>) :
                        (<h1>Update your job advert</h1>)}

                    {/* TODO: Save job as draft */}
                    {/* {jobId === undefined ? 
                    (<Button className={styles.bannerBtn}>
                        <TfiSave className={styles.icon} />
                        Save draft
                    </Button>) :
                    ''} */}

                    <span className={styles.req}>*Required fields</span>
                </div>
            </Banner>
            {type === "duplicate" ? (
                <JobPostForm jobId={jobId} />
            ) : (
                jobId === undefined ? (
                    <JobPostForm />
                ) : (
                    <JobUpdateForm />
                )
            )}
        </>
    )
}

export default JobPostPage
