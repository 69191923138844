import { isRejectedWithValue } from '@reduxjs/toolkit'

export const queryErrorLogger = (api) => (next) => (action) => {

    if (isRejectedWithValue(action)) {
        console.warn('QueryLogger: Something went wrong!', action.payload)
    }

    return next(action)
}
