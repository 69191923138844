import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { HiOutlineExternalLink } from "react-icons/hi";
import useForgotPasswordForm from '../../hooks/useForgotPasswordForm';
import RoundLoader from '../../../../components/Loader/RoundLoader'
import SignUpInput from '../../../../components/SignUpInput/SignUpInput'
import bannerImage from '../../../../assets/images/generic-banner.png'
import styles from './ForgotPassword.module.scss'

function ForgotPassword() {

    const [formError, setFormError] = useState('');

    const useForgotPasswordFormMemoized = useCallback(useForgotPasswordForm, [setFormError])
    const { forgotPasswordForm, isFetching, isLoading, extendedLoading } = useForgotPasswordFormMemoized(setFormError)
    const siteSetting = useSelector(state => state.site)


    return (
        <section className={styles.fgOuterWrapper} style={{ backgroundImage: `url(${siteSetting.bannerImage ?? bannerImage})` }}>
            <div className={`${styles.fgOuter} container`}>
                <div className={styles.formWrap}>
                    <h1>Reset Password</h1>
                    <form action="" onSubmit={forgotPasswordForm.handleSubmit}>
                        {formError && !(isFetching || isLoading) ? (
                            <div className={styles.textError}>{formError}</div>
                        ) : null}

                        <div className={styles.inputsOuterWrapper}>
                            <div className={styles.inputOuter}>
                                <SignUpInput name="username" form={forgotPasswordForm} type="text" placeholder="Username" />
                            </div>
                            <div className={styles.inputOuter}>
                                <SignUpInput name="password" form={forgotPasswordForm} type="password" placeholder="New Password" />
                            </div>
                            <div className={styles.inputOuter}>
                                <SignUpInput name="confirmPassword" form={forgotPasswordForm} type="password" placeholder="Confirm Password" />
                            </div>
                        </div>

                        <div className={styles.submitWrap}>
                            {
                                (isFetching || isLoading || extendedLoading) ?
                                    <RoundLoader className={styles.signUpLoader} /> :
                                    <button type="submit" className={styles.submitBtn}>Reset Password</button>
                            }
                        </div>
                        <div>
                            <p className={styles.loginLink}>If you are a JobSeeker? <Link to="/jobseeker-signup">Create Account <HiOutlineExternalLink /></Link></p>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword
