import React from 'react'
import LgbtDecorLine from '../LgbtDecorLine/LgbtDecorLine'

import styles from './LinedDecorTitle.module.scss'

function LinedDecorTitle(props) {
    const DynamicTag = props.tag || 'h2'
    return (
        <div className={styles.title}>
            <LgbtDecorLine className={styles.line} />
            <DynamicTag>{props.children}</DynamicTag>
            <LgbtDecorLine className={styles.line} />
        </div>
    )
}

export default LinedDecorTitle