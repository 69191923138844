import { useEffect } from 'react';
import { useJobAlertFrequencyOptionQuery } from '../../api/jobAlertApiSlice'
import styles from './FrequencyField.module.scss';

function FrequencyField({ form }) {

    const { data: frequencies } = useJobAlertFrequencyOptionQuery()

    const handleRadioChange = (value) => {
        form.setFieldValue('alert_frequency', value)
    };
    return (
        <section className={`${styles.alertFrequecncy} row`}>
            <div className={`${styles.Title} col-md-3`}>
                <h2>Alert Frequency</h2>
            </div>
            <div className={`${styles.checkBoxSec} col-md-9`}>
                <h3>How often should we send you jobs?*</h3>
                <div className={styles.checkBoxes}>
                    {
                        frequencies?.map((option, index) => (
                            <div className={styles.checkBox} key={index}>
                                <input
                                    id={option.key}
                                    type="radio"
                                    value={option.key}
                                    checked={form.values.alert_frequency === option.key}
                                    onChange={() => handleRadioChange(option.key)}
                                />
                                <label htmlFor={option.value}>{option.label}</label>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default FrequencyField;
