import React from 'react'
import { useSelector } from 'react-redux'

import Loader from '../../components/Loader/Loader'
import styles from './styles/GlobalLoader.module.scss'

function GlobalLoader() {

  const globalLoading = useSelector((state) => state.globalLoading.show || false);

  return (
    <div className={`${styles.loaderWrapper} ${globalLoading ? '' : styles.hide}`}>
      <Loader />
    </div>
  )
}

export default GlobalLoader
