import React from 'react'
import JobSearchBox from '../../features/JobSearchBoxNeurodiversity'
import styles from './StaticBannerNeurodiversity.module.scss'

const StaticBannerNeurodiversity = (props) => {

    const banner = props.background
    const mobileBanner = props.mobileBackground
    return (
        <div className={styles.homeBanner}>
            <div className={styles.animWrapper}>
                <img className={styles.desktop} src={banner} alt="" />
                <img className={styles.mobile} src={mobileBanner} alt="" />
            </div>
            <div className={styles.bannerCntWrapper + ' container'}>
                <div className={styles.titleWrapper}>
                    <span className={styles.headline}>
                        {props.title}
                    </span>
                </div>
                {props.children}
            </div>
        </div>
    )
}

export default StaticBannerNeurodiversity