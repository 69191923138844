import React, { useEffect, useState, useRef } from 'react'
import { EditorState, convertToRaw, ContentState, convertFromHTML, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './TextEditor.module.scss';

function TextEditor({ value, setData }) {

    const [editorState, setEditorState] = useState(() => {
        return EditorState.createEmpty();
    });
    const editorRef = useRef(null);

    useEffect(() => {
        if (!value) {
            setEditorState(EditorState.createEmpty());
        } else if (value && !editorRef.current) {
            value = value.replace(/\n/g, '<br/>');
            const blocksFromHTML = convertFromHTML(value);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
            editorRef.current = true;
        }
    }, [value]);

    const handlePastedText = (text, html, editorState) => {
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const newContentState = Modifier.replaceText(
            contentState,
            selectionState,
            text
        );
        const newEditorState = EditorState.push(
            editorState,
            newContentState,
            'insert-characters'
        );
        setEditorState(newEditorState);
        return 'handled';
    };

    return (
        <>
            <Editor
                toolbar={{
                    options: ['inline', 'list', 'textAlign', 'history', 'fontSize', 'fontFamily', 'link', 'blockType', 'image'],
                    inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                        bold: { className: 'undefined' },
                        italic: { className: 'undefined' },
                        underline: { className: 'undefined' },
                        strikethrough: { className: 'undefined' },
                        className: styles.inlineTools
                    },
                    blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    list: {
                        className: styles.listTool,
                        options: ['unordered'],
                    },
                    textAlign: {
                        className: styles.listTool,
                        options: ['left', 'center', 'right'],
                    },
                    fontSize: {
                        icon: 'image',
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    fontFamily: {
                        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    link: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        dropdownClassName: undefined,
                        showOpenOptionOnHover: true,
                        defaultTargetOption: '_self',
                        options: ['link', 'unlink'],
                        linkCallback: undefined
                    },
                    image: {
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        urlEnabled: true,
                        uploadEnabled: true,
                        alignmentEnabled: true,
                        uploadCallback: undefined,
                        previewImage: false,
                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                        alt: { present: false, mandatory: false },
                        defaultSize: {
                            height: 'auto',
                            width: 'auto',
                        },
                    },
                    history: {
                        className: styles.listTool,
                    },
                }}
                editorState={editorState}
                onEditorStateChange={(state) => {
                    setEditorState(state);
                }}
                handlePastedText={handlePastedText}
                onChange={(e) => {
                    const rawContentState = convertToRaw(editorState.getCurrentContent());
                    const markup = draftToHtml(rawContentState);
                    setData(markup);
                }}
                wrapperClassName={styles.editorWrapper}
                editorClassName={styles.editor}
                toolbarClassName={styles.toolbar}
                spellCheck="true"

            />
        </>
    )
}

export default TextEditor
