import React, { useEffect } from 'react'
import { api } from '../../utils/api';
import { useNavigate, useLocation } from "react-router-dom";
import { login } from '../Auth/hooks/authSlice'
import { useDispatch } from 'react-redux'
import { LgbtToast } from '../../components/LgbtToast/LgbtToast'

function AdminLoginInterceptor() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loc = useLocation();
    const queryParams = new URLSearchParams(loc.search);
    const accessToken = queryParams.get('access');
    const refreshToken = queryParams.get('refresh');


    useEffect(() => {
        if (accessToken == null || accessToken == false || refreshToken == null || refreshToken == false) {
            navigate('/', { replace: true })
            LgbtToast({ message: "Access Token not found", type: 'error' })
        } else {
            dispatch(login({
                access: accessToken,
                refresh: refreshToken
            }))
            setTimeout(() => {
                dispatch(api.util.invalidateTags(['HeaderNav']))
                navigate('/dashboard', { replace: true })
            }, 300)
        }
    }, [])
    return (
        <></>
    )
}

export default AdminLoginInterceptor
