import {api} from '../../../utils/api'

const popularJobsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getPopularJobs: builder.query({
            query: () => '/jobmanager/popular/jobs/',
        }),
    }),
})

export const { useGetPopularJobsQuery } = popularJobsApi