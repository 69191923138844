import React from 'react'
import styles from './BlackHistorySection.module.scss'
import useSelectTextColor from '../../common/hooks/useSelectTextColor'
import { useSelector } from 'react-redux'

function CurvedPromoSection(props) {
    const siteConfig = useSelector(state => state.site)
    const colorSelector = useSelectTextColor()
    return (
        <div className={styles.promoSection}>
            <div className={`${styles.container} container`}>
                <div className={styles.bannerWrapper}>
                    <a href={props.url}>
                        {window.innerWidth > 767 ? <img src={props.desktop} alt="" /> : <img src={props.mobile} alt="" />}
                    </a>
                </div>
            </div>
        </div>
    )
}

export default CurvedPromoSection