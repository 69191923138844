import React, { useState, useCallback, useEffect, useRef } from 'react'
import { ReactTags } from 'react-tag-autocomplete'
import { useGetSkillSuggestionsQuery } from '../../api/jobCreateApiSlice';

import styles from './SkillsInput.module.scss';


const GenerateApiList = (skills) => {
  const skillData = skills.map((skill) => {

    return skill.value;
  })
  return skillData;
}

const transformToSuggestions = (skills) => {
  const suggestions = skills.map((skill) => {
    return {
      value: skill.id,
      label: skill.name,
    }
  })
  return suggestions;
}


const SkillsInput = ({ selected = [], setSkills, form }) => {


  const [selectedSkills, setSelectedSkills] = useState([]);
  const { data: skillsSuggestion, isFetching, isLoading } = useGetSkillSuggestionsQuery();


  // handler on adding a new skill


  useEffect(() => {

    if (selected.length == 0 && selectedSkills.length > 0)
      setSelectedSkills([]);

  }, [selected])


  // set the skills to the form api data commes from props
  // convert selected skill [{value: 1, label: test},{value: 2, label: test2}] to [1, 2]
  // send to form
  useEffect(() => {
    setSkills(GenerateApiList(selectedSkills))
  }, [selectedSkills])


  //filter out the selected skills in the suggestion list from the api data
  // Load previously selected skills to the field
  useEffect(() => {
    if (skillsSuggestion && selected.length > 0 && selectedSkills.length === 0) {
      const filteredSkills = skillsSuggestion.filter(skill => selected.includes(skill.id));
      setSelectedSkills(transformToSuggestions(filteredSkills));
    }
  }, [skillsSuggestion, selected]);

  let suggestions = [];
  if (!isFetching && !isLoading && skillsSuggestion) {
    suggestions = transformToSuggestions(skillsSuggestion);
  }
  const onAdd = useCallback(
    (newTag) => {
      const normalizedNewTag = newTag.label.toLowerCase();
      const isDuplicate = selectedSkills.some(skill => skill.label.toLowerCase() === normalizedNewTag);

      if (isDuplicate) {
        alert('This skill is already added.');
      } else {
        setSelectedSkills([...selectedSkills, newTag]);
      }
    },
    [selectedSkills]
  )

  // handler on deleting new skill
  const onDelete = useCallback(
    (tagIndex) => {
      setSelectedSkills(selectedSkills.filter((_, i) => i !== tagIndex))
    },
    [selectedSkills]
  )

  // handler on validating the skill
  const onValidate = useCallback(
    (value) => (/^[a-zA-Z0-9\s-_]{3,}$/i.test(value)),
    [selectedSkills]
  )

  return (
    <div className={styles.skillsInputWrapper}>
      <ReactTags
        isInvalid={true}
        labelText={false}
        placeholderText="Type a skill and press enter"
        allowBackspace
        allowNew
        delimiterKeys={['Enter']}
        suggestions={suggestions}
        selected={selectedSkills}
        onAdd={onAdd}
        onDelete={onDelete}
        noOptionsText={false}
        onValidate={onValidate}
        newOptionText={`Add new skill: %value%`}
        ariaErrorMessage={document.getElementById('skillError')}
        classNames={{
          root: styles.skillTags,
          rootIsActive: styles.isActive,
          rootIsDisabled: 'is-disabled',
          rootIsInvalid: styles.isInvalid,
          label: 'react-tags__label',
          tagList: styles.selectedSkillsList,
          tagListItem: styles.selectedSkillsListItem,
          tag: 'react-tags__tag',
          tagName: 'react-tags__tag-name',
          comboBox: 'react-tags__combobox',
          input: styles.skillsInputField,
          listBox: styles.skillsListBox,
          noOptions: 'react-tags__listbox-no-options',
          option: styles.skillsListBoxItem,
          optionIsActive: 'is-active',
          highlight: styles.highlight,
        }}
      />
    </div>
  );
};

export default React.memo(SkillsInput);
