import React from 'react'
import GenericEmployer from '../templates/GenericEmployer/GenericEmployer'
import PostOffice from '../templates/PostOffice/PostOffice'
import dataJson from '../employer.json'
import BasicTemplate from '../templates/BasicTemplate/BasicTemplate'

function useEmployerComponentSelector() {

	return (data) => {
		let pageComponent = <></>

		// pageComponent = <GenericEmployer employer={dataJson} />
		// pageComponent = <BasicTemplate employer={dataJson} />
		// pageComponent = <PostOffice employer={dataJson} />

		switch (data.schema_standard) {
			case 'post_office':
				pageComponent = <PostOffice employer={data} />
				break
			case 'generic':
				pageComponent = <GenericEmployer employer={data} />
				break
			default:
				pageComponent = <BasicTemplate employer={data} />
		}
		return pageComponent
	}

}

export default useEmployerComponentSelector
