import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    employer: {},
    previewingFromDashboard: false
}

const dashboardSlice= createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setEmployer: (state, action) => { 
            state.employer = action.payload
        },
        setPreviewing: (state,action) => {
            state.previewingFromDashboard = (action.payload)
        }
    },
})

export const { setEmployer, setPreviewing } = dashboardSlice.actions
export default dashboardSlice.reducer