import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './styles/DocumentUploadField.module.scss';
import useDocumentField, { validMimeTypes } from '../../hooks/useDocumentField';
import DocumentPreview from '../DocumentPreview/DocumentPreview';
import DotLineLoader from '../../../../components/Loader/DotLineLoader/DotLineLoader';


const DocumentUploadField = ({ form }) => {
    const {
        uploadedFiles,
        handleFileSelection,
        handleFileRemove,
        handleEditClick,
        handleEditSubmit,
        error,
        setLoading,
        loading
    } = useDocumentField(form);

    /******** Handlers *********/

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleFileSelection,
        accept: validMimeTypes,
        multiple: true
    });

    return (
        <section className={`${styles.fieldGroupWrap} jobTypeFieldsWrap fieldGroupWrap`}>
            <div className="row">
                <div className="fieldHead col-md-3">
                    <h2>Upload Files</h2>
                </div>
                <div className="jobFields col-md-9">
                    <div {...getRootProps({ className: styles.dropzone })}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop the files here ...</p>
                        ) : (
                            <p>{uploadedFiles.length > 0 ? `${uploadedFiles.length} file(s) selected` : 'Drag & drop files here, or click to select files'}</p>
                        )}
                        {error && <p className={styles.error}>{error}</p>}
                    </div>

                    <div className={`${styles.fileList} `}>
                        <div className={`${styles.documentPreview} ${loading ? styles.documentPreviewActive : ''}`} >
                            {uploadedFiles.length ? uploadedFiles.map((file, index) => (
                                <DocumentPreview
                                    key={index}
                                    index={index}
                                    file={file}
                                    onUpdate={(index, updatedFile) => { handleEditSubmit(index, updatedFile) }}
                                    onRemove={(index) => { handleFileRemove(index) }}
                                    loading={setLoading}
                                />
                            ))
                                : ''}
                            {
                                loading ?
                                    <DotLineLoader className={styles.loader}/>
                                    : ''
                            }
                        </div>
                    </div>

                    <div className={styles.filemessage}>
                        <p>Note: It is recommended to add a specific file title to be displayed on the job detail page. If no title is provided, the default behavior will be to display the filename. This ensures a cleaner, more user-friendly presentation.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DocumentUploadField;
