import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import { api } from '../../../../utils/api'
import { login, setUser } from '../../hooks/authSlice'
import useAuthInit from '../../hooks/useAuthInit';
import useRoleRoute from '../../hooks/useRoleRoute';

function SignUpVerification() {

    const loc = useLocation();
    const navigate = useNavigate();
    const authInit = useAuthInit();
    const roleRoute = useRoleRoute();
    const queryParams = new URLSearchParams(loc.search);
    const accessToken = queryParams.get('acc');
    const refreshToken = queryParams.get('ref');
    const dispatch = useDispatch();
    const [status, setStatus] = useState(2);

    const auth = useSelector((state) => state.auth)

    useEffect(() => {
        if (!accessToken || !refreshToken) {
            navigate('/unauthorized', { replace: true });
        }
    }, [queryParams])

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                dispatch(login({ access: accessToken, refresh: refreshToken }));

                const initStatus = await authInit(); // Wait for authInit to complete
                if (initStatus) {
                    setStatus(1);
                    dispatch(api.util.invalidateTags(['HeaderNav']))
                } else
                    setStatus(0);

            } catch (error) {
                setStatus(0);
                console.error('Error initializing authentication:', error);
            }
        };

        initializeAuth(); // Call the async function
    }, []);

    useEffect(() => {
        if (status == 1 && roleRoute(auth.user.role))
            navigate(roleRoute(auth.user.role), { replace: true });
        if (status == 0)
            navigate('/unauthorized', { replace: true });
    }, [auth.user, status])

    if (status == 2) {
        return (
            <>
                Loading
            </>
        )
    }

    if (status == 0) {
        return (
            <div>
                Successfully Verified
            </div>
        )
    }

    return (<></>);

}

export default SignUpVerification
