import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FaPlay } from "react-icons/fa";
import { LgbtToast } from '../../components/LgbtToast/LgbtToast';
import './TextToSpeech.scss';
import { useLazyTextToSpeechQuery } from './api/textToSpeechApiSlice';

const TextToSpeech = () => {
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
  const [selectedText, setSelectedText] = useState('');
  const [audioData, setAudioData] = useState(null); // Stores audio URL
  const [error, setError] = useState(null);
  const site = useSelector(state => state.site);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const buttonRef = useRef(null);
  const audioRef = useRef(null);
  const [currentToastId, setCurrentToastId] = useState(null);
  const [triggerTextToSpeech, { isLoading, data, error: apiError }] = useLazyTextToSpeechQuery();

  useEffect(() => {
    if (apiError) {
      if (apiError.status === 413) {
        const toastId = LgbtToast({ message: apiError.data.message || 'The text is too long. Please select a shorter text.', type: 'error' });
        setCurrentToastId(toastId);
      } else {
        const toastId = LgbtToast({ message: 'An error occurred. Please try again.', type: 'error' });
        setCurrentToastId(toastId);
      }
    }
  }, [apiError]);

  const playAudio = (audioUrl) => {
    if (audioRef.current) {
      audioRef.current.src = audioUrl;
      audioRef.current
        .play()
        .catch(error => console.error('Error playing audio:', error));
    }
  };

  useEffect(() => {
    if (data) {
      if (selectedText) {
        const blob = new Blob([data], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(blob);
        setAudioData(audioUrl); // Store audio URL for playback
        if (isButtonClicked) {
          playAudio(audioUrl);
          setIsButtonClicked(false); // Reset flag after playing audio
        }
      }
    }
  }, [data]);

  const handlePlayButtonClick = () => {
    setIsButtonClicked(true); // Set flag to true
    if (audioData) {
      playAudio(audioData);
      setIsButtonClicked(false);
    }
  };

  const handleSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      const buttonTop = rect.bottom - bodyRect.top + 5;
      const buttonLeft = rect.left + rect.width / 2;
      setButtonPosition({ top: buttonTop, left: buttonLeft });
      const text = selection.toString().trim();
      setSelectedText(text);
    } else {
      setSelectedText('');
    }
  };

  useEffect(() => {
    const handleMouseUp = async (event) => {
      if (isButtonClicked) {
        // Reset the flag and ignore this mouseup event
        setIsButtonClicked(false);
        return;
      }

      handleSelection();

      if (selectedText && !audioData) {
        // Call API only if no audio data exists
        setError(null);
        try {
          triggerTextToSpeech(selectedText);

        } catch (error) {
          setError(apiError || error);
        } finally {
        }
      }
    };

    document.addEventListener('selectionchange', handleSelection);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('selectionchange', handleSelection);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [selectedText, audioData, isButtonClicked]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setSelectedText('');
        audioRef.current.pause();
        setAudioData(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <>
      {selectedText && (
        <button
          ref={buttonRef}
          className={`speech-button`}
          style={{ top: buttonPosition.top, left: buttonPosition.left, backgroundColor: site.primaryColor }}
          onClick={handlePlayButtonClick}
        >
          <FaPlay />
        </button>
      )}
      {error && <p className="error">Error fetching audio: {error.message}</p>}
      <audio ref={audioRef} hidden />
    </>
  );
};

export default TextToSpeech;
