import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ContactForm from "./components/ContactForm";
import sideStrips from "../../assets/images/side-strips.png";
import styles from "./styles/contact.module.scss";
import { useSelector } from "react-redux";

function Contact(props) {

  const siteConfig = useSelector(state => state.site)

  return (
    <div className={styles.contactPage}>
      {
        siteConfig.domain?.includes('lgbtjobs') === true ?
          <img
            src={sideStrips}
            className={`${styles.sideStrips} ${styles.sideStrips1}`}
            alt=""
          />
          : ''
      }
      <div className="container">
        <h1>Contact Us</h1>
      </div>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      >
        <ContactForm />
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default Contact;
