import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'

import useJobPostForm from '../hooks/useJobPostForm'

import BasicJobFields from './BasicJobFields/BasicJobFields'
import JobTypeFields from './JobTypeFields/JobTypeFields';
import JobLocationFields from './JobLocationFields/JobLocationFields';
import JobSectorFields from './JobSectorFields/JobSectorFields';
import SalaryFields from './SalaryFields/SalaryFields';
import SkillsFields from './SkillsFields/SkillsFields';
import JobApplyFields from './JobApplyFields/JobApplyFields';
import { useDispatch, useSelector } from 'react-redux';

import ExpiryFields from './ExpiryFields/ExpiryFields';
import { LgbtToast } from '../../../components/LgbtToast/LgbtToast';
import { toast } from 'react-toastify';
import { setPreviewing } from '../../Dashboard/dashboardSlice'


import '../styles/JobPostForm.Module.scss'
import DocumentUploadField from './DocumentUploadField/DocumentUploadField';

function JobPostForm(jobId) {
    const { jobPostForm, isJobFetching, isJobLoading, errorMessage } = useJobPostForm(jobId);
    const topOfPageRef = useRef(null);
    const dispatch = useDispatch();
    const [currentToastId, setCurrentToastId] = useState(null);


    useEffect(() => {
        // Scroll to the first error when the form is submitted
        if (jobPostForm.submitCount > 0 && Object.keys(jobPostForm.errors).length > 0) {
            const firstErrorField = Object.keys(jobPostForm.errors)[0];
            const element = document.getElementsByName(firstErrorField)[0];
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
        if (currentToastId) {
            toast.dismiss(currentToastId);
        }

        // show toast if there are errors on submission
        if (jobPostForm.submitCount > 0 && Object.keys(jobPostForm.errors).length > 0) {
            const toastId = LgbtToast({ message: 'Please fill in all required fields', type: 'error' })
            setCurrentToastId(toastId);
        }

        if (errorMessage && topOfPageRef.current) {
            topOfPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [jobPostForm.submitCount, errorMessage]);

    const previewHandler = () => {
        jobPostForm.setFieldValue('status', 5);
        dispatch(setPreviewing(true))
    }

    const draftHandler = () => {
        jobPostForm.setFieldValue('status', 6);
    }

    return (
        <section className="jobPostForm">
            <div ref={topOfPageRef} className=""></div>
            <Formik>
                <form action="" onSubmit={jobPostForm.handleSubmit}>
                    {/* <div className="advertBlock">
                        <div className="container">
                            <div className="row">
                                <div className="advertTitle col-3">
                                    <h2>Is your advert similar to a previous one?*</h2>
                                </div>
                                <div className="advertContent col-9">
                                    <p>I'd like to create a brand new advert - no</p>
                                    <p>I'd like to use details from a previous advert or draft as a template - yes</p>
                                    <div className="templateRadio">
                                        <label htmlFor="yes">
                                            <input type="radio" name="template" id="yes" />
                                            Yes
                                        </label>
                                        <label htmlFor="no">
                                            <input type="radio" name="template" id="no" />
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="jobDetails">
                        <div className="container">
                            <div className="errorMessageWrapper">
                                {errorMessage && <div className='error_message'>{errorMessage}</div>}
                            </div>
                            {/*** Basic Job Fields ***/}
                            <BasicJobFields form={jobPostForm} fields={["title", "description"]} />

                            {/*** Job type fields ***/}
                            <JobTypeFields form={jobPostForm} />

                            {/*** Job Location fields ***/}
                            <JobLocationFields form={jobPostForm} hintText={`<span>The postcode <b>will not</b> be displayed on the job advert.<br>It is used to make searching more accurate. </span>`} />

                            {/*** Job Sector fields ( )    ***/}
                            <JobSectorFields form={jobPostForm} />

                            {/*** Salary fields ***/}
                            <SalaryFields form={jobPostForm} fields={["minimumSalary", "maximumSalary", "salaryType", "salaryText"]} />

                            {/*** Skills fields ***/}
                            <SkillsFields form={jobPostForm} />

                            {/*** Apply section fields ***/}
                            <JobApplyFields form={jobPostForm} />

                            {/* ***Document Upload fields*** */}
                            <DocumentUploadField form={jobPostForm} />

                            {/*** Expiry fields ***/}
                            <ExpiryFields form={jobPostForm} />
                        </div>
                    </div>
                    <div className="submitBtn container">
                        <button className='previewBtn' onClick={draftHandler}>Save as draft</button>
                        <button className='previewBtn' onClick={previewHandler}>Preview job advert</button>
                        <button type="submit" disabled={!(jobPostForm.dirty)}>Post job advert</button>
                    </div>
                </form>
            </Formik>
        </section >
    )
}

export default JobPostForm
