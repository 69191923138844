import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    show: 0,
    initiators: {}
}

const globalLoaderSlice= createSlice({
    name: "globalLoader",
    initialState,
    reducers: {
        showLoader: (state, action) => {
            state.initiators[action.payload.id] = 1;
            state.show = true
        },
        hideLoader: (state, action) => {
            state.initiators[action.payload.id] = 0;
            state.show = Object.values(state.initiators).some((initiator) => initiator === 1)
        }
    },
})

export const { showLoader, hideLoader } = globalLoaderSlice.actions
export default globalLoaderSlice.reducer