import store from '../../store'

const authTokenHeader = () => {
    let accessToken = store.getState().auth.accessToken

    if (accessToken !== null) {
        return {
            'Authorization': `Bearer ${store.getState().auth.accessToken}`,
        };
    }
    else {
        return {};
    }
};

export default authTokenHeader