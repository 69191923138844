import React, { useState, useEffect, useRef } from 'react';
import JobsList from '../../../../components/JobsList/JobsList';
import { useGetEmployerJobsQuery } from '../../api/EmployerApiSlice';
import Pagination from '../../../../components/Pagination/Pagination';
import { useLocation } from 'react-router-dom';

function JobSection({ data, employer }) {

    const loc = useLocation();
    const queryParams = new URLSearchParams(loc.search);
    const keyword = queryParams.get('keyword');
    const location = queryParams.get('location');
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [contentLines, setContentLines] = useState(10);
    const contentRef = useRef(null);
    const [isContentTruncated, setIsContentTruncated] = useState(false)
    const [wrapperHeight, setOuterDivHeight] = useState(0);
    const [contentHeight, setInnerContentheight] = useState(0);
    const innerContentRef = useRef(null)

    const [page, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const page_size = 15;

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };


    const { data: jobs, isLoading, isFetching } = useGetEmployerJobsQuery({
        employerId: employer.id,
        page_size: page_size,
        page: page,
    });

    useEffect(() => {
        if (jobs) {
            setTotalPages(Math.ceil(jobs.count / page_size));
        }
    }, [jobs])

    useEffect(() => {
        if (data.content) {
            const contentElement = contentRef.current;
            const lineHeight = parseInt(window.getComputedStyle(contentElement).lineHeight);
            const maxHeight = lineHeight * contentLines;

            setIsContentTruncated(contentElement.scrollHeight > maxHeight);
            if (contentElement.scrollHeight > maxHeight) {
                setShowFullDescription(false);
            }
        }
    }, [contentLines, data.content]);


    useEffect(() => {
        if (contentRef.current) {
            setOuterDivHeight(contentRef.current.clientHeight);
        }
        if (innerContentRef.current) {
            setInnerContentheight(innerContentRef.current.scrollHeight);
        }
    }, []);

    useEffect(() => {
        const page = queryParams.get('page');
        setPageNumber(page ? parseInt(page, 10) : 1);
      }, [loc,queryParams]);

    return (
        <section className='job-sec'>
            {
                data.content || data.title || jobs ?
                    <div className="life-heading">
                        <div className="container">
                            <div className="life-top">
                                {
                                    data.title ?
                                        <h2 dangerouslySetInnerHTML={{ __html: data?.title }}></h2>
                                        : ''
                                }
                                <div
                                    ref={contentRef}
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: showFullDescription ? 'unset' : contentLines,
                                        WebkitBoxOrient: 'vertical',
                                    }}
                                >
                                    {
                                        data.content ?
                                            <p ref={innerContentRef} dangerouslySetInnerHTML={{ __html: data?.content }}></p>
                                            : ''
                                    }
                                </div>
                                {isContentTruncated && wrapperHeight < contentHeight && (
                                    <button onClick={toggleDescription} className="bttn">
                                        {showFullDescription ? 'Read Less' : 'Read More'}
                                    </button>
                                )}
                            </div>
                            <JobsList jobs={jobs} isLoading={isLoading} isFetching={isFetching} className="secJobListing" />
                            <Pagination
                                currentPage={page}
                                totalPages={totalPages}
                                pageSize={page_size}
                                onPageChange={setPageNumber}
                            />
                        </div>
                    </div>
                    : ''
            }
        </section>
    );
}

export default JobSection;
