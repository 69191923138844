import React from 'react'
import styles from './styles/CompaniesGrid.module.scss'

import PinsentLogo from '../../assets/images/Pinsent-logo.png'
import allenOveryLogo from '../../assets/images/allen-overy-logo.png'
import dhlLogo from '../../assets/images/dhl-logo.png'
import essexCountyLogo from '../../assets/images/essex-county-logo.png'
import odeonLogo from '../../assets/images/odeon-logo.png'
import CompanyCard from '../../components/CompanyCard/CompanyCard'

function CompaniesGrid() {
  return (
    <div className={styles.gridWrapper}>
      {
        [{
          logo: PinsentLogo,
          link: '/',
          jobCount: 50
        },
        {
          logo: allenOveryLogo,
          link: '/',
          jobCount: 50
        },
        {
          logo: dhlLogo,
          link: '/',
          jobCount: 50
        },
        {
          logo: essexCountyLogo,
          link: '/',
          jobCount: 50
        },
        {
          logo: odeonLogo,
          link: '/',
          jobCount: 50
        },
        {
          logo: allenOveryLogo,
          link: '/',
          jobCount: 50
        },
        {
          logo: essexCountyLogo,
          link: '/',
          jobCount: 50
        }].map((data, index) => {
          return (
            <div className={styles.companyItemWrap}>
              <CompanyCard data={data} key={index} className={styles.companyItem} />
            </div>
          )
        })
      }
    </div>
  )
}

export default CompaniesGrid