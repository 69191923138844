import React from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FaFileContract } from "react-icons/fa6";
import { Link } from "react-router-dom";

import useCompanyUrl from '../../common/hooks/useCompanyUrl'
import useJobUrl from "../../common/hooks/useJobUrl";
import styles from './JobCard.module.scss'
const JobCard = (props) => {
    const companyUrl = useCompanyUrl()
    const jobUrl = useJobUrl()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const Truncate = (data) => {
        if (windowWidth < 768 && data && data.length > 20) {
            return `${data.slice(0, 20)}...`;
        } else {
            return data;
        }
    };

    return (
        <div className={styles.card}>
            <div className={styles.contentWrapper}>
                <div className={styles.infoWrapper}>
                    <div className={styles.cardTitle}>
                        <Link to={props.job ? jobUrl(props.job.slug) : '#'} >
                            <h3 dangerouslySetInnerHTML={{ __html: props.job?.title }}></h3>
                        </Link>
                    </div>
                    <div className={styles.infoWrapperImage}>
                        <div className={styles.basicInfoWrapper}>
                            <div className={`${styles.basicInfo} `}>
                                {
                                    props.job?.location?.length > 0 ?
                                        <div className={styles.optionWrapper}>
                                            <FontAwesomeIcon icon={faLocationDot} className={styles.Icon} />
                                            <p>{Truncate(props?.job?.location[0].raw_location_string)}
                                                <i>{props?.job?.location?.length > 1 ? ' and more' : ''}</i>
                                            </p>
                                        </div>
                                        : ''
                                }
                                {props?.job?.salary &&
                                    <div className={styles.optionWrapper}>
                                        <FontAwesomeIcon icon={faMoneyBill} className={styles.Icon} />
                                        <p>{Truncate(props?.job?.salary)}</p>
                                    </div>
                                }
                            </div>
                            <div className={`${styles.basicInfo} ${styles.right}`}>

                                {

                                    props.job.contract_types && props.job.contract_types.length ?
                                        <div className={styles.optionWrapper}>
                                            <FaFileContract className={styles.Icons} />
                                            <p>{Truncate(props?.job?.contract_types[0])}
                                                <i>{props?.job?.contract_types?.length > 1 ? ' and more' : ''}</i>
                                            </p>
                                        </div>
                                        : ''
                                }
                            </div>
                        </div>
                        <div className={styles.iconWrapper}>
                            {
                                props.job.logo ?
                                    <Link to={companyUrl(props.job?.employer_slug)}>
                                        <figure className={styles.listLogo}>
                                            <img src={props.job.logo?.file} alt={props.job.logo.alternative_text} />
                                        </figure>
                                    </Link>
                                    : ''
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.iconWrapper}>
                    {
                        props.job.logo ?
                            <Link to={companyUrl(props.job?.employer_slug)}>
                                <figure className={styles.listLogo}>
                                    <img src={props.job.logo?.file} alt={props.job.logo.alternative_text} />
                                </figure>
                            </Link>
                            : ''
                    }
                </div>
            </div>
            <div className={styles.cardFooter}>
                <p className={styles.postedDate}>Posted {props.job.posted_date}</p>
            </div>
        </div >
    )

}


export default JobCard