import React from 'react'
import Button from '../Button/Button'
import { useSelector } from 'react-redux'
import styles from './SomethingWrong.module.scss'
import banner from '../../assets/images/static-banner.png'

function SomethingWrong() {
  const siteSetting = useSelector(state => state.site)
  return (
    <section style={{ background: `url(${siteSetting.bannerImage ?? banner})` }} className={styles._404}>
      <div className="container">
        <h1>Something Went Wrong!</h1>
        <p className={styles.cnt}>We are working on it. Please check back here later</p>
        <Button className={styles.btn} to='/'>Go to Home</Button>
      </div>
    </section>
  )
}

export default SomethingWrong
