import { useEffect, useState, useRef } from 'react'
import Button from 'react-bootstrap/esm/Button.js'
import Accordion from 'react-bootstrap/Accordion'

import { useGetContractTypesQuery, useGetSalaryRangeQuery, useGetSectorsQuery } from '../../../JobPost/api/jobCreateApiSlice'
import LgbtSelectBox from '../../../../components/LgbtSelectBox/LgbtSelectBox.jsx'
import LgbtInputBox from '../../../../components/LgbtInputBox/LgbtInputBox.jsx'
import styles from './SearchFilter.module.scss'

function JobSearchFilter({ form }) {
    const { data: contractData } = useGetContractTypesQuery()
    const { data: salaryData } = useGetSalaryRangeQuery()
    const { data: sectorData } = useGetSectorsQuery()
    const [selectedContractTypes, selectContractType] = useState([]);
    const [selectedSectors, selectSector] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);
    const filterRef = useRef(null);
    const [isFixed, setIsFixed] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isIpad, setIsIpad] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 992) {
            setIsIpad(true)
        } else {
            setIsIpad(false)
        }
    }, [window.innerWidth])


    const handleCheckboxChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedContractTypes.includes(selectedValue)) {
            selectContractType(selectedContractTypes.filter(item => item !== selectedValue));
            if (selectedValue.toLowerCase() === form.values.keyword.toLowerCase()) {
                form.setFieldValue('keyword', '')
            }
        } else {
            selectContractType([selectedValue, ...selectedContractTypes]);
        }
    }

    const handleSectorCheckboxChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedSectors.includes(selectedValue)) {
            selectSector(selectedSectors.filter(item => item !== selectedValue));
            if (selectedValue.toLowerCase() === form.values.keyword.toLowerCase()) {
                form.setFieldValue('keyword', '')
            }
        } else {
            selectSector([selectedValue, ...selectedSectors]);
        }
    }

    useEffect(() => {
        const searchFormPromise = form.setFieldValue('contractTypes', selectedContractTypes);
        searchFormPromise?.then((res) => {
            form.submitForm();
        });
    }, [selectedContractTypes]);

    useEffect(() => {
        const searchFormPromise = form.setFieldValue('sector', selectedSectors);
        searchFormPromise?.then((res) => {
            form.submitForm();
        });
    }, [selectedSectors]);

    const setSalaryMessage = (form) => {
        let message = '';
        const minSalary = form.values.minSalary ? form.values.minSalary : 0;
        const maxSalary = form.values.maxSalary ? form.values.maxSalary : 0;
        if (minSalary === 0 && maxSalary === 0) {
            message = '';
        } else if (minSalary === 0 && maxSalary !== 0) {
            message = `Up to ${String.fromCharCode(163) + maxSalary}`;
        } else if (minSalary !== 0 && maxSalary === 0) {
            message = `${String.fromCharCode(163) + minSalary}+`;
        } else if (minSalary === maxSalary) {
            message = `${String.fromCharCode(163) + minSalary}`
        }
        else {
            message = `${String.fromCharCode(163) + minSalary} to ${String.fromCharCode(163) + maxSalary}`;
        }

        return (
            <div className={styles.closeButton}>
                <span>{message}</span>
                <button onClick={() => clearAllSalary()}>&#x2715;</button>
            </div>
        );
    };
    const clearAllSalary = () => {
        form.setFieldValue('minSalary', 0)
        form.setFieldValue('maxSalary', 0)
        form.submitForm();
    }

    const handleFilter = () => {
        form.submitForm();
    };

    const setSectorMessage = () => {
        let sectorMessage = '';
        if (form.values.sector && form.values.sector.length > 0) {
            sectorMessage = form.values.sector.map(sectorItem => (
                <div className={styles.closeButton}>
                    <span key={sectorItem}>{sectorItem}&nbsp;</span>
                    <button onClick={() => clearSectorValue(sectorItem)}>&#x2715;</button>
                </div>
            ));
        } else {
            sectorMessage = '';
        }
        return sectorMessage;
    };
    const clearSectorValue = (value = '') => {
        if (value === '') {
            selectSector([])
            return;
        }
        const updatedSectorItems = selectedSectors.filter(item => item !== value);
        selectSector(updatedSectorItems)
        if (value.toLowerCase() === form.values.keyword.toLowerCase()) {
            form.setFieldValue('keyword', '')
        }
    }

    const setContractMessage = () => {
        let contractMessage = '';
        if (form.values.contractTypes && form.values.contractTypes.length > 0) {
            contractMessage = form.values.contractTypes.map(contract => (
                <div className={styles.closeButton}>
                    <span key={contract}>{contract}&nbsp;</span>
                    <button onClick={() => clearContratctValue(contract)}>&#x2715;</button>
                </div>
            ));
        } else {
            contractMessage = '';
        }
        return contractMessage;
    };
    const clearContratctValue = (value = '') => {
        if (value === '') {
            selectContractType([])
            return;
        }
        const updatedContractItems = selectedContractTypes.filter(item => item !== value);
        selectContractType(updatedContractItems)
        if (value.toLowerCase() === form.values.keyword.toLowerCase()) {
            form.setFieldValue('keyword', '')
        }
    }

    var postedDateData = ["Anytime", "Today", "Last 3 days", "Last week", "Last 2 weeks"];
    const setDatePostedMessage = (form) => {
        var sortMessage = ''
        sortMessage = form.values.postedDate ? (
            <div className={styles.closeButton}>
                <span>{form.values.postedDate}&nbsp;</span>
                <button onClick={() => { form.setFieldValue('postedDate', ''); form.submitForm(); }}>&#x2715;</button>
            </div>
        ) : ''
        return sortMessage
    }

    const toggleFilters = () => {
        setIsOpen(!isOpen);
        setFilterOpen(!filterOpen);
    };

    const hasValue = Object.values(form.values).some(value => {
        if (Array.isArray(value)) {
            return value.length > 0;
        } else {
            return !!value;
        }
    });

    return (
        <>
            <div className={`${styles.filterOpen} ${isOpen ? '' : styles.active}`}>
                <Button onClick={toggleFilters}>Open Filters</Button>
            </div>
            <div className={`${styles.filterClose} ${isOpen ? styles.active : ''}`}>
                <Button onClick={toggleFilters}>Close Filters</Button>
            </div>
            <div className={styles.sidebarWrapper}>
                <div className={`${styles.values} ${styles.mobile}`}>
                    {
                        hasValue ?
                            <h3>
                                Applied filters
                                <span onClick={() => {
                                    clearContratctValue();
                                    clearSectorValue();
                                    form.resetForm();
                                    form.submitForm();
                                }}> Clear all</span>
                            </h3>
                            : ''
                    }
                    <div className={styles.Message}>
                        {
                            <div className={styles.contratcMessageWrapper}>
                                <div className={styles.contractMessage}>
                                    {
                                        form.values.minSalary || form.values.maxSalary ?
                                            setSalaryMessage(form) :
                                            ''
                                    }
                                    {
                                        form.values.contractTypes.length > 0 ?
                                            setContractMessage(form)
                                            : ''
                                    }
                                    {
                                        form.values.postedDate ?
                                            setDatePostedMessage(form)
                                            : ''
                                    }
                                    {
                                        form.values.sector.length > 0 ?
                                            setSectorMessage(form)
                                            : ''
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div ref={filterRef} className={`${styles.filterWrapper} ${filterOpen ? '' : styles.collapsed} ${isFixed ? styles.fixed : ''}`}>
                    <div className={styles.title}>
                        <h2>Filter your search</h2>
                    </div>
                    <div className={styles.contents}>
                        {
                            isIpad ?
                                <div className={styles.buttonWrapper}>
                                    <Accordion defaultActiveKey="0">
                                        <div className={`${styles.searchGroup} ${styles.searchGroupChild}`}>
                                            <Accordion.Item
                                                className={styles.accordionItem}
                                                eventKey="0"
                                            >
                                                <Accordion.Header className={styles.accordionHeader} >Salary range</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className={styles.filterBtns}>
                                                        <div className={styles.selectBox}>
                                                            <span>From:</span>
                                                            <LgbtInputBox
                                                                fieldName="minSalary"
                                                                form={form}
                                                            />
                                                        </div>
                                                        <div className={styles.selectBox}>
                                                            <span>To:</span>
                                                            <LgbtInputBox
                                                                fieldName="maxSalary"
                                                                form={form}
                                                            />
                                                        </div>
                                                        <div className={styles.buttonGroup}>
                                                            <button type="button" onClick={clearAllSalary} className={styles.clearButton}>Clear</button>
                                                            <button type="button" onClick={handleFilter} className={styles.filterButton}>Search</button>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </div>
                                        <div className={styles.searchGroup}>
                                            <Accordion.Item
                                                eventKey="1"
                                                className={styles.accordionItem}
                                            >
                                                <Accordion.Header className={styles.accordionHeader}>Job type</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className={styles.contractType}>
                                                        {
                                                            contractData?.map((contractType, index) => {
                                                                return (
                                                                    <div key={contractType.value} className={styles.filterCheckbox}>
                                                                        <input
                                                                            key={index}
                                                                            type="checkbox"
                                                                            id={contractType.label}
                                                                            name="contract-type"
                                                                            value={contractType.label}
                                                                            checked={selectedContractTypes.includes(contractType.label.toString()) ? "checked" : ""}
                                                                            onChange={handleCheckboxChange}
                                                                        />
                                                                        <label htmlFor={contractType.label}>{contractType.label}</label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </div>
                                        <div className={styles.searchGroup}>
                                            <Accordion.Item
                                                className={styles.accordionItem}
                                                eventKey="2"
                                            >
                                                <Accordion.Header className={styles.accordionHeader}>Date posted</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className={styles.filterBtns}>
                                                        <div className={styles.selectBox}>
                                                            <LgbtSelectBox
                                                                title={form.values.postedDate ? form.values.postedDate : 'Date posted'}
                                                                form={form}
                                                                options={postedDateData}
                                                                fieldName="postedDate"
                                                                selectHandler={(value) => {
                                                                    form.setFieldValue('postedDate', value);
                                                                    form.submitForm();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </div>
                                        <div className={styles.searchGroup}>
                                            <Accordion.Item
                                                eventKey="3"
                                                className={styles.accordionItem}
                                            >
                                                <Accordion.Header className={styles.accordionHeader}>Sector</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className={styles.contractType}>
                                                        {
                                                            sectorData?.map((sector, index) => {
                                                                return (
                                                                    <div key={sector.value} className={styles.filterCheckbox}>
                                                                        <input
                                                                            key={index}
                                                                            type="checkbox"
                                                                            id={sector.label}
                                                                            name="sector"
                                                                            value={sector.label}
                                                                            checked={selectedSectors.includes(sector.label.toString()) ? "checked" : ""}
                                                                            onChange={handleSectorCheckboxChange}
                                                                        />
                                                                        <label htmlFor={sector.label}>{sector.label}</label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </div>
                                    </Accordion>
                                </div>
                                :
                                <div className={styles.buttonWrapper}>
                                    <div className={`${styles.searchGroup} ${styles.searchGroupChild}`}>
                                        Salary range
                                        <div className={styles.filterBtns}>
                                            <div className={styles.selectBox}>
                                                <span>From:</span>
                                                <LgbtInputBox
                                                    fieldName="minSalary"
                                                    form={form}
                                                />
                                            </div>
                                            <div className={styles.selectBox}>
                                                <span>To:</span>
                                                <LgbtInputBox
                                                    fieldName="maxSalary"
                                                    form={form}
                                                />
                                            </div>
                                            <div className={styles.buttonGroup}>
                                                <button type="button" onClick={clearAllSalary} className={styles.clearButton}>Clear</button>
                                                <button type="button" onClick={handleFilter} className={styles.filterButton}>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.searchGroup}>
                                        Job type
                                        <div className={styles.contractType}>
                                            {
                                                contractData?.map((contractType, index) => {
                                                    return (
                                                        <div key={contractType.value} className={styles.filterCheckbox}>
                                                            <input
                                                                key={index}
                                                                type="checkbox"
                                                                id={contractType.label}
                                                                name="contract-type"
                                                                value={contractType.label}
                                                                checked={selectedContractTypes.includes(contractType.label.toString()) ? "checked" : ""}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                            <label htmlFor={contractType.label}>{contractType.label}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.searchGroup}>
                                        Date posted
                                        <div className={styles.filterBtns}>
                                            <div className={styles.selectBox}>
                                                <LgbtSelectBox
                                                    title={form.values.postedDate ? form.values.postedDate : 'Date posted'}
                                                    form={form}
                                                    options={postedDateData}
                                                    fieldName="postedDate"
                                                    selectHandler={(value) => {
                                                        form.setFieldValue('postedDate', value);
                                                        form.submitForm();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.searchGroup}>
                                        Sector
                                        <div className={styles.contractType}>
                                            {
                                                sectorData?.map((sector, index) => {
                                                    return (
                                                        <div key={sector.value} className={styles.filterCheckbox}>
                                                            <input
                                                                key={index}
                                                                type="checkbox"
                                                                id={sector.label}
                                                                name="sector"
                                                                value={sector.label}
                                                                checked={selectedSectors.includes(sector.label.toString()) ? "checked" : ""}
                                                                onChange={handleSectorCheckboxChange}
                                                            />
                                                            <label htmlFor={sector.label}>{sector.label}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}

export default JobSearchFilter