import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useSignUpForm from '../hooks/useSignUpForm'
import SignUpInput from '../../../components/SignUpInput/SignUpInput';
import RoundLoader from '../../../components/Loader/RoundLoader'
import bannerImage from '../../../assets/images/generic-banner.png'
import { HiOutlineExternalLink } from "react-icons/hi";
import styles from '../styles/SignUp.module.scss'
import ConfirmationPopup from '../../../components/ConfirmationPopup/ConfirmationPopup';

function SignUp() {

    const [formError, setFormError] = useState('');

    const useSignUpFormMemoized = useCallback(useSignUpForm, [setFormError])
    const { signUpForm, isFetching, isLoading, extendedLoading, showConfirmPopup, setShowConfirmPopup, handleExistingUserLogin, popupQuestion } = useSignUpFormMemoized(setFormError)
    const siteSetting = useSelector(state => state.site)


    const handlePopupConfirm = () => {
        setShowConfirmPopup(false);
        handleExistingUserLogin(signUpForm.values); // Call the existing user login mutation
    };

    const handlePopupCancel = () => {
        setShowConfirmPopup(false);
    };

    return (
        <>
            <section className={styles.signUp} style={{ backgroundImage: `url(${siteSetting.bannerImage ?? bannerImage})` }}>
                <div className={`${styles.forOuter} container`}>
                    <div className={styles.formWrap}>
                        <h1>JobSeeker Sign Up</h1>
                        <form action="" onSubmit={signUpForm.handleSubmit}>
                            {formError && !(isFetching || isLoading) ? (
                                <div className={styles.textError}>{formError}</div>
                            ) : null}

                            <div className={styles.inputsOuterWrapper}>
                                <div className={styles.inputOuter}>
                                    <SignUpInput name="firstname" form={signUpForm} type="text" placeholder="First name" />
                                </div>
                                <div className={styles.inputOuter}>
                                    <SignUpInput name="lastname" form={signUpForm} type="text" placeholder="Last name" />
                                </div>
                                <div className={styles.inputOuter}>
                                    <SignUpInput name="username" form={signUpForm} type="text" placeholder="Username" />
                                </div>
                                <div className={styles.inputOuter}>
                                    <SignUpInput name="email" form={signUpForm} type="email" placeholder="Email" />
                                </div>
                                <div className={styles.inputOuter}>
                                    <SignUpInput name="password" form={signUpForm} type="password" placeholder="Password" />
                                </div>
                                <div className={styles.inputOuter}>
                                    <SignUpInput name="confirmPassword" form={signUpForm} type="password" placeholder="Confirm Password" />
                                </div>
                                <div className={`${styles.inputOuter} ${styles.linkedInUrl}`}>
                                    <SignUpInput name="linkedin_profile_url" form={signUpForm} type="url" placeholder="Linked-In Profile URL" />
                                </div>
                            </div>

                            <div className={styles.submitWrap}>
                                {
                                    (isFetching || isLoading || extendedLoading) ?
                                        <RoundLoader className={styles.signUpLoader} /> :
                                        <button type="submit" className={styles.submitBtn}>Sign Up</button>
                                }
                            </div>
                            <div>
                                <p className={styles.loginLink}>Already have an account? <Link to="/login">Log in <HiOutlineExternalLink /></Link></p>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {showConfirmPopup && (
                <ConfirmationPopup
                    message={popupQuestion ? popupQuestion : 'This account already exists. Do you want to log in as an existing user?'}
                    onConfirm={handlePopupConfirm}
                    onCancel={handlePopupCancel}
                />
            )}
        </>
    )
}

export default SignUp
