import React from 'react'
import styles from './TwoImageSection.module.scss'

function TwoImageSection(data) {
    return (
        <div className="container">
            <div className={`${styles.twoImageSection} row`}>
                <div className="col-md-6">
                    <img src={data.data.image_left} alt="" />
                </div>
                <div className={`${styles.bottom} col-md-6`}>
                    <img src={data.data.image_right} alt="" />
                </div>
            </div>
        </div>
    )
}

export default TwoImageSection
