import Select from 'react-select';
import FieldError from '../FieldError/FieldError';
import { useGetContractTypesQuery } from '../../api/jobCreateApiSlice';


function JobTypeFields({ form }) {

    const { data: contractTypes, error, isError, isLoading } = useGetContractTypesQuery();

    const getJobTypeOptions = (selectedContractTypes) => {
        if (!contractTypes) return [];
        return contractTypes.filter(option => selectedContractTypes?.includes(option.value));
    }

    return (
        <section className="jobTypeFieldsWrap fieldGroupWrap">
            <div className="row">
                <div className="fieldHead col-md-3">
                    <h2>Job type</h2>
                </div>
                <div className="jobFields col-md-9">
                    <div className="row">
                        <div className="col-12">
                            <div className="fieldWrap">
                                <div className="labelBox">
                                    <label htmlFor="contract-type">Contract type</label>
                                </div>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    blurInputOnSelect={false}
                                    value={getJobTypeOptions(form.values.contract_types)}
                                    options={contractTypes}
                                    onChange={(selectedOptions) =>
                                        form.setFieldValue(
                                            'contract_types',
                                            selectedOptions.map(option => option.value)
                                        )
                                    }
                                />
                                <FieldError error={form.errors.contract_types} touched={form.touched.contract_types} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default JobTypeFields
