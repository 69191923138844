import React from 'react'
import Header from '../../features/Header/Header'
import Footer from '../../features/Footer'


function Layout({ children, layoutStyles }) {

  let siteSpecificStyles = {};
  if (layoutStyles.domain?.includes('neurodiversityjobs'))
    siteSpecificStyles.fontFamily = 'rooney-sans'

  const mainWrapperStyle = {
    "--footer-background": layoutStyles.footerDecorColor,
    ...siteSpecificStyles
  };

  return (
    <div className="main-wrapper" style={mainWrapperStyle}>
      <Header />
      {children}
      <Footer background={layoutStyles.footerDecorColor} />
    </div>
  )
}

export default Layout