import React from 'react'
import styles from './TitleSubtitleCard.module.scss'

import Stripes from '../../assets/images/SmallStripes.png'

function TitleSubtitleCard(props) {
    return (
        <div className={styles.cardWrapper} style={props.background ? { backgroundColor: props.background } : null}>
            <div className={`${styles.card} container`}>
                {
                    props.stripes ?
                        <div className={styles.contentWrapper}>
                            <div className={`${styles.stripe} ${styles.top}`}>
                                <img src={Stripes} alt="stripes" />
                            </div>
                            <h1>{props.title}</h1>
                            <p>{props.description}</p>
                            <div className={`${styles.stripe} ${styles.bottom}`}>
                                <img src={Stripes} alt="stripes" />
                            </div>
                        </div>
                        :
                        <div className={styles.contentWrapper}>
                            <h1>{props.title}</h1>
                            <p>{props.description}</p>
                        </div>
                }
            </div>
        </div>
    )
}

export default TitleSubtitleCard
