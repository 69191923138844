import React from 'react'
import useDashboard from "./hooks/useDashboard"
import styles from './styles/Dashboard.module.scss';
function Dashboard() {
	const  sections  = useDashboard()

	return (
		<div className={styles.Dashboard}>
			{sections}
		</div>
	)
}

export default Dashboard
