import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Logo.module.scss'
import { useSelector } from 'react-redux'

function Logo(props) {

  const site = useSelector((state) => state.site)

  const logoData = props.data

  if (!logoData) {
    return null;
  }

  const { logourl, link } = logoData;
  return (
    <Link to="/" className={styles.link}>
      <img className={styles.logo} src={site.siteLogo} alt="" />
    </Link>
  )
}

export default Logo