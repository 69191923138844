import styles from './FieldError.module.scss'

function FieldError({error, touched}) {

  if(!error || !touched){
    return null;
  }
  
  return (<div className={styles.fieldError}>
      <p>{error}</p>
  </div>);
}

export default FieldError
