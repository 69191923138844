import React from 'react';
import { useTermsAndConditionsPageFieldsQuery } from './api/termsAndConditionsApi';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { showLoader, hideLoader } from '../GlobalLoader/globalLoaderSlice';
import styles from './styles/TermsAndConditionsPage.module.scss'
import AnimatedBanner from '../../components/AnimatedBanner/AnimatedBanner';
import StaticBanner from '../../components/StaticBanner/StaticBanner';

function PrivacyPolicyPage() {
    const { data, error, isError, isLoading } = useTermsAndConditionsPageFieldsQuery();
    const dispatch = useDispatch();
    const loaderId = { id: 'privacy_policy' };

    useEffect(() => {
        if (isLoading) {
            dispatch(showLoader(loaderId));
        } else {
            dispatch(hideLoader(loaderId));
        }
    }, [isLoading, dispatch]);

    return (
        <div className={styles.termsAndConditionPage}>
            {
                data?.banner_type === 'animated_banner' ?
                    <AnimatedBanner
                        title={data?.title}
                        subtitle={data?.subtitle}
                        height='40vh'
                        scrollTo={false}
                    />
                    :
                    <StaticBanner
                        page={'privacyAndTerms'}
                        title={data?.title}
                        background={data?.backgroundImage}
                    />
            }
            <div className="container">
                <div className={styles.TermsAndConditionsWrapper}>
                    <div dangerouslySetInnerHTML={{ __html: data?.content }} />
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;
