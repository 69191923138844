import React, { useContext } from 'react'
import { EmployerContext } from '../../templates/GenericEmployer/GenericEmployer'
import useSelectTextColor from '../../../../common/hooks/useSelectTextColor'
import { useSelector } from 'react-redux'

function GenericEmployerBanner({ data, primaryColor }) {

    const employer = useContext(EmployerContext)
    const colorSelector = useSelectTextColor()
    return (
        <section className="company-banner banner">
            <div className="container">
                <div className="banner-sub row">
                    <div className={data.image ? "col-md-6" : "col-md-12"}>
                        <div className="banner-left">
                            {(data.company_logo || employer.logo) && (
                                <div className="logo">
                                    <img src={data.company_logo ? data.company_logo : employer.logo.file} alt="" />
                                </div>
                            )}
                            <h1 style={{ color: colorSelector(primaryColor) }}>{data.title}</h1>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="banner-right">
                            <img src={data.image} alt="" />
                        </div>
                    </div>
                </div>  
            </div>
        </section>
    )
}

export default GenericEmployerBanner
