import styles from './JobPerk.module.scss'
import Button from '../Button/Button'

function JobPerk({ text, children, title, redirectTo }) {

    if (!text) {
        return null
    }
    let className = '';
    if (redirectTo) {
        className = styles['ProfileWrrapper'];
    }
    return (
        <div className={`${styles.perk} ${className}`} >

            {
                (redirectTo) ?
                    <Button className={styles.jobBtn + ' ' + styles.jobProfileBtn} to={redirectTo}>{children}</Button>
                    :
                    <div className={`${styles.perkTextContent}`}>
                        {children}
                        <div>
                            <h4>{title}</h4>
                            <span>{text}</span>
                        </div>
                    </div>
            }
        </div >
    )
}

export default JobPerk
