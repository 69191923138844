
import React, { useState } from 'react'
import styles from './JobsList.module.scss';
import Button from '../Button/Button'
import JobCard from '../JobCard/JobCard';
import JobListSkeleton from './JobListSkeleton';


const JobsList = (props) => {
    const [showAll, setShowAll] = useState(false);
    const [numToShow, setNumToShow] = useState(4);

    const toggleShowAll = () => {
        setShowAll(!showAll);
        setNumToShow(props.jobs.length);
    }

    if (props.isLoading || props.isFetching) {
        return <JobListSkeleton />;
    }

    return (
        <div className={`${styles.lifeBtm} ${props.className}`}>
            {
                props.jobs?.results.length !== 0 ?
                    <ul className={styles.List}>
                        {
                            props.jobs?.results?.map((job, index) => (
                                <li key={index} style={{ display: props.passiveLoadMore === true ? (index < numToShow || showAll ? 'block' : 'none') : '' }}>
                                    {
                                        props.isLoading || props.isFetching === true ?
                                            <JobListSkeleton />
                                            : <JobCard job={job} />
                                    }
                                </li>
                            ))
                        }

                        {props.passiveLoadMore === true && !showAll && (
                            <li className={styles.loadMoreBtnItem}><Button onClick={toggleShowAll} className={styles.loadMoreBtn}>See more jobs</Button></li>
                        )}
                    </ul>
                    : <div className={styles.noJobs}><h3>No jobs found.</h3></div>
            }
        </div >
    )
}

export default JobsList
