import React, {useState} from 'react'

import companyLogo from '../../../../assets/images/post-office-logo.png'
import companyBanner from '../../../../assets/images/post-office-banner.png'
import cardImage1 from '../../../../assets/images/company-profile-card-image1.png'
import cardImage2 from '../../../../assets/images/company-profile-card-image2.png'
import cardImage3 from '../../../../assets/images/company-profile-card-image3.png'
import teamImage from '../../../../assets/images/full-width-inner-image.png'
import promoteImage from '../../../../assets/images/promote-inclusion-image.png'
import teamImage1 from '../../../../assets/images/team-image1.png'
import teamImage2 from '../../../../assets/images/team-image2.png'

import styles from './PostOffice.module.scss'

function PostOffice() {
    const [tab,setTab] = useState('0')
    return (
      <div className={styles.companyProfile}  role='tablist'>
          <div className={styles.banner}>
            <div className={styles.bannerBackground}>
              {/* <img src={companyBanner} alt='banner background' className='img-fluid'></img> */}
              <div className={styles.overlay}></div>
              <div className={`container ${styles.bannerContent}`}>
                <img src={companyLogo} className={`img-fluid ${styles.companyLogo}`} alt='logo'></img>
                <h1>Whatever you need us for, We're here for you</h1>
              </div>
            </div>
            
          </div>
          <div className={styles.aboutCardBlock}>
            <div className='container'>
              <div className='row'>
                <div className={`col-lg-4 col-md-6 ${styles.column}`}>
                  <div className={`card ${styles.card}`}>
                    <img src={cardImage1} alt='card-image1'></img>
                    <div className={`card-body ${styles.cardBody}`}>
                      <h3 className={`card-title ${styles.cardTitle}`}>Everybody’s business</h3>
                      <p className={`card-text ${styles.cardText}`}>With over 11,500 branches, Post Office has the biggest retail network in the UK. 99.7% of the population live within three miles of a Post Office and 4,000 branches are open seven days a week.</p>
                      <button className={styles.readMore}>Read More</button>
                    </div>
                  </div>
                </div>
                <div className={`col-lg-4 col-md-6 ${styles.column}`}>
                  <div className={`card ${styles.card}`}>
                    <img src={cardImage2} alt='card-image2'></img>
                    <div className={`card-body ${styles.cardBody}`}>
                      <h3 className={`card-title ${styles.cardTitle}`}>Everybody’s business</h3>
                      <p className={`card-text ${styles.cardText}`}>Our Everyday Banking services in partnership with over 30 banks, building societies and credit unions means that 99% of customers can access their High Street bank account</p>
                      <button className={styles.readMore}>Read More</button>
                    </div>
                  </div>
                </div>
                <div className={`col-lg-4 col-md-6 ${styles.column}`}>
                  <div className={`card ${styles.card}`}>
                    <img src={cardImage3} alt='card-image3'></img>
                    <div className={`card-body ${styles.cardBody}`}>
                      <h3 className={`card-title ${styles.cardTitle}`}>Our Diversity & Inclusion statement</h3>
                      <p className={`card-text ${styles.cardText}`}>Post Office is committed to embedding equality, diversity and inclusion in all its practices, and aims to establish and nourish an inclusive culture that </p>
                      <button className={styles.readMore}>Read More</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.networkBlock}>
            <div className='container'>
              <h2>EDI Network Groups</h2>
              <ul className={`nav nav-tabs ${styles.tabWrapper}`}role="tablist">
                <li className='nav-item'>
                  <button className={`nav-link ${tab === '0' ? styles.active : ''}`} id='home-tab' data-toggle='tab' role='tab' aria-controls='home' aria-selected='true' onClick={() => setTab("0")}>PRISM</button>
                </li>
                <li className='nav-item'>
                  <button className={`nav-link ${tab === '1' ? styles.active : ''}`} id='poem-tab' data-toggle='tab' role='tab' aria-controls='poem' aria-selected='false' onClick={() => setTab("1")}>POEM</button>
                </li>
              </ul>
              <div className={`tab-content ${styles.tabContent}`} id="myTabContent">
                <div className={`tab-pane row fade  ${tab === '0' ? 'show active' : ''}`} id="home" role="tabpanel" aria-labelledby="home-tab">
                  <p>Prism is the Post Office network for colleagues in the LGBT+ community, and allies who want to show their support and be involved with the network. </p>
                  <p>Celebrate and raise awareness of the LBTQ community by creating events such as Pride History Month.</p>
                  <p style={{margin: '0'}}> Recent Prism highlights</p>
                  <ul className={styles.highlights}>
                    <li>Marched in London and Bolton Pride parades in 2018. Marched in London and Manchester Pride parades in 2019.</li>
                    <li>Headline sponsor of Chesterfield Pride in 2018, and heavily involved in 2019.</li>
                    <li>Ran a fundraising quiz, raising £1,500 for Albert Kennedy Trust in January 2019.</li>
                    <li>Raised over £1,500 through a series of Pride-related events in July 2020.</li>
                  </ul>
                  <div className={styles.features}>
                    <h4>Affinity</h4>
                    <p>Affinity is a network which aims to promote the development of women at Post Office. Events, networking, access to resources, mentoring and other initiatives are in place to support women overcome barriers to progression. Recruitment partners creating a diverse shortlists to make sure we are complying with the affinity network. Women are responsible for 70% of caring responsibilities at home. This network supports by encouraging open flexible working practices Return to work – support for women returning from maternity leave and those for long term leave.</p>
                    <button className={styles.readMore}>Read More</button>
                  </div>
                  <div className={styles.features}>
                    <h4>Be You</h4>
                    <p>Be You is the name of our disability, mental health and wellbeing network at Post Office. </p>
                    <p>Be You work with Purple Space, a networking and professional development hub for disabled employees, who help us ensure</p>
                    <button className={styles.readMore}>Read More</button>
                  </div>
                </div>
                <div className={`tab-pane row fade ${styles.tabPane} ${tab === '1' ? 'show active' : ''}`} id="poem" role="tabpanel" aria-labelledby="poem-tab">Prism is the Post Office network for colleagues in the LGBT+ community, and allies who want to show their support and be involved with the network. Celebrate and raise awareness of the LBTQ community by creating events such as Pride History Month. Recent Prism highlights</div>
              </div>
            </div>
          </div>
          <div className={styles.fullWidthWrapper}>
            <img src={teamImage} alt='full width team' className='img-fluid'></img>
            <div className={styles.fullwidthOverlay}></div>
          </div>
          <div className={styles.promoteWrapper}>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6'>
                  <h2>How we promote diversity & inclusion at the Post Office</h2>
                  <ul>
                    <li>Inclusion Playbook- Supporting managers in everyday actions that they can take to build and maintain inclusive teams</li>
                    <li>An ED&I Newsletter that is sent out to our Diversity Champions and all employees every 2 months</li>
                    <li>A conversation with a colleague from an underrepresented community about their lived experiences every 2 months- ‘Let’s Talk About…’. We have covered a lot of topics including Women’s Health, being black, neurodiversity in the workplace, LGBTQ+ experiences, wearing a burka and many others</li>
                    <li>A yearly ED&I survey for all colleagues. From this survey we can sell where we have improved over the past 12 months and also where we may not have done so well.</li>
                  </ul>
                  <button className={styles.readMore}>Read More</button>
  
                </div>
                <div className='col-md-6'>
                  <img src={promoteImage} className='img-fluid'  alt='promote'></img>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.fullWidthWrapper} ${styles.divideWidthImageWrapper}`}>
            <img src={teamImage1} alt='achievement1' className='img-fluid'></img>
            <img src={teamImage2} alt='achievement2' className='img-fluid'></img>
            <div className={styles.fullwidthOverlay}></div>
          </div>
          <div className={styles.benefitSummary}>
            <div className='container'>
              <h2>Benefits Summary</h2>
              <p>There are a range of employee benefits at The Post Office, please see the summary below of what The Post Office can offer</p>
              <div className={styles.benefitbuttons}>
                <button>STIP (Bonus) – Depending on Grade</button>
                <button>PO Pension Plan</button>
                <button>Employee Assistance Program</button>
                <button>Purchase 5 additional annual leave days</button>
                <button>Medical Cover: Benenden Health</button>
                <button>Season Ticket Loan</button>
                <button>Cycle to work scheme</button>
                <button>Life Assurance</button>
                <button>Retail Discount Platform</button>
                <button>Annual Leave 25 Days</button>
              </div>
            </div>
          </div>
          <div className={styles.jobContainer}>
            <div className='container'>
              <table>
                <tbody>
                  <tr className={styles.job}>
                    <td style={{width: '20%'}}><a href='#' className={styles.companyLogo}><img src={companyLogo} className='img-fluid' alt='company-logo'></img></a></td>
                    <td style={{width:'30%'}}><h2>Head of Agent Knowledge</h2></td>
                    <td style={{width:'25%'}}><div className={styles.salary}><span>£ 50,550 - £ 54,500</span></div></td>
                    <td style={{width:'25%'}}><div className={styles.location}><span>Nottigham</span></div></td>
                    <td><a href='#' className={styles.applyNow}>ApplyNow</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>
    )
}

export default PostOffice
