import { Formik } from 'formik'
import { useEffect, useState } from 'react'

import useJobAlertUpdateForm from '../Hooks/useJobAlertUpdateForm'
import { LgbtToast } from '../../../components/LgbtToast/LgbtToast'
import BasicJobFields from '../../JobPost/components/BasicJobFields/BasicJobFields'
import JobLocationFields from '../../JobPost/components/JobLocationFields/JobLocationFields';
import SalaryFields from '../../JobPost/components/SalaryFields/SalaryFields';
import { useJobAlertPageDataQuery } from '../api/jobAlertApiSlice'
import JobTypeFieldsMultiple from './JobTypeFIeldsMultiple/JobTypeFieldsMultiple'
import JobSectorFieldsMultiple from './JobSectorFieldsMultiple/JobSectorFieldsMultiple'
import StaticBanner from '../../../components/StaticBanner/StaticBanner'
import NameField from './NameField/NameField'
import FrequencyField from './FrequencyField/FrequencyField'

import styles from '../Styles/JobAlertPage.module.scss'

function JobAlertUpdateForm() {

    const { jobAlertUpdateForm, isError, Error, AlertResponseMessage } = useJobAlertUpdateForm();
    const { data: pageData, error, isError: pageIsError, isLoading } = useJobAlertPageDataQuery();


    useEffect(() => {
        // Scroll to the first error when the form is submitted
        if (jobAlertUpdateForm.submitCount > 0 && Object.keys(jobAlertUpdateForm.errors).length > 0) {
            const firstErrorField = Object.keys(jobAlertUpdateForm.errors)[0];
            const element = document.getElementsByName(firstErrorField)[0];
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }

        // show toast if there are errors on submission
        if (jobAlertUpdateForm.submitCount > 0 && Object.keys(jobAlertUpdateForm.errors).length > 0) {
            if (jobAlertUpdateForm.errors.recaptcha) {
                LgbtToast({ message: 'Please reload this page to submit another response', type: 'error' })
            } else {
                LgbtToast({ message: 'Please fill in all required fields', type: 'error' })
            }
        }

    }, [jobAlertUpdateForm.submitCount, AlertResponseMessage]);

    return (
        <div className={styles.jobAlert}>
            <StaticBanner
                title={'Update your Job Alert'}
                subtitle={pageData?.sub_title}
                page={'jobAlert'}
                background={pageData?.background_image}
            />
            <div className={styles.formWrapper}>
                <div className={`${styles.container} container`}>
                    <Formik>
                        <form action="" className={`${styles.form} jobPostForm`} onSubmit={jobAlertUpdateForm.handleSubmit}>
                            <span className="req">*Required fields</span>
                            {
                                AlertResponseMessage !== '' ?
                                    <div className={`${styles.AlertMessage} ${isError ? styles.red : styles.green}`}>
                                        <h3>{AlertResponseMessage}</h3>
                                    </div>
                                    : ''
                            }

                            <NameField
                                form={jobAlertUpdateForm}
                            />

                            <BasicJobFields
                                form={jobAlertUpdateForm}
                                fields={["title"]}
                                whichForm={'jobAlertForm'}
                                placeHolder={`Please add job titles you are interested in eg sales executive, sales manager.`}
                            />

                            <JobLocationFields form={jobAlertUpdateForm} type="alert" whichForm={'jobAlertForm'} locationPlaceholder={`Please state your desired job location e.g. Manchester or postcode`} />

                            <JobSectorFieldsMultiple form={jobAlertUpdateForm} />

                            <SalaryFields form={jobAlertUpdateForm} whichForm={'jobAlertForm'} fields={["minimumSalary", "maximumSalary"]} />

                            <JobTypeFieldsMultiple form={jobAlertUpdateForm} />

                            <FrequencyField form={jobAlertUpdateForm} />

                            <div className={`${styles.submitBtn} submitBtn container row`}>
                                <div className="col-md-9" dangerouslySetInnerHTML={{ __html: pageData?.bottom_text }} />
                                <div className={`${styles.buttonWrapper} col-md-3`}>
                                    <button type="submit">Update Job alert</button>
                                </div>
                            </div>
                        </form>
                    </Formik>
                </div>
            </div>

        </div>
    )
}

export default JobAlertUpdateForm