import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AlertAccordion from '../AlertAccordion/AlertAccordion';
import { useGetMyJobAlertsQuery, useDeleteJobAlertMutation } from '../../../JobAlert/api/jobAlertApiSlice'
import styles from './JobAlert.module.scss';

function JobAlertListing() {
	const auth = useSelector(state => state.auth)
	const jobSeeker = auth.user.username;
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	const { data, error, isLoading, isFetching, refetch } = useGetMyJobAlertsQuery({ jobSeeker });
	const [deleteJob, { isLoading: isJobDeleting, isError: isJobDeleteError, isSuccess: isJobDeleteSuccess }] = useDeleteJobAlertMutation();

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	let args = {
		jobSeeker,
	}


	if (!data || data.length <= 0) {
		return (
			<h3 style={{ textAlign: 'center' }}>No Job Alerts Yet!</h3>
		);
	}
	let handleDelete = async (id, e) => {
		if (window.confirm('Are you sure you want to delete this job?')) {
			await deleteJob(id);
		}
	}

	return (
		<div className={styles.AlertWrap}>
			<div className={`${styles.tables} tables`}>
				<div className={`row ${styles.Alertheading}`}>
					<div className='col-3'><p>Job title</p></div>
					<div className='col-3'><p>Job Location</p></div>
					<div className='col-3'><p>Sector</p></div>
					<div className='col-3'><p>Alert Frequency</p></div>
				</div>
				{data.map((jobAlert, index) => (
					!isMobile ? (
						<div key={index} className={`row ${styles.Listing}`}>
							<div className='col-3'><p>{jobAlert.job_title_keyword}</p></div>
							<div className='col-3'><p>{jobAlert.location}</p></div>
							<div className='col-3'><p>{jobAlert.sector.length > 0 ? jobAlert.sector.reduce((prevSec, currSec) => (prevSec + ' | ' + currSec)) : ''}</p></div>
							<div className='col-3'><p>{jobAlert.alert_frequency}</p></div>
							<div className={`col-12 ${styles.hoverList}`}>
								<div>{jobAlert.job_title_keyword}</div>
								<div className={styles.links}>
									<Link className={styles.edit} to={`/job-alert/${jobAlert?.id}/edit`}>Update</Link>
									<button className={styles.delete} onClick={(e) => { handleDelete(jobAlert.id, e) }}>Delete</button>
								</div>
							</div>
						</div>
					) : (
						<AlertAccordion key={jobAlert.index} jobAlert={jobAlert} handleDelete={handleDelete} />
					)
				))}
			</div>
		</div>
	);
}

export default JobAlertListing;
