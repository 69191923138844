import React, { useState, useRef, useEffect } from 'react';

function Content2Column({ data }) {
    const [showFullDescriptionLeft, setShowFullDescriptionLeft] = useState(false);
    const [showFullDescriptionRight, setShowFullDescriptionRight] = useState(false);
    const [contentLinesLeft, setContentLinesLeft] = useState(15);
    const [contentLinesRight, setContentLinesRight] = useState(15);
    const leftColumnRef = useRef(null);
    const rightColumnRef = useRef(null);
    const elementRefLeft = useRef(null);
    const [wrapperHeightLeft, setOuterDivHeightLeft] = useState(0);
    const contentRefLeft = useRef(null);
    const [contentHeightLeft, setInnerContentheightLeft] = useState(0);
    const elementRefRight = useRef(null);
    const [wrapperHeightRight, setOuterDivHeightRight] = useState(0);
    const contentRefRight = useRef(null);
    const [contentHeightRight, setInnerContentheightRight] = useState(0);
    const [imageRight, setImageRight] = useState(false);
    const [imageLeft, setImageLeft] = useState(false);

    useEffect(() => {
        const calculateOverflowLeft = (ref, setContentLines) => {
            if (ref.current) {
                const lineHeight = parseInt(window.getComputedStyle(ref.current).lineHeight);
                const maxHeight = lineHeight * contentLinesLeft;
                if (ref.current.scrollHeight > maxHeight) {
                    setContentLines(contentLinesLeft + 1);
                }
            }
        };
        const calculateOverflowRight = (ref, setContentLines) => {
            if (ref.current) {
                const lineHeight = parseInt(window.getComputedStyle(ref.current).lineHeight);
                const maxHeight = lineHeight * contentLinesRight;
                if (ref.current.scrollHeight > maxHeight) {
                    setContentLines(contentLinesRight + 1);
                }
            }
        };

        calculateOverflowLeft(leftColumnRef, setContentLinesLeft);
        calculateOverflowRight(rightColumnRef, setContentLinesRight);
    }, [data.column_1_content, data.column_2_content, contentLinesLeft, contentLinesRight]);

    useEffect(() => {
        const contentDivLeft = elementRefLeft.current;
        if (contentDivLeft) {
            const imgTagLeft = contentDivLeft.querySelector('img');
            if (imgTagLeft) {
                setImageLeft(true)
            }
        }

        const contentDivRight = elementRefRight.current;
        if (contentDivRight) {
            const imgTagRight = contentDivRight.querySelector('img');
            if (imgTagRight) {
                setImageRight(true)
            }
        }

        if (elementRefLeft.current) {
            setOuterDivHeightLeft(elementRefLeft.current.clientHeight);
        }
        if (contentRefLeft.current) {
            setInnerContentheightLeft(contentRefLeft.current.scrollHeight);
        }
        if (elementRefRight.current) {
            setOuterDivHeightRight(elementRefRight.current.clientHeight);
        }
        if (contentRefRight.current) {
            setInnerContentheightRight(contentRefRight.current.scrollHeight);
        }
    }, []);

    const toggleDescriptionLeft = () => {
        setShowFullDescriptionLeft(!showFullDescriptionLeft)
    }

    const toggleDescriptionRight = () => {
        setShowFullDescriptionRight(!showFullDescriptionRight)
    }

    return (
        <section className="who-heading">
            <div className="container">
                <div className="who-sub row">
                    {data.column_1_title || data.column_1_content ? (
                        <div className={data.column_2_title || data.column_2_content ? 'col-md-6' : 'col-md-12'}>
                            <div className="who-left" ref={leftColumnRef}>
                                <h2 dangerouslySetInnerHTML={{ __html: data?.column_1_title }}></h2>

                                <div ref={elementRefLeft}
                                    style={{
                                        maxHeight: (showFullDescriptionLeft || imageLeft) ? 'unset' : `${contentLinesLeft * 1.22}em`,
                                        overflow: 'hidden',
                                        transition: 'max-height 0.5s ease-out',
                                    }}
                                >
                                    <div ref={contentRefLeft}
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: showFullDescriptionLeft ? 'unset' : contentLinesLeft,
                                            WebkitBoxOrient: 'vertical',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: data?.column_1_content }}
                                    ></div>
                                </div>
                                {wrapperHeightLeft < contentHeightLeft ? (
                                    <div>
                                        {data.column_1_content.length > 0 && showFullDescriptionLeft && !imageLeft && (
                                            <button onClick={toggleDescriptionLeft} className="bttn">
                                                Read Less
                                            </button>
                                        )}
                                        {data.column_1_content.length > 0 && !showFullDescriptionLeft && !imageLeft && (
                                            <button onClick={toggleDescriptionLeft} className="bttn">
                                                Read More
                                            </button>
                                        )}
                                    </div>
                                ) : ''}


                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {data.column_2_title || data.column_2_content ? (
                        <div className={data.column_1_title || data.column_1_content ? 'col-md-6' : 'col-md-12'}>
                            <div className="who-right" ref={rightColumnRef}>
                                <h2 dangerouslySetInnerHTML={{ __html: data?.column_2_title }}></h2>
                                <div ref={elementRefRight}
                                    style={{
                                        maxHeight: (showFullDescriptionRight || imageRight) ? 'unset' : `${contentLinesRight * 1.2}em`,
                                        overflow: 'hidden',
                                        transition: 'max-height 0.5s ease-out',
                                    }}
                                >
                                    <div ref={contentRefRight}
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: showFullDescriptionRight ? 'unset' : contentLinesRight,
                                            WebkitBoxOrient: 'vertical',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: data?.column_2_content }}
                                    ></div>
                                </div>
                                {wrapperHeightRight < contentHeightRight ? (
                                    <div>
                                        {data.column_2_content.length > 0 && showFullDescriptionRight && !imageRight && (
                                            <button onClick={toggleDescriptionRight} className="bttn">
                                                Read Less
                                            </button>
                                        )}
                                        {data.column_2_content.length > 0 && !showFullDescriptionRight && !imageRight && (
                                            <button onClick={toggleDescriptionRight} className="bttn">
                                                Read More
                                            </button>
                                        )}
                                    </div>
                                ) : ''}
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </section>
    )
}

export default Content2Column
