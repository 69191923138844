import {api} from '../../../utils/api'

const EmployerSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        getEmployer: builder.query({
            query: (employerSlug) => `/jobmanager/employer/${employerSlug}`,
        }),
        getEmployerJobs: builder.query({
            query: ({employerId, page_size, page}) => `/jobmanager/employer/${employerId}/jobs?page_size=${page_size}&page=${page}`,
        }),
    }),
})
export const { useGetEmployerQuery, useGetEmployerJobsQuery } = EmployerSlice