import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

function RequireAuth({ allowedRoles, children }) {

    const auth = useSelector((state) => state.auth)
    const location = useLocation();

    if (!allowedRoles) {
        return <Navigate to="/unauthorized" state={{ path: location.pathname }} />
    }

    if (!auth.isAuthenticated || auth.accessToken === null || auth.accessToken === undefined) {
        return <Navigate to="/login" state={{ path: location.pathname }} replace />
    }

    if (auth === undefined || auth.user === null || Object.keys(auth.user).length == 0) {
        return <div>Loading...</div>;
    }

    let flag = 1;
    auth.user.role.forEach((role) => {
        if (allowedRoles.includes(role)) {
            flag = 0;
            return;
        }
    });

    if (flag)
        return <Navigate to="/unauthorized" state={{ path: location.pathname }} />


    return (
        <>{children}</>
    )
}

export default RequireAuth
