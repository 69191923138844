import React, { useRef, useState, useEffect } from 'react'
import { useUploadDocumentsMutation } from '../api/jobCreateApiSlice'

export const validMimeTypes = {
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
}

function useDocumentField(form) {

    const isMounted = useRef(true);  // useRef to track mounting
    const { setFieldValue, values } = form;
    const [error, setError] = useState('');

    const [loading, setLoading] = useState(false)

    const [
        uploadDocument,
        {
            isFetching: isUploadFetching,
            isLoading: isUploadLoading,
            isError: isUploadError,
            error: uploadError
        }
    ] = useUploadDocumentsMutation();

    const handleFileSelection = async (selectedFiles) => {
        const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
        const validFiles = selectedFiles.filter(file =>
            Object.keys(validMimeTypes).includes(file.type) && file.size <= maxFileSize
        );
        setLoading(true)

        if (validFiles.length !== selectedFiles.length) {
            if (isMounted.current) {
                setError('Some files were invalid or exceeded the size limit of 2MB and were not added. Please upload only valid PDF or Word documents under 2MB.')
            }
        } else {
            if (isMounted.current) {
                setError('');
            }
        }

        if (isMounted.current) {
            const response = await uploadDocument({ files: validFiles })
            if (response.data?.length) {
                form.setFieldValue('attached_file', [...form.values.attached_file, ...response.data])
            }
            else {
                if (isMounted.current) {
                    setError('Some files were invalid and not added. Please upload only valid PDF or Word documents.');
                }
            }
        }
        setLoading(false)
    };
    const handleFileRemove = (index) => {
        if (isMounted.current) {
            const newFile = [...form.values.attached_file]
            form.setFieldValue('attached_file', newFile.filter((_, i) => i !== index))
        }
    };

    const handleEditSubmit = (index, updatedFile) => {
        const newFiles = [...form.values.attached_file]
        newFiles[index] = updatedFile
        form.setFieldValue('attached_file', newFiles)
    };

    useEffect(() => {
        isMounted.current = true; // Component mounted
        return () => {
            isMounted.current = false; // Component unmounted
        };
    }, []);

    return {
        isMounted,
        uploadedFiles: form.values.attached_file,
        handleFileSelection,
        handleFileRemove,
        handleEditSubmit,
        error,
        setLoading,
        loading,
    }
}

export default useDocumentField
