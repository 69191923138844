import { useGetSiteSettingsQuery } from '../api/siteApiSlice'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { initSiteSettings } from '../SiteSlice'
import { useEffect } from 'react';

function useSiteInit() {

  const searchParams = window.location.search;
  const urlParams = new URLSearchParams(searchParams)
  const site = urlParams.get('site');
  const siteConfig = useSelector(state => state.site)

  const { data: siteSettings, isloading, isFetching, error } = useGetSiteSettingsQuery(site)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isloading && !isFetching && siteSettings)
      dispatch(initSiteSettings(siteSettings))
  }, [isloading, isFetching, siteSettings])

  useEffect(() => {
    if (siteConfig.domain.includes('neurodiversityjobs.co.uk') === true) {
      const scriptString = `
    _linkedin_partner_id = "6910833";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);

    (function(l) {
      if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
      window.lintrk.q=[]}
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);
    })(window.lintrk);

    (function() {
      var noscript = document.createElement('noscript');
      var img = document.createElement('img');
      img.height = '1';
      img.width = '1';
      img.style.display = 'none';
      img.alt = '';
      img.src = 'https://px.ads.linkedin.com/collect/?pid=6910833&fmt=gif';
      noscript.appendChild(img);
      document.body.appendChild(noscript);
    })();
  `;

      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.text = scriptString;
      document.body.appendChild(scriptElement);
      return () => {
        // Cleanup if necessary
        document.body.removeChild(scriptElement);
      };
    }
  }, [siteConfig.domain]);

  return () => {
  }
}

export default useSiteInit


