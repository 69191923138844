import React from 'react'

import PeopleImage from '../../../../assets/images/people.jpeg'

function FullWidthImage({ data }) {
  return (
    <div className="container">
      {data?.image ? (<section className="full-img ">
        <img src={data?.image} alt="" className="img-fluid" />
      </section>) : null}
    </div>
  )
}

export default FullWidthImage
