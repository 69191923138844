import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './LgbtSelectBox.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCheck } from '@fortawesome/free-solid-svg-icons';

function LgbtSelectBox(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const selectRef = useRef(null);

  // Close select box when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  // Close other select boxes when one is opened
  useEffect(() => {
    function handleCloseOthers() {
      setIsOpen(false);
    }

    if (isOpen) {
      const closeOthers = () => {
        const selectBoxes = document.querySelectorAll('.customDropdown');
        selectBoxes.forEach(box => {
          if (box !== selectRef.current) {
            box.dispatchEvent(new Event('close'));
          }
        });
      };
      document.addEventListener('open', closeOthers);
    }

    return () => {
      document.removeEventListener('open', handleCloseOthers);
    };
  }, [isOpen]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.dispatchEvent(new Event('open'));
    }
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
    props.selectHandler(value);
  };

  function formatNumberWithComma(number) {
    const numStr = String(number);
    const lastThree = numStr.substring(numStr.length - 3);
    const otherNumbers = numStr.substring(0, numStr.length - 3);
    if (otherNumbers !== '') {
      return String.fromCharCode(163) + ' ' + otherNumbers + ',' + lastThree;
    } else {
      return String.fromCharCode(163) + ' ' + lastThree;
    }
  }

  return (
    <div ref={selectRef} className={styles.customDropdown}>
      <button onClick={handleToggle} >
        {props.title || 'Select an Option'}
        <div className={styles.arrowIcon}>{isOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</div>
      </button>
      {isOpen && (
        <ul>
          {props.options.map((option, index) => (
            <li key={index} onClick={() => handleSelect(option)} className={option === selectedValue ? styles.activeItem : ''}>
              {option === selectedValue && <FontAwesomeIcon icon={faCheck} className={styles.checkIcon} />}
              {typeof option === 'string' ?
                option.charAt(0).toUpperCase() + option.slice(1)
                :
                option === 0 ?
                  String.fromCharCode(163) + ' Any'
                  : option === 10000 ?
                    'Up to ' + formatNumberWithComma(option)
                    : option === 100000 ?
                      formatNumberWithComma(option) + '+'
                      : formatNumberWithComma(option)
              }
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

LgbtSelectBox.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  selectHandler: PropTypes.func.isRequired,
  fieldName: PropTypes.string,
  form: PropTypes.object
};

export default LgbtSelectBox;
