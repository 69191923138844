import React from 'react'
import FieldError from '../FieldError/FieldError';
import { HiOutlineExternalLink } from "react-icons/hi";
import styles from './JobApplyFields.module.scss'
function JobApplyFields({ form }) {

    const handleTypeChange = (e) => {
        form.setFieldValue('apply_link', '')
        form.setFieldValue('applyLinkType', e.target.value)
    }

    return (
        <section className="jobTypeFieldsWrap fieldGroupWrap">
            <div className="row">
                <div className="fieldHead col-md-3">
                    <h2>Apply to*</h2>
                </div>
                <div className="jobFields col-md-9">
                    <div className="row">
                        <div className="col-12">
                            <div className="fieldWrap">
                                <div className="labelBox">
                                    <p>Select the preferred application method for jobseekers: email or URL.</p>
                                </div>
                                <div className={styles.toggleOuter}>
                                    <div className={styles.toggleWrap}>
                                        <div className={styles.toggle}>
                                            <input type="radio" name="applyType" id="urlInput" onChange={handleTypeChange} value="url" checked={Boolean(form.values.applyLinkType == "url")} />
                                            <label htmlFor="urlInput">URL</label>
                                            <input type="radio" name="applyType" id="emailInput" onChange={handleTypeChange} value="email" checked={Boolean(form.values.applyLinkType == "email")} />
                                            <label htmlFor="emailInput">Email</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.inputWrap}>
                                    <input
                                        id="apply-link"
                                        type="text"
                                        name="apply_link"
                                        placeholder={`Enter ${form.values.applyLinkType == 'url' ? 'URL' : 'email address'}`}
                                        value={form.values.apply_link}
                                        onChange={form.handleChange}
                                    />
                                    {form.values.applyLinkType == 'url' && !form.errors.apply_link ? <a className={styles.testUrl} href={form.values.apply_link} target='_blank'>Test URL <HiOutlineExternalLink /></a> : null}
                                    <FieldError error={form.errors.apply_link} touched={form.touched.apply_link} />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default JobApplyFields
