import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHomepageFieldsQuery } from "../api/homeApi";
import useSearchForm from "../../JobSearchBox/hooks/useSearchForm";

import AnimatedBanner from '../../../components/AnimatedBanner/AnimatedBanner'
import StaticBanner from '../../../components/StaticBanner/StaticBanner'
import StaticBannerNeurodiversity from "../../../components/StaticBannerNeurodiversity/StaticBannerNeurodiversity";
import SliderBanner from "../../../components/SliderBanner/SliderBanner";
import BlackHistorySection from '../../../components/BlackHistorySection/BlackHistorySection'
import CurvedPromoSection from '../../../components/CurvedPromoSection/CurvedPromoSection'
import CompaniesTeaser from '../../../components/CompaniesTeaser/CompaniesTeaser'
import CompaniesTeaserBlockView from "../../../components/CompaniesTeaserBlockView/CompaniesTeaserBlockView";
import PartnersCarousel from '../../../components/PartnersCarousel/PartnersCarousel'
import TrendingJobs from '../../../components/TrendingJobs/TrendingJobs'
import TrendingJobsSecondaryColor from '../../../components/TrendingJobsSecondaryColor/TrendingJobsSecondaryColor'
import CompanySearchSection from '../../../components/CompanySearchSection/CompanySearchSection'
import PopularJobs from '../../../components/PopularJobs/PopularJobs'
import PopularJobsSecondaryColor from "../../../components/PopularJobsSecondaryColor/PopularJobsSecondaryColor";

import { LgbtToast } from "../../../components/LgbtToast/LgbtToast";
import { showLoader, hideLoader } from '../../GlobalLoader/globalLoaderSlice';
import JobSearchBox from "../../JobSearchBox";

function useHomePage() {

    const loaderId = { id: 'home' };
    const { searchForm } = useSearchForm();

    const dispatch = useDispatch();
    const { data, error, isError, isLoading } = useHomepageFieldsQuery();

    useEffect(() => {
        if (isError && error) {
            LgbtToast({ message: error.status, type: 'error' })
        }
    }, [dispatch, isError, error]);

    useEffect(() => {
        if (isLoading) {
            dispatch(showLoader(loaderId));
        } else {
            dispatch(hideLoader(loaderId));
        }
    }, [isLoading, dispatch]);

    // loop through each section and render the components based on the type
    let sections = data?.sections.map((section, index) => {
        switch (section.type) {
            case 'static_banner':
                return <StaticBanner key={index} title={section.title} subtitle={section.subtitle} background={section.background} ><JobSearchBox searchForm={searchForm} /></StaticBanner>
            case 'static_banner_neurodiversity':
                return <StaticBannerNeurodiversity key={index} title={section.title} background={section.background} mobileBackground={section.mobileBackground} ><JobSearchBox searchForm={searchForm} /></StaticBannerNeurodiversity>
            case 'hero_banner':
                return <AnimatedBanner key={index} title={section.title} subtitle={section.subtitle} background={section.background} scrollTo={true} ><JobSearchBox searchForm={searchForm} /></AnimatedBanner>
            case 'slider_banner':
                return <SliderBanner key={index} title={section.title} subtitle={section.subtitle} background={section.background} ><JobSearchBox searchForm={searchForm} /></SliderBanner>
            case 'pride_calendar_section':
                return <CurvedPromoSection key={index} title={section.title} flagImage={section.flag_image} card={section.card} />
            case 'black_history_section':
                return <BlackHistorySection key={index} desktop={section.desktop_banner} mobile={section.mobile_banner} url={section.redirection_url} />
            case 'find_companies_section':
                return <CompaniesTeaser key={index} title={section.title} subtitle={section.subtitle} button={section.button} />
            case 'find_companies_section_block_view':
                return <CompaniesTeaserBlockView key={index} title={section.title} subtitle={section.subtitle} button={section.button} />
            case 'company_logo_slider_section':
                return <PartnersCarousel key={index} companies={section.companies} />
            case 'trending_jobs_section':
                return <TrendingJobs key={index} trendingJobs={section.trending_jobs} />
            case 'trending_jobs_section_secondary':
                return <TrendingJobsSecondaryColor key={index} trendingJobs={section.trending_jobs} />
            case 'popular_jobs_section':
                return <PopularJobs key={index} title={section.title} />
            case 'popular_jobs_section_secondary':
                return <PopularJobsSecondaryColor key={index} title={section.title} />
            case 'company_search_section':
                return <CompanySearchSection key={index} />
            default:
                return null
        }
    });

    return {
        sections,
        isLoading,
        isError
    }
}

export default useHomePage
