import {api} from '../../../utils/api'

const singleJobApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getSingleJob: builder.query({
      query: (jobSlug) => `/jobmanager/job/${jobSlug}/`,
    }),
    getSingleJobPreview: builder.query({
      query: ({jobSlug,timestamp}) => {
        return `/jobmanager/job/preview/${jobSlug}/?timestamp=${timestamp}`
      },
    }),
    incrementJobViewCount: builder.mutation({
      query: (values) => {
        return {
          url: `/jobmanager/job/register/${values.jobId}/view`,
          method: 'POST',
          body: values,
        }
      },
    }),
    incrementJobApplyCount: builder.mutation({
      query: (values) => {
        return {
          url: `/jobmanager/job/register/${values.jobId}/apply`,
          method: 'POST',
          body: values,
        }
      },
    })
  }),
});

export const {
  useGetSingleJobQuery,
  useLazyGetSingleJobQuery,
  useGetSingleJobPreviewQuery,
  useIncrementJobViewCountMutation,
  useIncrementJobApplyCountMutation
} = singleJobApiSlice;


