import React, { useState, useEffect, useRef } from 'react';

function Overview({ data }) {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [contentLines, setContentLines] = useState(11);
  const contentRef = useRef(null);
  const innerContentRef = useRef(null);
  const [wrapperHeight, setOuterDivHeight] = useState(0);
  const [contentHeight, setInnerContentheight] = useState(0);
  const [isContentTruncated, setIsContentTruncated] = useState(false);

  useEffect(() => {
    const contentElement = contentRef.current;
    const lineHeight = parseInt(window.getComputedStyle(contentElement).lineHeight);
    const maxHeight = lineHeight * contentLines;

    if (contentElement.scrollHeight > maxHeight) {
      setShowFullDescription(false);
    }
    setIsContentTruncated(contentElement.scrollHeight > maxHeight);
    if (contentElement.scrollHeight > maxHeight) {
      setShowFullDescription(false);
    }
  }, [contentLines]);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  useEffect(() => {
    if (contentRef.current) {
      setOuterDivHeight(contentRef.current.clientHeight);
    }
    if (innerContentRef.current) {
      setInnerContentheight(innerContentRef.current.scrollHeight);
    }
  }, []);
  const titleTextColor = data.hasOwnProperty('title_text_color') ? data.title_text_color : "#000000";
  return (
    <section className="video-sec">
      <div className="container">
        <div className="video-sub row">
          {data?.youtube_iframe && (
            <div className={data.title || data.content ? "col-md-6" : "col-md-12"}>
              <div className="video-left">
                <div className="o-video" dangerouslySetInnerHTML={{ __html: data.youtube_iframe }} />
              </div>
            </div>
          )}
          {(data.title || data.content) && (
            <div className={data?.youtube_iframe ? "col-md-6" : "col-md-12"}>
              <div className="video-right">
                <h2 dangerouslySetInnerHTML={{ __html: data?.title }} style={{ color: titleTextColor }}></h2>
                <div
                  ref={contentRef}
                  className="content"
                  style={{ maxHeight: showFullDescription ? 'unset' : `${contentLines * 1.2}em`, overflow: 'hidden', transition: 'max-height 0.5s ease-out' }}>
                  <div ref={innerContentRef} dangerouslySetInnerHTML={{ __html: data?.content }}></div>
                </div>
                {isContentTruncated && wrapperHeight < contentHeight && (
                  <button onClick={toggleDescription} className="bttn">
                    {showFullDescription ? 'Read Less' : 'Read More'}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Overview
