import { useFormik } from 'formik'
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from 'yup'

function useCompanySearchForm() {

    const navigate = useNavigate();
    const loc = useLocation();
    const queryParams = new URLSearchParams(loc.search);
    const company = queryParams.get('company');
    const searchByLetter = queryParams.get('search-by')
    const page_size = queryParams.get('page_size')
    const page = queryParams.get('page')


    const initialValues = {
        company: company ?? '',
        searchByLetter: searchByLetter ?? '',
        page_size: page_size ?? '',
        page: page ?? 1
    }
    const validationSchema = Yup.object({
        'company': Yup.string(),
    })

    const searchForm = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            navigate(
                `/inclusive-hiring-employers-directory?company=${values.company}${values.searchByLetter ? `&search-by=${values.searchByLetter}` : ''}&page_size=${values.page_size}&page=${values.page}`
            );
        }
    })

    return { searchForm }
}

export default useCompanySearchForm