import React, { useRef, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import JobSearchBox from '../../features/JobSearchBox'
import styles from './AnimatedBanner.module.scss'
import DownArrow from '../../assets/images/down-arrow-shape.png'
import { useSelector } from 'react-redux'

let colors = ['red', 'orange', 'yellow', 'green', 'deepskyblue', 'magenta', 'purple']

const getStripStartPositions = () => {
    // create an empty array to store the parts
    let startPoints = [];
    let vw = window.innerWidth;
    let vh = window.innerHeight;

    let offset = vh / 3 * 1;
    if(vh > vw) {
        offset = vh /2;
    }

    let pointGap = (offset + vw)/6;

    // calculate the size of each part by dividing the value by 6
    // let pointGap = (window.innerWidth + (window.innerWidth / 3)) / 6;
    // let offset = window.innerHeight + (window.innerHeight / 3);
    // loop from 0 to 5 and push the pointGap multiplied by the loop index to the array
    for (let i = 0; i < 6; i++) {
        startPoints.push({
            x: (Math.floor((pointGap * i) - offset)) - vh,
            y: -vh
        });
    }
    // return the array of parts
    return startPoints;
}

// Create a function that returns a strip configuration object
const createStripConfig = (height, width, zIndex, duration) => ({
    styles: {
        height: `${height}%`,
        width: `${width}vh`,
        zIndex,
    },
    duration,
});

// Create 3 different strip configurations
let Strip1 = () => createStripConfig(22, 110, -1, 10000)
let Strip2 = () => createStripConfig(15, 90, -2, 18000)
let Strip3 = () => createStripConfig(8, 70, -3, 30000)
if (window.innerWidth <= 1199) {
    Strip1 = () => createStripConfig(15, 110, -1, 10000)
    Strip2 = () => createStripConfig(8, 90, -2, 18000)
    Strip3 = () => createStripConfig(4, 70, -3, 30000)
}

// A component that renders a strip with a random color and a random angle
const Strip = (props) => {

    let stripConf = {}
    switch (props.id) {
        case 2:
            // call fun1 when value is 1 or 4
            stripConf = Strip1()
            break;
        case 3:
        case 1:
        case 5:
            // call fun2 when value is 2 or 5
            stripConf = Strip3()
            break;
        case 4:
        case 6:
            // call fun3 when value is 3 or 6
            stripConf = Strip2()
            break;
        default:
            // do nothing otherwise
            break;
    }

    // Create a spring object with random values for the position and opacity
    const conf = {
        from: { x: props.start.x, y: props.start.y, opacity: 1 },
        to: { x: props.start.x + (2 * window.innerHeight), y: props.start.y + (2 * window.innerHeight), opacity: 1 },
        loop: true,
        config: { duration: stripConf.duration },
    }

    // If the strip has a delay, set the delay to half the duration
    let initDelay = (props.delay) ? Math.floor(stripConf.duration / 2) : 0
    const [delay, setDelay] = useState(initDelay);
    if (props.delay) {
        conf.delay = delay;
        conf.onRest = () => {
            // Change the reset value to false after the first loop
            if (delay) {
                setDelay(0);
            }
        }
    }

    // Choosing background color and changing the order of colors
    let backgroundColor = colors[props.id - 1]
    if (props.id === 6) {
        backgroundColor = colors[props.id - 1]
        let last = colors.pop();
        colors.unshift(last);
    }

    // Create a spring object with random values for the position and opacity
    const spring = useSpring(conf);

    const genericStyle = {
        transform: `rotate(45deg)`,
        transformOrigin: 'top left',
        borderRadius: '200px',
        position: 'absolute',
    }

    // Return an animated div with the spring object and some inline styles
    return (
        <animated.div
            style={{
                ...spring,
                ...stripConf.styles,
                ...genericStyle,
                backgroundColor,
            }}
        />
    )
}

// take randome position inside banner and should have a random size but too small sizes. should animate in a random direction
const WhiteDot = (props) => {

    const x = (Math.floor(Math.random() * window.innerWidth))
    const y = (Math.floor(Math.random() * (window.innerHeight / 3 * 2)))

    const dotSpring = useSpring({
        from: { x: x, y: y },
        to: [
            { x: x + Math.floor(Math.random() * 20), y: y + Math.floor(Math.random() * 20) },
            { x: x + Math.floor(Math.random() * 20), y: y + Math.floor(Math.random() * 20) },
            { x: x + Math.floor(Math.random() * 20), y: y + Math.floor(Math.random() * 10) },
            { x: x, y: y },
        ],
        loop: true,
        config: { duration: (Math.floor(Math.random() * 1000)) + 2000 },
    });

    const width = 3 + Math.floor(Math.random() * 15);
    const height = width

    return (
        <animated.div
            style={{
                ...dotSpring,
                width: `${width}px`,
                height: `${height}px`,
                borderRadius: '50%',
                position: 'absolute',
                backgroundColor: '#ffffff55',
                zIndex: 999,
            }}
        />
    )
}

const AnimatedBanner = (props) => {
    const sectionref = useRef()

    const site = useSelector(state => state.site)
    colors = [
        site.decorColor1,
        site.decorColor2,
        site.decorColor3,
        site.decorColor4,
        site.decorColor5,
        site.decorColor6,
        site.decorColor7,
    ]
    const scrollToSection = (e) => {
        e.preventDefault()
        if (sectionref.current) {
            // Get the height of the referenced section
            const sectionHeight = sectionref.current.offsetHeight;
            // Scroll to the top of the referenced section
            window.scrollTo(0, sectionHeight);
        }
    }
    // Return a div with some inline styles and children components
    return (
        <div className={styles.homeBanner} ref={sectionref}>
            <div className={styles.animWrapper} style={{ height: props.height ? props.height : '' }}>
                {getStripStartPositions().map((startPos, index) => {
                    return <Strip key={index} id={index + 1} start={startPos} />
                })}
                {getStripStartPositions().map((startPos, index) => {
                    return <Strip key={index * 5} id={index + 1} start={startPos} delay />
                })}
                {Array(10).fill().map((_, i) => <WhiteDot key={i} />)}
                {
                    props.scrollTo === true ?
                    <a href="#" className={styles.downArrow} onClick={scrollToSection}><img src={DownArrow} alt="" /></a>
                    :
                    ''
                }
            </div>
            <div className={styles.bannerCntWrapper + ' container'}>
                <span className={styles.headline} dangerouslySetInnerHTML={{ __html: `${props.title ? props.title : ''}` }}>
                </span>
                <span className={styles.cnt} dangerouslySetInnerHTML={{ __html: `${props.subtitle ? props.subtitle : ''}` }}></span>
                {props.children}
            </div>
        </div>
    )
}

export default AnimatedBanner