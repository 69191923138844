import React, { useEffect } from 'react'
import FieldError from '../FieldError/FieldError';
import SkillsInput from './SkillsInput';

function SkillsFields({ form }) {


    return (
        <section className="jobTypeFieldsWrap fieldGroupWrap">
            <div className="row">
                <div className="fieldHead col-md-3">
                    <h2>Required Skills</h2>
                </div>
                <div className="jobFields col-md-9">
                    <div className="row">
                        <div className="col-12">
                            <div className="fieldWrap">
                                <p>Adding key skills to your job advert will allow you to sort any applications
                                    received by the skills you've entered using the "best match" filter on the jobs &
                                    applications page.</p>
                                <SkillsInput selected={form.values.skills} setSkills={(selected) => {
                                    form.setFieldValue('skills', selected)
                                }} />
                                <FieldError error={form.errors.skills} touched={form.touched.skills} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default SkillsFields
