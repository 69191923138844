import { api } from '../../../utils/api'
import authTokenHeader from '../../../common/functions/authTokenHeader'

const jobAlertApi = api.injectEndpoints({
    endpoints: (builder) => ({
        jobAlert: builder.mutation({
            query: (body) => {
                return {
                    'url': `/jobalerts/job-alert`,
                    'method': 'post',
                    'body': body,
                }
            },
            invalidatesTags: ['JobAlerts'],
        }),
        jobAlertUpdate: builder.mutation({
            query: ({ body, jobAlertId }) => {
                return {
                    'url': `/jobalerts/job-alert/${jobAlertId}`,
                    'method': 'put',
                    'body': body,
                }
            },
            invalidatesTags: ['JobAlerts', 'SingleJobAlert'],
        }),
        getMyProfile: builder.query({
            query: () => {
                return {
                    url: `/user/jobseeker/profile`,
                    headers: {
                        ...authTokenHeader(),
                    }
                }
            },
            providesTags: ['profile']
        }),
        getMyJobAlerts: builder.query({
            query: ({ jobSeeker }) => {
                if (!jobSeeker) {
                    return false;
                }

                return {
                    url: '/jobalerts/my/job-alertList',
                    headers: {
                        ...authTokenHeader(),
                    },
                };
            },
            providesTags: ['JobAlerts'],
        }),
        deleteJobAlert: builder.mutation({
            query: (id) => {
                return {
                    url: `/jobalerts/job-alert/${id}`,
                    method: 'DELETE',
                    headers: {
                        ...authTokenHeader(),
                    }
                }
            },
            invalidatesTags: ['JobAlerts'],
        }),
        jobAlertFrequencyOption: builder.query({
            query: (body) => {
                return {
                    'url': `/jobalerts/frequency-options`,
                }
            },
        }),
        jobAlertPageData: builder.query({
            query: (body) => {
                return {
                    'url': `/exampleprovider/job-alert-page/`,
                }
            },
        }),
        getJobAlert: builder.query({
            query: (jobAlertId) => {
                return {
                    'url': `/jobalerts/job-alert/${jobAlertId}`,
                }
            },
            providesTags: ['SingleJobAlert']
        })
    })
})

export const {
    useJobAlertMutation,
    useGetMyProfileQuery,
    useGetMyJobAlertsQuery,
    useGetJobAlertQuery,
    useJobAlertPageDataQuery,
    useJobAlertFrequencyOptionQuery,
    useDeleteJobAlertMutation,
    useJobAlertUpdateMutation,
} = jobAlertApi