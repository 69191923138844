import React, { useEffect, useRef } from 'react';
import { useGetLocationSuggestionsQuery } from '../../api/jobCreateApiSlice';
import RoundLoader from '../../../../components/Loader/RoundLoader';
import styles from './SuggestionBox.module.scss';

function SuggestionBox({ keyword, form, name = "location_strings", visibilty, setVisibility, onSuggestionSelect }) {
    const { data, isLoading, isFetching } = useGetLocationSuggestionsQuery(keyword);
    const suggestionRef = useRef(null);
    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const handleSuggestionBoxVisibility = (e) => {
            if (
                suggestionRef.current &&
                visibilty &&
                !suggestionRef.current.contains(e.target)
            ) {
                setVisibility(false);
            }
        };

        document.addEventListener('click', handleSuggestionBoxVisibility);

        return () => {
            isMounted.current = false;
            document.removeEventListener('click', handleSuggestionBoxVisibility);
        };
    }, [visibilty, setVisibility]);

    const handleClick = (suggestion) => {
        if (isMounted.current) {
            onSuggestionSelect(suggestion);
            setVisibility(false);
            if (name === "location") {
                form.setFieldValue(name, suggestion);
            }
        }
    };

    if ((isLoading || isFetching) && visibilty) {
        return <RoundLoader className={styles.whiteLoader} />;
    }

    return (data != undefined && data.length > 0 && visibilty) ? (
        <ul className={styles.suggestionBox} ref={suggestionRef}>
            {data.map((item, index) => (
                <li
                    key={index}
                    onClick={() => handleClick(item)}
                >
                    {item}
                </li>
            ))}
        </ul>
    ) : null;
}

export default SuggestionBox;
