import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles/bannerpopup.module.scss';
import LgbtDecorLine from '../../components/LgbtDecorLine/LgbtDecorLine';
import { Link } from 'react-router-dom';

function BannerPopUp() {
  const site = useSelector((state) => state.site);
  const [isVisible, setIsVisible] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem('cookiesAccepted');
    if (!hasAcceptedCookies) {
      setIsVisible(true); 
    }
  }, []);

  const handleAccept = (event) => {
    event.preventDefault();
    localStorage.setItem('cookiesAccepted', 'true'); 
    setIsVisible(false); 
  };

  const handleReject = (event) => {
    event.preventDefault();
    localStorage.setItem('cookiesAccepted', 'false'); 
    setIsVisible(false); 
  };

  return (
    <>
      {isVisible && (
        <div className={styles.bannerpopupwrapper} ref={popupRef}>
          <div className={styles.bannerpopupheading}>
            We value your privacy
          </div>
          <LgbtDecorLine className={styles.popupline} />
          <div className={styles.bannerpopuptext}>
            <p>
              We use cookies to enhance your browsing experience,<br />
              serve personalized ads or content, and analyze our traffic.<br />
              By clicking "Accept All", you consent to our use of<br />
              cookies. <Link to="/privacy-policy">Cookie Policy</Link>
            </p>
          </div>
          <div className={styles.wrapperBtn}>
            <a href='#' className={styles.btn} onClick={handleAccept}>Accept All</a>
            <a href='#' className={styles.btn} onClick={handleReject}>Reject All</a>
          </div>
        </div>
      )}
    </>
  );
}

export default BannerPopUp;
