import {api} from '../../../utils/api'

const aboutApi = api.injectEndpoints({
    endpoints: (builder) => ({
        aboutPageFields: builder.query({
            query: () => `/exampleprovider/about/`,
        }),
    }),
});

export const {useAboutPageFieldsQuery} = aboutApi;
