import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import JobsList from '../../../../components/JobsList/JobsList'
import DefaultCompanyLogo from '../../../../assets/images/default-company-logo.png'
import useSelectTextColor from '../../../../common/hooks/useSelectTextColor'
import Pagination from '../../../../components/Pagination/Pagination'
import styles from './BasicTemplate.module.scss'
import { useGetEmployerJobsQuery } from '../../api/EmployerApiSlice'
import { useLocation } from 'react-router-dom';

function BasicTemplate({ employer }) {

    const loc = useLocation();
    const queryParams = new URLSearchParams(loc.search);
    const keyword = queryParams.get('keyword');
    const location = queryParams.get('location');
    const siteConfig = useSelector(state => state.site)
    const colorSelector = useSelectTextColor()
    const [page, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const page_size = 15;

    let companyLogo = DefaultCompanyLogo;
    const { data: jobs, isLoading, isFetching } = useGetEmployerJobsQuery({
        employerId: employer.id,
        page_size: page_size,
        page: page,
    });

    useEffect(() => {
        if (jobs) {
          setTotalPages(Math.ceil(jobs.count / page_size));
        }
      }, [jobs])
    
      useEffect(() => {
        const page = queryParams.get('page');
        setPageNumber(page ? parseInt(page, 10) : 1);
      }, [loc]);

    return (
        <>
            <section className={styles.companyBanner} >
                <div className="container">
                    <div className={`${styles.bannerSub} row`}>
                        <div className="col-md-12">
                            <div className={styles.bannerLeft}>
                                <div className={styles.logo}>
                                    {employer.logo != null ?
                                        (
                                            <img src={employer.logo.file} alt={employer.logo.alternative_text} />
                                        ) :
                                        <img src={companyLogo} alt="Employer logo" />
                                    }
                                </div>
                                <h1 style={{ color: colorSelector(siteConfig.decorColor7) }}>{employer.name}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className={styles.employerBasicTemplate} style={{
                paddingBottom: '4em',
                "--text-color": '#fff'
            }}>
                <div className="container">
                    <JobsList jobs={jobs} isLoading={isLoading} isFetching={isFetching} className={styles.secJobListing} />
                    <Pagination
                        currentPage={page}
                        totalPages={totalPages}
                        pageSize={page_size}
                        onPageChange={setPageNumber}
                    />
                </div>
            </div>
        </>
    )
}

export default BasicTemplate
