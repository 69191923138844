import Select from 'react-select';
import FieldError from '../FieldError/FieldError';
import { useGetSectorsQuery } from '../../api/jobCreateApiSlice';

function JobSectorFields({ form }) {
    const { data: sectors, error, isError, isLoading } = useGetSectorsQuery();

    const getSectorOptions = (selectedSectors) => {
        if (!sectors) return [];
        return sectors.filter(option => selectedSectors?.includes(option.value));
    }

    return (
        <section className="jobSectorFieldsWrap fieldGroupWrap">
            <div className="row">
                <div className="fieldHead col-md-3">
                    <h2>Sector</h2>
                </div>
                <div className="jobFields col-md-9">
                    <div className="row">
                        <div className="col-12">
                            <div className="fieldWrap">
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    blurInputOnSelect={false}
                                    options={sectors}
                                    value={getSectorOptions(form.values.sectors)}
                                    onChange={(selectedOptions) =>
                                        form.setFieldValue(
                                            'sectors',
                                            selectedOptions.map(option => option.value)
                                        )
                                    }
                                />
                                <FieldError error={form.errors.sector} touched={form.touched.sector} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default JobSectorFields
