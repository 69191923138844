// ConfirmationPopup.js
import React from 'react';
import styles from './ConfirmationPopup.module.scss'; // Assuming you have styles for popup

function ConfirmationPopup({ message, onConfirm, onCancel }) {
    return (
        <div className={styles.popupBackdrop}>
            <div className={styles.popupContainer}>
                <h2>Confirm?</h2>
                <p>{message}</p>
                <div className={styles.popupActions}>
                    <button onClick={onCancel} className={styles.cancelButton}>Cancel</button>
                    <button onClick={onConfirm} className={styles.confirmButton}>Confirm</button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationPopup;
