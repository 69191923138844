function useSelectTextColor() {
    return (backgroundColor) => {
        // Convert hex to RGB
        const hexToRgb = (hex) =>
            hex
                .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
                .substring(1)
                .match(/.{2}/g)
                .map((x) => parseInt(x, 16));

        // Calculate luminance
        const calculateLuminance = (rgb) =>
            0.2126 * (rgb[0] / 255) + 0.7152 * (rgb[1] / 255) + 0.0722 * (rgb[2] / 255);

        // Get RGB values
        const rgb = hexToRgb(backgroundColor);

        // Calculate luminance
        const luminance = calculateLuminance(rgb);

        // Decide text color based on luminance
        return luminance > 0.55 ? '#000' : '#fff';
    }
}

export default useSelectTextColor
