import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './EmployerGrid.module.scss';
import useCompanyUrl from '../../../../common/hooks/useCompanyUrl';

function EmployerGrid(props) {
    const companyUrl = useCompanyUrl();
    const [visibleItems, setVisibleItems] = useState(0);

    var length = props.data.companies.length

    useEffect(() => {
        function handleResize() {
            const screenWidth = window.innerWidth;
            if (screenWidth > 1400) {
                setVisibleItems(length-(length%5));
            } else if (screenWidth > 1200) {
                setVisibleItems(length-(length%4));
            } else if (screenWidth > 768) {
                setVisibleItems(length-(length%3));
            } else {
                setVisibleItems(length-(length%2));
            }
        }

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div key={props.index} className={styles.clientListing}>
            <div className='container'>
                <h2 style={props.data?.title_color ? { color: props.data.title_color } : null}>{props.data?.title}</h2>
                <ul className={styles.listingWrapper}>
                    {props.data?.companies.map((company, key) => {
                        if (key < visibleItems) {
                            return (
                                <li key={key} className={styles.logoContainer}>
                                    <Link to={company ? companyUrl(company.company_slug) : '#'}>
                                        <div className={styles.logoWrapper}>
                                            <img src={company.logo_url} className='img-fluid' alt="Company Logo" />
                                        </div>
                                    </Link>
                                </li>
                            );
                        }
                        return null;
                    })}
                </ul>
            </div>
        </div>
    );
}

export default EmployerGrid;
