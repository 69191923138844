import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const GAPageTracker = () => {

  const location = useLocation();

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    ReactGA.send({ hitType: 'pageview', page: pagePath });
  }, [location]);
  return <> </>
};

export default GAPageTracker;
