import React, { useRef } from 'react';
import styles from './Pagination.module.scss';
import { useLocation, Link } from 'react-router-dom';

const Pagination = ({ currentPage, totalPages }) => {

    const loc = useLocation();
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    const componentRef = useRef(null);

    const renderPageNumbers = () => {
        const pages = [];

        if (totalPages <= 1) {
            return pages;
        }

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            if (currentPage <= 5) {
                pages.push(1, 2, 3, 4, 5, '...', totalPages - 1, totalPages);
            } else if (currentPage >= totalPages - 3) {
                pages.push(1, '...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
            } else {
                pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages - 1, totalPages);
            }
        }

        return pages;
    };

    if (totalPages <= 1) {
        return null;
    }
    const getPagedUrl= (page)=>{
        const params = new URLSearchParams(loc.search);
        params.set('page', page);
        return '?'+ params.toString()

    }
    return (
        <div ref={componentRef} className={styles.Wrapper}>
            <ul className={styles.pagination}>
                {/* Previous Page Button */}
                {!isFirstPage && (
                    <li>
                        <Link to={getPagedUrl(currentPage - 1)}>{'<'}</Link>
                    </li>
                )}

                {/* Page Numbers */}
                {renderPageNumbers().map((page, index) =>
                    page === '...' ? (
                        <li key={index}>
                            <span>...</span>
                        </li>
                    ) : (
                        <li key={index}>
                            <Link
                                to={getPagedUrl(page)}
                                className={currentPage === page ? styles.active : ''}
                            >
                                {page}
                            </Link>
                        </li>
                    )
                )}

                {/* Next Page Button */}
                {!isLastPage && (
                    <li>
                        <Link to={getPagedUrl(currentPage + 1)}>{'>'}</Link>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default Pagination;
