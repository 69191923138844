import React, { useState, useRef, useEffect } from 'react';
import styles from './SortFilter.scss';
import { IoIosArrowDown } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { FiSearch } from "react-icons/fi";


export default function SortFilter({ sort, filter, search }) {
    // ===============Sort=============================
    const { selectedSortOption, setSelectedSortOption } = sort;
    const { selectedFilterOption, setSelectedFilterOption } = filter;
    const { searchInput, setSearchInput } = search;

    const [isOpen, setIsOpen] = useState(false);
    const sortRef = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const sortingOptions = [
        { key: 'title', label: "Title" },
        { key: 'date', label: "Date Posted" },
        { key: 'status', label: "Status" }
    ];

    const handleClickOutside = (event) => {
        if (sortRef.current && !sortRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (optionKey) => {
        setSelectedSortOption(optionKey);
        setIsOpen(false);
    };

    const handleResetClick = () => {
        setSelectedSortOption('');
        setIsOpen(false);
    };

    const getSortSelectedLabel = () => {
        let sortOption = sortingOptions.filter((item) => {
            return item.key == selectedSortOption;
        });
        return sortOption.length <= 0 ? "Sort" : "Sorted by " + sortOption[0]?.label;
    }

    // =================Filter=============================
    const [newOpen, setNewOpen] = useState(false);
    const filterRef = useRef(null);

    useEffect(() => {
        document.addEventListener('click', clickOutside);
        return () => {
            document.removeEventListener('click', clickOutside);
        };
    }, []);

    const filterOptions = [
        { key: 'active', label: 'Active' },
        { key: 'inactive', label: 'Inactive' },
        { key: 'expired', label: 'Expired' },
        { key: 'filled', label: 'Filled' },
        { key: 'preview', label: 'Preview' },
        { key: 'draft', label: 'Draft' }
    ];

    const clickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setNewOpen(false);
        }
    };

    const clickFilter = () => {
        setNewOpen(!newOpen);
    };

    const clickFilterOpen = (optionKey) => {
        setSelectedFilterOption(optionKey);
        setNewOpen(false);
    };

    const clickReset = () => {
        setSelectedFilterOption('');
        setNewOpen(false);
    };
    const getFilterSelectedLabel = () => {
        let filterOption = filterOptions.filter((item) => {
            return item.key == selectedFilterOption;
        });
        return filterOption.length <= 0 ? "Filter" : 'Filtered by ' + filterOption[0]?.label;
    }
    // ==================search=================
    // const [searchInput, setSearchInput] = useState('');
    return (
        <div className={styles.SortFilter}>
            <div className='sort_filter'>
                <div className='form-search'>
                    <FiSearch className='search-icon' />
                    <input
                        type="text"
                        id="search"
                        name="search"
                        placeholder='search..'
                        value={searchInput}
                        onChange={(event) => {
                            setSearchInput(event.target.value);
                        }}
                    />
                </div>
                <div className='sortFilter_contents'>
                    <div className='sort_btn' ref={sortRef}>
                        <button onClick={handleClick}>
                            {getSortSelectedLabel()}
                            {selectedSortOption === '' ? <IoIosArrowDown className={isOpen ? 'rotated' : ''} /> : <RxCross2 onClick={handleResetClick} />}
                        </button>
                        {isOpen && (
                            <ul className='sort_contents'>
                                {sortingOptions.map(option => (
                                    <li key={option.key}>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                            handleOptionClick(option.key);
                                        }}>
                                            {option.label}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className='sort_btn' ref={filterRef}>
                        <button onClick={clickFilter}>
                            {getFilterSelectedLabel()}
                            {selectedFilterOption === '' ? <IoIosArrowDown className={newOpen ? 'rotated' : ''} /> : <RxCross2 onClick={clickReset} />}
                        </button>
                        {newOpen && (
                            <ul className='sort_contents'>
                                {filterOptions.map(option => (
                                    <li key={option.key}>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                            clickFilterOpen(option.key)
                                        }}>
                                            {option.label}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
