import { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useGetProfileQuery, useUpdateProfileMutation } from '../../Dashboard/api/dashboardApiSlice';
import { LgbtToast } from '../../../components/LgbtToast/LgbtToast';

const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    linkedin_profile_url: '',
}

const validationSchema = Yup.object({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string(),
    email: Yup.string().email('Invalid email format').required('Required'),
    linkedin_profile_url: Yup.string()
        .url('Invalid URL format')
        .matches(
            /^https?:\/\/(www\.)?linkedin\.com\/.*$/,
            'URL must be a valid LinkedIn profile'
        ),
});

const useProfileUpdateForm = () => {

    const { data: profileData, isLoading: pdLoading, isError: pdIsError, error: pdError } = useGetProfileQuery();
    const [updateProfile, { isLoading, isFetching, isError, error }] = useUpdateProfileMutation();
    const [formError, setFormError] = useState('');
    const [emailTouched, setEmailTouched] = useState(false);

    const profileUpdateForm = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const response = await updateProfile(values);
            let message = 'Your profile Details are updated';
            if (response.error) {
                if (response.error.status === 401 && response.error?.data.detail != undefined)
                    setFormError(response.error?.data.detail);
                else if (response.error.status === 400 && response.error?.data != undefined)
                    setFormError(response.error.data[0]);
                else
                    setFormError('Something went wrong! Please try again later')
                return;
            }
            if (emailTouched) {
                message = "A verification email is sent to the existing email address";
            }

            LgbtToast({ message, type: 'success' });
        }
    })

    const formRef = useRef();

    useEffect(() => {
        if (profileData == undefined && profileUpdateForm) return;

        if (!formRef.current) {
            profileUpdateForm.setValues({
                first_name: profileData.first_name || '',
                last_name: profileData.last_name || '',
                email: profileData.email || '',
                linkedin_profile_url: profileData.linkedin_profile_url || ''
            });
            formRef.current = true;
        }

    }, [profileData, profileUpdateForm])

    useEffect(() => {

        if (profileUpdateForm.touched.email) {
            console.log(profileUpdateForm);

            setEmailTouched(false);
        }
    }, [profileUpdateForm])

    return { profileUpdateForm, isLoading, formError }
}

export default useProfileUpdateForm
