import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import useEmployerProfile from './hooks/useEmployerProfile'
import { showLoader, hideLoader } from '../GlobalLoader/globalLoaderSlice'
import { LgbtToast } from '../../components/LgbtToast/LgbtToast'
import SomethingWrong from '../../components/SomethingWrong/SomethingWrong'
import NotFound from '../../components/NotFound/NotFound'

function EmployerProfile() {
    const { employer, pageComponent, isLoading, error } = useEmployerProfile()
    const dispatch = useDispatch()
    const loaderId = { id: 'employerProfile' }

    useEffect(() => {
        if (isLoading) {
            dispatch(showLoader(loaderId))
        } else {
            dispatch(hideLoader(loaderId))
        }
    }, [isLoading])

    if (error) {
        if (error.status === 404) {
            return <NotFound />
        } else {
            LgbtToast({ message: error.data.error, type: "error" })
            return <SomethingWrong />
        }
    }

    return (
        <>
            {pageComponent}
        </>
    )
}

export default EmployerProfile
