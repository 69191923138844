import React from 'react'
import Button from '../Button/Button'
import styles from './NotFound.module.scss'
import banner from '../../assets/images/static-banner.png'
import { useSelector } from 'react-redux'

function NotFound() {
  const siteSetting = useSelector(state => state.site)
  return (
    <section style={{ background: `url(${siteSetting.bannerImage ?? banner})` }} className={styles._404}>
      <div className="container">
        <h1>Oops!</h1>
        <h2>404 Not Found</h2>
        <p className={styles.cnt}>Sorry, Requested page not found!</p>
        <Button className={styles.btn} to='/'>Go to Home</Button>
      </div>
    </section>
  )
}

export default NotFound
