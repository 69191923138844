import React, { useState, useEffect, useRef } from 'react';
import useSelectTextColor from '../../../../common/hooks/useSelectTextColor';

function ColoredOverview({ data, primaryColor }) {
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [contentLines, setContentLines] = useState(10);
    const contentRef = useRef(null);
    const [isContentTruncated, setIsContentTruncated] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };
    const colorSelector = useSelectTextColor();

    useEffect(() => {
        const contentElement = contentRef.current;

        const lineHeight = parseInt(window.getComputedStyle(contentElement).lineHeight);
        const maxHeight = lineHeight * contentLines;

        if (contentElement.scrollHeight > maxHeight) {
            setShowFullDescription(false);
        }
        setIsContentTruncated(contentElement.scrollHeight > maxHeight);
        if (contentElement.scrollHeight > maxHeight) {
            setShowFullDescription(false);
        }
    }, [contentLines]);

    return (
        <section className="video-sec reverse">
            <div className="container">
                <div className="video-sub row">
                    {data.youtube_iframe && (
                        <div className={data.title || data.content ? 'col-md-6' : 'col-md-12'}>
                            <div className="video-left">
                                {data?.youtube_iframe && (
                                    <div className="o-video" dangerouslySetInnerHTML={{ __html: data?.youtube_iframe }}></div>
                                )}
                            </div>
                        </div>
                    )}
                    {(data.title || data.content) && (
                        <div className={data.youtube_iframe ? 'col-md-6' : 'col-md-12'}>
                            <div className="video-right">
                                <h2 dangerouslySetInnerHTML={{ __html: data?.title }} style={{ color: colorSelector(primaryColor) }}></h2>
                                <div
                                    ref={contentRef}
                                    className="content"
                                >
                                    <div style={{ color: colorSelector(primaryColor), overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical' }}>
                                        <span dangerouslySetInnerHTML={{ __html: data?.content }}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default ColoredOverview
