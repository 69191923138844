import { api } from '../../../utils/api'

const popupApi = api.injectEndpoints({
    endpoints: (builder) => ({
        homepagePopUpData: builder.query({
            query: () => `/exampleprovider/lightbox-popup/`,
        }),
    }),
});

export const { useHomepagePopUpDataQuery } = popupApi;
