import React from 'react'
import { useSelector } from 'react-redux'
import bannerImage from '../../../../assets/images/generic-banner.png'
import styles from './SignUpConfirm.module.scss'

function SignUpConfirm() {
	const siteSetting = useSelector(state => state.site)
	return (
		<section className={styles.signupConfirm} style={{ backgroundImage: `url(${siteSetting.bannerImage ?? bannerImage})` }}>
		{/* <section className={styles.signupConfirm} style={{ backgroundImage: `url(${bannerImage})` }}> */}
			<div className={`${styles.cntOuter} container`}>
				<h1>Thank you for Signing Up!</h1>
				<h3>Email Verification mail sent to your registered email address, Please verify your email address to complete sign up</h3>
			</div>
		</section>
	)
}

export default SignUpConfirm;