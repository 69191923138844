import { toast, cssTransition } from 'react-toastify';
import './LgbtToast.scss'

const lgbtAnim = cssTransition({
  enter: 'fade-in-right',
  exit: 'fade-out-right'
})

const toastDefaultOptions = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: lgbtAnim
}


export const LgbtToast = ({ message, type = '', options = {} }) => {
  let finalOptions = {
    ...toastDefaultOptions,
    ...options
  }
  let toastId;
  switch (type) {
    case 'success':
      toastId = toast.success(message, finalOptions)
      break

    case 'warn':
      toastId = toast.warn(message, finalOptions)
      break

    case 'error':
      toastId = toast.error(message, finalOptions)
      break

    case 'info':
      toastId = toast.info(message, finalOptions)
      break

    default:
      toastId = toast(message, finalOptions)
  }
  return toastId;
}

