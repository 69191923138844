import React from 'react'

import { Link } from 'react-router-dom';
import styles from './JobAccordion.module.scss'

const Accordion = ({ job, isActive, onClick, jobUrl, handleDelete, viewsKeys }) => {
    let dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    let created = new Date(job.created_at).toLocaleDateString('en-US', dateOptions);
    let expiry = job.expiry_date != null ? new Date(job.expiry_date).toLocaleDateString('en-US', dateOptions) : '-';
    let status = styles[job.status.toLowerCase()];
    return (
        <div className={styles.accordion}>
            <div className={`${styles.accordionHeader} ${isActive ? `active ${styles.active}` : ''}`} onClick={onClick}>
                <div>{job.title}</div>
            </div>
            {isActive && (
                <div className={styles.accordionContent}>
                    <table>
                        <tr>
                            <td><b>Date Posted</b></td>
                            <td>{created}</td>
                        </tr>
                        <tr>
                            <td><b>Expiry Date</b></td>
                            <td>{expiry}</td>
                        </tr>
                        <tr>
                            <td><b>Views</b></td>
                            {viewsKeys.map((key, index) => {
                                const trimmedKey = key.replace(/^views_on_(.*?)couk$/, '$1');
                                return (
                                    <div className='row' key={index}>
                                        <div className={`${styles[trimmedKey.charAt(0).toUpperCase() + trimmedKey.slice(1)]} ${styles.mobViews} ${styles.mobViews}`}>
                                            {trimmedKey.charAt(0).toUpperCase() + trimmedKey.slice(1)} : {job[key]}
                                        </div>
                                    </div>
                                );
                            })}
                        </tr>
                        <tr>
                            <td><b>Status</b></td>
                            <td><span className={status}>{job.status}</span></td>
                        </tr>
                    </table>
                    <div className={styles.links}>
                        <Link className={styles.edit} to={`/job/${job.id}/edit`}>Edit</Link>
                        <Link className={styles.view} target='_blank' to={jobUrl(job.slug)}>View</Link>
                        <button className={styles.delete} onClick={(e) => { handleDelete(job.slug, e) }} to='#'>Delete</button>
                    </div>
                </div>
            )}
        </div>
    );
};



export default Accordion
