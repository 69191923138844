import { useState, useRef, useEffect } from "react"
import styles from './SuggestionList.module.scss'

function SuggestionList({ keyword, data, setValue, inputRef }) {


    const [visibilty, setVisibility] = useState(0)
    const [selectedItem, setSelectedItem] = useState(null)
    const suggestionRef = useRef()

    const handleClick = (e) => {
        setValue(e.target.innerText)
        setVisibility(false)
    }

    useEffect(() => {
        document.addEventListener('click', handleSuggestionListVisibility)
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('click', handleSuggestionListVisibility);
            document.removeEventListener('keydown', handleKeyDown)
        };
    });

    useEffect(() => {
        if (keyword)
            setVisibility(true)
        return () => {
            setVisibility(false)
        }
    }, [keyword])

    useEffect(() => {
        setVisibility(false)
    }, [])

    const handleKeyDown = (e) => {
        // Handle down arrow key
        if (!data) {
            return
        }
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            const currentIndex = selectedItem !== null ? selectedItem : -1;
            const nextIndex = (currentIndex + 1) % data.length;
            setSelectedItem(nextIndex);
        }
        else if (e.key === 'ArrowUp') {
            e.preventDefault();
            const currentIndex = selectedItem !== null ? selectedItem : data.length;
            const nextIndex = (currentIndex - 1) !== -1 ? (currentIndex - 1) : data.length - 1;
            setSelectedItem(nextIndex);
        }
        // Handle enter key
        else if (e.key === 'Enter') {
            if (!visibilty) {
                return;
            }
            e.preventDefault();
            if (selectedItem !== null) {
                const selectedValue = data[selectedItem];
                setValue(selectedValue)
                setSelectedItem(null)
                setVisibility(false);
            }
        }
    }

    const handleSuggestionListVisibility = (e) => {
        if (
            visibilty ||
            keyword
        ) {
            setVisibility(false);
        }
    }

    const inputChangeHandler = () => {
        if (keyword) {
            setVisibility(true)
        }
    }

    return (data != undefined && data.length > 0 && visibilty) ?
        (<ul className={styles.suggestionList} ref={suggestionRef}>
            {data && data.map((item, index) => {
                return (
                    <li key={index} className={`${styles.suggestionItem} ${index === selectedItem ? styles.selected : ''}`} onClick={handleClick}>{item}</li>
                )
            })}
        </ul>) : null;
}

export default SuggestionList
