import { api } from '../../../utils/api'

const footerApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getFooter: builder.query({
            query: () => `/exampleprovider/footer/`,
        }),
    }),
})

export const { useGetFooterQuery } = footerApi