import React from 'react'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import useJobAlertForm from './Hooks/useJobAlertForm'
import { LgbtToast } from '../../components/LgbtToast/LgbtToast'

import BasicJobFields from '../JobPost/components/BasicJobFields/BasicJobFields'
import JobLocationFields from '../JobPost/components/JobLocationFields/JobLocationFields';
import SalaryFields from '../JobPost/components/SalaryFields/SalaryFields';
import { useJobAlertPageDataQuery, useJobAlertFrequencyOptionQuery } from './api/jobAlertApiSlice'
import JobTypeFieldsMultiple from './Components/JobTypeFIeldsMultiple/JobTypeFieldsMultiple'
import JobSectorFieldsMultiple from './Components/JobSectorFieldsMultiple/JobSectorFieldsMultiple'
import StaticBanner from '../../components/StaticBanner/StaticBanner'
import NameField from './Components/NameField/NameField'
import FrequencyField from './Components/FrequencyField/FrequencyField'

import styles from './Styles/JobAlertPage.module.scss'

function JobAlertPage() {

    const { jobAlertForm, isError, Error, AlertResponseMessage } = useJobAlertForm();
    const { data: pageData, error, isError: pageIsError, isLoading } = useJobAlertPageDataQuery();
    const { data: frequency } = useJobAlertFrequencyOptionQuery()

    useEffect(() => {
        // Scroll to the first error when the form is submitted
        if (jobAlertForm.submitCount > 0 && Object.keys(jobAlertForm.errors).length > 0) {
            const firstErrorField = Object.keys(jobAlertForm.errors)[0];
            const element = document.getElementsByName(firstErrorField)[0];
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }

        // show toast if there are errors on submission
        if (jobAlertForm.submitCount > 0 && Object.keys(jobAlertForm.errors).length > 0) {
            if (jobAlertForm.errors.recaptcha) {
                LgbtToast({ message: 'Please reload this page to submit another response', type: 'error' })
            } else {
                LgbtToast({ message: 'Please fill in all required fields', type: 'error' })
            }
        }

    }, [jobAlertForm.submitCount, AlertResponseMessage]);

    const [selectedContractTypes, setSelectedContractTypes] = useState([]);
    const [selectedSectorTypes, setSelectedSectorTypes] = useState([]);

    useEffect(() => {
        jobAlertForm.setFieldValue('contract_type', selectedContractTypes)
        jobAlertForm.setFieldValue('sector', selectedSectorTypes)
    }, [selectedContractTypes, selectedSectorTypes])

    return (
        <div className={styles.jobAlert}>
            <StaticBanner
                title={pageData?.title}
                subtitle={pageData?.sub_title}
                page={'jobAlert'}
                background={pageData?.background_image}
            />
            <div className={styles.formWrapper}>
                <div className={`${styles.container} container`}>
                    <Formik>
                        <form action="" className={`${styles.form} jobPostForm`} onSubmit={jobAlertForm.handleSubmit}>
                            <span className="req">*Required fields</span>
                            {
                                AlertResponseMessage !== '' ?
                                    <div className={`${styles.AlertMessage} ${isError ? styles.red : styles.green}`}>
                                        <h3>{AlertResponseMessage}</h3>
                                    </div>
                                    : ''
                            }

                            <NameField
                                form={jobAlertForm}
                            />

                            <BasicJobFields
                                form={jobAlertForm}
                                fields={["title"]}
                                whichForm={'jobAlertForm'}
                                placeHolder={`Please add job titles you are interested in eg sales executive, sales manager.`}
                            />

                            <JobLocationFields form={jobAlertForm} type="alert" whichForm={'jobAlertForm'} locationPlaceholder={`Please state your desired job location e.g. Manchester or postcode`}/>

                            <JobSectorFieldsMultiple form={jobAlertForm} />

                            <SalaryFields form={jobAlertForm} whichForm={'jobAlertForm'} fields={["minimumSalary", "maximumSalary"]} />

                            <JobTypeFieldsMultiple form={jobAlertForm} />

                            <FrequencyField form={jobAlertForm} options={frequency} />

                            <div className={`${styles.submitBtn} submitBtn container row`}>
                                <div className="col-md-9" dangerouslySetInnerHTML={{ __html: pageData?.bottom_text }} />
                                <div className={`${styles.buttonWrapper} col-md-3`}>
                                    <button type="submit">Create alert</button>
                                </div>
                            </div>
                        </form>
                    </Formik>
                </div>
            </div>

        </div>
    )
}

export default JobAlertPage