import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FiEdit } from "react-icons/fi";
import JobSingleTemplate from './JobSingleTemplate'
import { useLocation } from 'react-router-dom'
import { showLoader, hideLoader } from '../../../features/GlobalLoader/globalLoaderSlice';
import { useGetSingleJobPreviewQuery } from '../api/singleJobApiSlice'

import Button from '../../../components/Button/Button'
import NotFound from '../../../components/NotFound/NotFound'
import { LgbtToast } from '../../../components/LgbtToast/LgbtToast'
import SomethingWrong from '../../../components/SomethingWrong/SomethingWrong'
import styles from '../styles/JobPreview.module.scss'

function JobSinglePreview() {
    const dispatch = useDispatch()
    const loc = useLocation();
    const loaderId = { id: 'jobSingle' };
    const queryParams = new URLSearchParams(loc.search);
    const jobSlug = queryParams.get('slug');
    const timestamp = queryParams.get('timestamp');
    const { data, isloading, isFetching, isError, error } = useGetSingleJobPreviewQuery({ jobSlug, timestamp });
    const previewingFromDashboard = useSelector(state => state.dashboard.previewingFromDashboard)

    useEffect(() => {
        if (isloading || isFetching)
            dispatch(showLoader(loaderId));
        else
            dispatch(hideLoader(loaderId));
    }, [dispatch, isloading, isFetching]);

    if (isError || error) {
        if (error.originalStatus === 404) {
            return <NotFound />;
        }
        LgbtToast({ message: error.status, type: "error" })
        return <SomethingWrong />
    }
    return (
        <div className={styles.previewWrap}>
            <Button
                to={previewingFromDashboard ? `/job/${data?.id}/edit` : data?.continue_editing_url}
                className={styles.continueEdit}
            >
                Continue editing
                <FiEdit />
            </Button>
            <JobSingleTemplate data={data} />
        </div>
    )

}

export default JobSinglePreview
