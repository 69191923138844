import React from 'react'
import styles from './JobListSkeleton.module.scss'

function JobListSkeleton({ count = 6 }) {

    const jobCards = [];

    for (let i = 0; i < count; i++) {
        jobCards.push(
            <div className={`placeholder-wave ${styles.jobCardWrap}`} aria-hidden="true" key={i}>
                <div className={styles.skeletonTop}>
                    <div className={styles.topLeft}>
                        <div className={styles.title}>
                            <span className="placeholder col-9"></span>
                        </div>
                        <div className={styles.points}>
                            <div className={styles.left}>
                                <span className="placeholder col-3"></span>
                                <span className="placeholder col-3"></span>
                            </div>
                            <div className={styles.right}>
                                <span className="placeholder col-3"></span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.topRight}>
                        <span className={`placeholder col-3 ${styles.imgPlaceholder}`}></span>
                    </div>
                </div>
                <div className={styles.skeletonBottom}>
                    <span className="placeholder col-12"></span>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.skeleton}>
            {jobCards}
        </div>
    )
}

export default JobListSkeleton
