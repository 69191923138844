import { api } from '../../../utils/api'

const searchApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getSearchResults: builder.query({
      query: ({ keyword, location, contractTypes, minSalary, maxSalary, sort, page_size, page, sector, postedDate, location_radius }) => {
        let query = `/jobmanager/jobs/search/?s=${keyword}&location=${location}`;
        if (contractTypes?.length > 0) {
          query += `&contract-types=${contractTypes}`
        }
        if ((minSalary != null) && (minSalary != 0)) {
          query += `&salary-min=${minSalary}`
        }
        if ((maxSalary != null) && (maxSalary != 0)) {
          query += `&salary-max=${maxSalary}`
        }
        if ((postedDate !== undefined) || (postedDate !== null)) {
          query += `&date-posted=${postedDate}`
        }
        if (page_size !== null) {
          query += `&page_size=${page_size}`
        }
        if (page !== 0) {
          query += `&page=${page}`
        }
        if (sector?.length > 0) {
          const encodedSectors = sector.map(item => encodeURIComponent(item));
          query += `&sectors=${encodedSectors}`
        }
        if ((location_radius)) {
          query += `&location-radius=${location_radius}`
        }
        return query;
      },
    }),
    getKeywordSearchResults: builder.query({
      query: ({ keyword }) => `/jobmanager/search/autocomplete/?s=${keyword}`,
    }),
    getLocationSearchResults: builder.query({
      query: ({ location }) => `/jobmanager/search/autocomplete_location/?location=${location}`,
    }),
  }),
});

export const { useGetSearchResultsQuery, useGetKeywordSearchResultsQuery, useGetLocationSearchResultsQuery } = searchApiSlice;
