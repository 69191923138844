import React from 'react';
import { useEffect, useState } from 'react';
import staticBanner from '../../assets/images/static-banner.png';
import { Link } from 'react-router-dom'
import styles from './styles/About.module.scss';
import useCompanyUrl from '../../common/hooks/useCompanyUrl'
import { useAboutPageFieldsQuery } from './api/aboutApi';
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader } from '../GlobalLoader/globalLoaderSlice';

import LinedDecorTitle from '../../components/LinedDecorTitle/LinedDecorTitle'
import AboutCard from '../../components/AboutCard/AboutCard';
import TitleSubtitleCard from '../../components/TitleSubtitleCard/TitleSubtitleCard';
import ImageContentBlock from '../../components/ImageContentBlock/ImageContentBlock';
import EmployerGrid from './components/EmployerGrid/EmployerGrid';

function About() {
    const { data, error, isError, isLoading } = useAboutPageFieldsQuery();
    const dispatch = useDispatch();
    const companyUrl = useCompanyUrl()
    const loaderId = { id: 'about' };

    const siteConfig = useSelector(state => state.site)

    useEffect(() => {
        if (isLoading) {
            dispatch(showLoader(loaderId));
        } else {
            dispatch(hideLoader(loaderId));
        }
    }, [isLoading, dispatch]);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);

        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    const pageData = data?.sections.map((section, index) => {
        switch (section.type) {
            case 'static_banner':
                return (
                    <>
                        <div key={index} className={`${styles.banner} ${styles.neuroBanner}`}>
                            <div className={`${styles.container} container`}>
                                <div className={styles.bannerCard}>
                                    <div className='row'>
                                        <LinedDecorTitle tag="h1">{section.title}</LinedDecorTitle>
                                        <div dangerouslySetInnerHTML={{ __html: section.description }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            case 'hero_banner':
                return (
                    <>
                        <div key={index} className={styles.banner} style={{ backgroundImage: `url(${staticBanner})` }}>
                            <div className={`${styles.container} container`}>
                                <div className={styles.bannerCard}>
                                    <div className='row'>
                                        <LinedDecorTitle tag="h1">{section.title}</LinedDecorTitle>
                                        <div dangerouslySetInnerHTML={{ __html: section.description }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            case 'single_image_banner':
                return (
                    <div key={index} className={styles.imageWrapper}>
                        {
                            isMobile == false ?
                                <img src={`${section.background}`} alt="" />
                                : <img src={`${section.mobile_background}`} alt="" />
                        }
                    </div>
                );
            case 'image_content_block':
                return (
                    <ImageContentBlock
                        image={section.image}
                        paragraphLeft={section.content_left}
                        paragraphBottom={section.content_bottom}
                        background={section.background_color}
                    />
                )
            case 'title_subtitle_card':
                return (
                    <TitleSubtitleCard
                        title={section.title}
                        description={section.description}
                        background={section.background_color}
                        stripes={section.stripes}
                    />
                );
            case 'about_card':
                return (
                    <AboutCard
                        key={index}
                        title={section.title}
                        description={section.description}
                        image={section.image}
                        textBlockHalf={section.text_block_half_width}
                        textBlockFull={section.text_block_full_width}
                    />
                );
            case 'company_logo_section':
                return (
                    <EmployerGrid index={index} data={section} />
                );
            case 'linkedin_bio_section':
                return (
                    <div key={index} className={styles.linkedinBio} style={siteConfig.domain.includes('lgbtjobs.co.uk') ? { backgroundColor: 'var(--primary-color)' } : null} >
                        <div className="container">
                            <div className={styles.title}>
                                <h2>{section.title}</h2>
                            </div>
                            <div className={styles.description} dangerouslySetInnerHTML={{ __html: section.description }}>
                            </div>
                        </div>
                    </div>
                );
            case 'single_paragraph_block':
                return (
                    <div className={styles.block}>
                        <div className="container">
                            <div className={styles.text} dangerouslySetInnerHTML={{ __html: section.paragraph }} />
                        </div>
                    </div>
                );
            case 'linkedin_feed': {
                return section.embed_code ? (
                    <div key={index} className={styles.feedWrapper}>
                        <div className="container" dangerouslySetInnerHTML={{ __html: section.embed_code }} />
                    </div>
                ) : (
                    ""
                );
            }
            default:
                return null;
        }
    });

    return (
        <div className={styles.aboutUsPageWrapper}>
            {pageData}
        </div>
    );
}

export default About;
