import DatePicker from 'react-date-picker';

import FieldError from '../FieldError/FieldError';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import styles from './ExpiryFields.module.scss';

function ExpiryFields({form, context}) {

    return (
        <section className={`${styles.expiryFields} jobTypeFieldsWrap fieldGroupWrap`}>
            <div className="row">
                <div className="fieldHead col-md-3">
                    <h2>Ad. end date</h2>
                </div>
                <div className="jobFields col-md-9">
                    <div className="row">
                        <div className="col-12">
                            <div className="fieldWrap">
                                <div className="labelBox">
                                    <label htmlFor="contract-type">The job will appear until</label>
                                </div>
                                {context === "updated" ?
                                <DatePicker
                                    minDate={new Date()}
                                    format='y/MM/dd'
                                    calendarClassName={styles.calendar}
                                    clockClassName={styles.clock}
                                    className={styles.dateField}
                                    onChange={(e)=>{
                                        form.setFieldValue('expiry_date', e)
                                    }}
                                    value={form.values.expiry_date} /> :
                                <DatePicker
                                    format='y/MM/dd'
                                    calendarClassName={styles.calendar}
                                    clockClassName={styles.clock}
                                    className={styles.dateField}
                                    onChange={(e)=>{
                                        form.setFieldValue('expiry_date', e)
                                    }}
                                    value={form.values.expiry_date} />
                                }
                                <FieldError error={form.errors.expiry_date} touched={form.touched.expiry_date} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ExpiryFields
