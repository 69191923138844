import React from 'react'
import LgbtDecorLineSingle from '../LgbtDecorLineSingle/LgbtDecorLineSingle'

import styles from './LinedDecorTitleGreen.module.scss'

function LinedDecorTitleGreen(props) {
    const DynamicTag = props.tag || 'h2'
    return (
        <div className={styles.title}>
            <LgbtDecorLineSingle color='green' />
            <DynamicTag>{props.children}</DynamicTag>
            <LgbtDecorLineSingle color='green' />
        </div>
    )
}

export default LinedDecorTitleGreen