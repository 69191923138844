import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { IoSearchOutline } from 'react-icons/io5';
import { IoLocationOutline } from 'react-icons/io5';
import TextInput from '../../components/TextInput/TextInput'
import styles from './styles/CompactCompanySearch.module.scss'

const initValues = {
    company: '',
    location: '',
    searchScaleValue: '',
}

const validationSchema = Yup.object({
    'company': Yup.string().required('Required'),
    'location': Yup.string().required('Required'),
    'searchScaleValue': Yup.string()
})

const onSubmit = (values) => {
    console.log('Form data', values)
}

function CompactCompanySearch() {
    return (
        <div className={styles.companySearchWrap}>
            <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <Form className={styles.companySearchForm}>
                    <TextInput className={styles.inputWrap} icon={<IoSearchOutline className={styles.icon} />} name="company" placeholder="Search by Company" />
                    <TextInput className={styles.inputWrap} icon={<IoLocationOutline className={styles.icon} />} name="location" placeholder="Search by Location" />
                </Form>
            </Formik>
        </div>
    );
}

export default CompactCompanySearch