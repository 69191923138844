import React, { useEffect } from 'react'

import LinedDecorTitle from '../LinedDecorTitle/LinedDecorTitle'
import JobsList from '../JobsList/JobsList'
import { useGetPopularJobsQuery } from './api/popularJobsApi'
import { LgbtToast } from '../LgbtToast/LgbtToast'
import styles from './PopularJobs.module.scss'


function PopularJobs(props) {

    const { data, isError, error, isLoading, isFetching } = useGetPopularJobsQuery()

    useEffect(() => {
        if (isError || error) {
            LgbtToast({ message: error.status, type: 'error' })
        }
    }, [error, isError])

    if (isError) {
        return null;
    }

    return (
        <div className={styles.popularJobsSec}>
            <div className={styles.jobBgWrapper}>
                <div className="container">
                    <LinedDecorTitle className={styles.propTitle}>{props.title}</LinedDecorTitle>
                </div>
                <div className="container">
                    <JobsList jobs={data} isLoading={isLoading} isFetching={isFetching} passiveLoadMore={true} />
                </div>
            </div>
        </div>
    )
}

export default PopularJobs